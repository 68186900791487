import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Dialog,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useMessenger from "../../hooks/useMessenger";
import "./ViewGroupModal.css";
import {
  PermMediaOutlined as MediaIcon,
  StarBorderOutlined as StarIcon,
  EnhancedEncryptionOutlined as EncryptionIcon,
  ChevronRightOutlined as RightIcon,
} from "@mui/icons-material";
import UsersSvg from "../../assets/svgs/UsersSvg";
import Chat from "../../assets/svgs/Chat";
import { avatarName } from "../../utilities/resuseFunctions";
import Loading from "../Loading";
import StarredMessages from "../../screens/Chat/StarredMessages";
import ReferencedPatients from "../../screens/Chat/ReferencedPatients";
import SectionCard from "../Cards/SectionCard";
import { Icon } from "@virtuslab/tetrisly-icons";
import FilterByCaretags from "../../screens/Chat/FilterByCaretags";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { SocketContext } from "../../context/SocketProvider";
import { useFetchConversationByIdMutation } from "../../features/chat/chatApiSlice";
import MediaFiles from "../../screens/Chat/MediaFiles";
import SentToEhrMessages from "../../screens/Chat/SentToEhrMessages";

const ViewContactModal = ({ isOpen, onClose, chat, isCommunity }) => {
  const { height } = useWindowDimensions();
  const user = useSelector(selectUser);
  const {
    setCurrentChat,
    getStarredMessages,
    getReferencedPatients,
    getTaggedCaretags,
    isLoading,
    getMediaFiles,
    getAthenaMessages,
  } = useContext(SocketContext);
  const [fetchConversationById] = useFetchConversationByIdMutation();
  const [open, setOpen] = useState(isOpen);
  // const [selected, setSelected] = useState([]);
  const [data, setData] = useState({});
  const [starred, setStarred] = useState([]);
  const [referenced, setReferenced] = useState([]);
  const [tagged, setTagged] = useState([]);
  const [athenaMessages, setAthenaMessages] = useState([]);
  const [isStarred, setIsStarred] = useState(false);
  const [isReferenced, setIsReferenced] = useState(false);
  const [isCaretags, setIsCaretags] = useState(false);
  const [isSentEhr, setIsSentEhr] = useState(false);
  const [media, setMedia] = useState([]);
  const [isMedia, setIsMedia] = useState(false);

  useEffect(() => {
    fetchConversationById({
      id: chat.id,
    }).then(({ data }) => {
      const contact = data?.data?.participants?.find(
        (participant) => participant.user_id !== user.id
      );
      if (contact) {
        const final = {
          id: data.data.id,
          firstname: contact?.firstname,
          lastname: contact?.lastname,
          img: contact?.img,
          position: contact?.speciality,
          groups: data.data.groups,
        };
        setData(final);
      }
    });
  }, []);

  useEffect(() => {
    getStarredMessages({ conversationId: chat?.id }).then((data) => {
      setStarred(data);
    });
    getReferencedPatients({ conversationId: chat?.id }).then((data) =>
      setReferenced(data)
    );
    getTaggedCaretags({ conversationId: chat?.id }).then((data) =>
      setTagged(data)
    );
    getMediaFiles({ conversationId: chat?.id }).then((data) => setMedia(data));
    getAthenaMessages({ conversationId: chat?.id }).then((data) =>
      setAthenaMessages(data)
    );
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const onStarredClick = () => setIsStarred(!isStarred);

  const onEhrClick = () => setIsSentEhr(!isSentEhr);

  const onReferenceClick = () => setIsReferenced(!isReferenced);

  const onCaretagClick = () => setIsCaretags(!isCaretags);

  const onMediaClick = () => setIsMedia(!isMedia);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          margin: 0,
          top: 20,
          bottom: 0,
          backgroundColor: "#F2F2F2",
        },
      }}
    >
      {isLoading && <Loading loading={isLoading} />}
      {isMedia ? (
        <MediaFiles handleClick={onMediaClick} chat={chat} media={media} />
      ) : isStarred ? (
        <StarredMessages
          onStarredClick={onStarredClick}
          starred={starred}
          chat={chat}
        />
      ) : isReferenced ? (
        <ReferencedPatients
          onReferenceClick={onReferenceClick}
          referenced={referenced}
          chat={chat}
        />
      ) : isCaretags ? (
        <FilterByCaretags
          onTagClick={onCaretagClick}
          tagged={tagged}
          chat={chat}
        />
      ) : isSentEhr ? (
        <SentToEhrMessages
          handleClose={onEhrClick}
          messages={athenaMessages}
          chat={chat}
        />
      ) : (
        <Grid
          sx={{
            width: {
              xs: "300px",
              sm: "300px",
              md: "350px",
            },
            //   height: "100vh",
            backgroundColor: "#F2F2F2",
          }}
          className="py-3 overflow-hidden"
        >
          <div className="d-flex align-items-center px-3">
            <div role="button" className="close-box " onClick={handleClose}>
              <Tooltip title="Close">
                <CloseOutlinedIcon className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Contact Information
            </Typography>
          </div>
          <Grid className="px-3 group-content">
            <Grid className="text-center p-3">
              <div className="d-flex justify-content-center">
                <Avatar className="m-2 avatar-group" src={data?.img} />
              </div>
              <Typography className="group-name">
                {`${data?.firstname || ""} ${data?.lastname || ""}`}
              </Typography>
              <Typography className="group-subtext">
                {data?.position}
              </Typography>
            </Grid>
            <div className="section-card rounded my-2 mb-4">
              <Typography className="message-text text-center">
                Message this Contact
              </Typography>
            </div>
            <Grid>
              <SectionCard
                text={"Media, Links, Docs"}
                // count={24}
                icon={MediaIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onMediaClick}
              />
              <SectionCard
                text={"Starred Messages"}
                // count={24}
                icon={StarIcon}
                className={"my-2 rounded"}
                onClick={onStarredClick}
                showDeleteButton={false}
                handleClick={onStarredClick}
              />
              <SectionCard
                text={"Filter by Patient"}
                // count={3}
                icon={UsersSvg}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onReferenceClick}
              />
              <SectionCard
                text={"Filter by CareTag"}
                // count={3}
                icon={(props) => <Icon name="20-hashtag" {...props} />}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onCaretagClick}
              />
              {!isCommunity && (
                <SectionCard
                  text={"Messages sent to EHR"}
                  // count={3}
                  icon={Chat}
                  className={"my-2 rounded"}
                  showDeleteButton={false}
                  handleClick={onEhrClick}
                />
              )}
              <SectionCard
                text={"Privacy Policy"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open("https://www.caresms.io/privacy-policy");
                }}
              />
              <SectionCard
                text={"HIPAA Compliance"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open(
                    "https://www.caresms.io/hipaa-compliance-security"
                  );
                }}
              />
            </Grid>
            <Typography className="text-center participants-title my-3">
              {data?.groups?.length} Groups Together
            </Typography>
            <Grid className="rounded">
              {data?.groups?.map((i, index) => {
                return (
                  <div key={i.id}>
                    <SectionCard
                      text={i.name}
                      profileImg={i.img}
                      handleClick={async () => {
                        setCurrentChat({
                          id: i?.id,
                          lastSeen: moment().format("YYYY-MM-DD HH:mm:ss"),
                          contactId: user.id,
                          name: i.name,
                          image: i.img,
                          type: "GROUP",
                        });
                      }}
                    />
                    {index !== data.groups.length && <Divider />}
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default ViewContactModal;
