import React, { useContext, useEffect, useState } from "react";
import { colors } from "../../styles";
import CustomButton from "../Buttons/CustomButton";
import { Dialog, Divider, Grid, IconButton, Typography } from "@mui/material";
import "./ForwardModal.css";
import CloseIcon from "@mui/icons-material/Close";
import ForwardSvg from "../../assets/svgs/ForwardSvg";
import SearchComponent from "../Search/Search";
import ContactCard from "../Cards/ContactCard";
import { providers } from "../../data";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useMessenger from "../../hooks/useMessenger";
import Loading from "../Loading";
import { SocketContext } from "../../context/SocketProvider";
import { useFetchContactsMutation } from "../../features/organisation/organisationApiSlice";

const ForwardModal = ({ open: isOpen, handleClose, onSubmit }) => {
  const { height } = useWindowDimensions();
  // const {
  //   getAllContacts,
  //   loading,
  //   chats,
  //   // createPrivateConversation,
  //   // newGroupCreationEmit,
  //   // sendMessage,
  // } = useMessenger();
  const [fetchContacts, { isLoading }] = useFetchContactsMutation();
  const { chats } = useContext(SocketContext);
  const [open, setOpen] = useState(isOpen);
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [contacts, setContacts] = useState(null);

  useEffect(() => {
    fetchContacts().then(({ data }) => {
      const formatedData = data?.data?.map((item) => {
        return {
          ...item,
          details:
            item.provider || item.back_office_staff || item.community_staff,
        };
      });
      setContacts(formatedData);
    });
  }, []);

  const onClose = () => {
    setOpen(true);
    handleClose();
  };

  const onhandleSubmit = async () => {
    onSubmit(selected);
    onClose();
  };

  const onMemberSelect = (item) => {
    const isExist = selected.find((i) => i.id === item.id);
    if (isExist) {
      setSelected((prev) => prev.filter((i) => i.id !== item.id));
    } else {
      setSelected((prev) => [...prev, item]);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"sm"} fullWidth>
      <Loading loading={isLoading} />
      <Grid>
        <Grid
          className="d-flex justify-content-between py-2 px-3 align-items-center"
          style={{
            backgroundColor: colors.OFFICE_COLOR,
          }}
        >
          <div className="d-flex align-items-center">
            <ForwardSvg color={"white"} />
            <Typography className="region-modal-title mx-2">
              {"Forward message to:"}
            </Typography>
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon className="close-icon" />
          </IconButton>
        </Grid>
        <Grid
          className="px-2"
          style={{
            height: height - 200,
            overflow: "auto",
            marginBottom: selected?.length ? 60 : 0,
          }}
        >
          <div className="p-3">
            <SearchComponent
              placeholder=""
              value={search}
              onChange={(value) => setSearch(value)}
            />
          </div>
          <Typography className="head-title-chats color-1 mb-2">
            CareSMS Groups
          </Typography>
          <Divider />
          <div
          // style={{
          //   height: selected?.length ? height - 300 : height - 240,
          //   overflow: "auto",
          // }}
          >
            {chats
              ?.filter((data) => data.type === "GROUP")
              ?.map((item, idx) => {
                return (
                  <>
                    <ContactCard
                      profile={item.image}
                      label={item.name}
                      subLabel={"Group"}
                      isSelected={selected.find((i) => i.id === item.id)}
                      onSelect={() => onMemberSelect({ ...item, id: item.id })}
                    />
                    {idx !== providers.length - 1 && (
                      <Divider ml={6} width={"95%"} thickness="0.5" />
                    )}
                  </>
                );
              })}
          </div>
          <Typography className="head-title-chats color-1 mb-2">
            CareSMS Chats
          </Typography>
          <Divider />
          <div
          // style={{
          //   height: selected?.length ? height - 300 : height - 240,
          //   overflow: "auto",
          // }}
          >
            {contacts
              ?.filter(
                (data) =>
                  data?.details?.firstname.includes(search) ||
                  data?.details?.lastname.includes(search)
              )
              ?.map((item, idx) => {
                return (
                  <>
                    <ContactCard
                      profile={item.img}
                      label={`${item.details?.firstname || ""} ${
                        item.details?.lastname || ""
                      }`}
                      subLabel={item.position}
                      isSelected={selected.find((i) => i.id === item.id)}
                      onSelect={() => onMemberSelect(item)}
                    />
                    {idx !== contacts.length - 1 && (
                      <Divider ml={6} width={"95%"} thickness="0.5" />
                    )}
                  </>
                );
              })}
          </div>
        </Grid>
        {selected.length ? (
          <Grid className="bg-1 d-flex justify-content-between align-items-center bottom-div">
            <Typography className="mx-2 px-2 text-white">
              {/* Coastal Community Group Home */}
            </Typography>
            <CustomButton
              className={"bg-white text-black p-2"}
              onClick={onhandleSubmit}
            >
              <Typography>Forward Message</Typography>
            </CustomButton>
          </Grid>
        ) : null}
      </Grid>
    </Dialog>
  );
};

export default ForwardModal;
