import classNames from "classnames";
import React from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import moment from "moment";
import ArrowUpRight from "../../../assets/svgs/ArrowUpRight";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { usePublishToAthenaMutation } from "../../../features/athena/athenaApiSlice";
import { Form } from "react-bootstrap";
import Loading from "../../../components/Loading";
import { useLSStore } from "../../../context/ls-store";
import { Alert, Snackbar } from "@mui/material";

const PublishAppointmentModal = ({
  publishOpen,
  setPublishOpen,
  weeks,
  setSuccessCard,
  selectedProvider,
  user,
  setRecallSchedule,
}) => {
  const [publishToAthena, { isLoading }] = usePublishToAthenaMutation();
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();
  const setAlert = useLSStore((state) => state.setAlert);
  const [message, setMessage] = React.useState([]);

  const showAlert = (msg) => {
    setMessage(msg);
  };

  const onPublish = async () => {
    try {
      // if (
      //   !user?.subscription?.status ||
      //   user?.subscription?.status !== "active"
      // ) {
      //   alert(
      //     "You have not subscribed to the Athena integration plan, please subscribe if you want to publish to Athena"
      //   );
      //   return;
      // }

      if (!user?.organisation?.practiceId) {
        showAlert(["You don't have a practice id, please add it to publish"]);
        return;
      }
      if (!startDate || !endDate) {
        showAlert(["You didn't selected any start date or end date"]);
        return;
      }
      const { data } = await publishToAthena({
        ProviderID: selectedProvider.id,
        userId: user.id,
        startDate,
        endDate,
        practiceId: user?.organisation?.practiceId,
        providername: selectedProvider.name,
      }).unwrap();

      setPublishOpen(false);
      setSuccessCard(true);
      setRecallSchedule?.(true);
      setAlert({
        message: data?.message,
        severity: "success",
      });
    } catch (err) {
      console.log(err, "err");
      if (err?.data?.errors?.length > 0) {
        showAlert(err?.data?.errors);
      } else {
        showAlert([
          "(Error Code 001): We were unable to publish these schedules to Athena due to system timeout. Please try again in a few minutes. If the problem persists, please contact your system administrator.",
        ]);
      }
    }
  };

  return (
    <ReusableModal open={publishOpen} hideHeader>
      {/* <Snackbar
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={5000}
        message={message ? message : "Something went wrong"}
        severity="error"
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity ? severity : "error"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message ? message : "Something went wrong"}
        </Alert>
      </Snackbar> */}
      {isLoading && <Loading loading={isLoading} />}
      <div
        className={classNames(
          "absolute top-0 left-0 flex items-center justify-center w-screen h-full transition-all",
          publishOpen ? "z-50 opacity-100" : "-z-10 opacity-0"
        )}
      >
        <div className="bg-white w-[600px] rounded-md shadow-md flex flex-col">
          <div className="flex flex-row items-center justify-between w-full p-4">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              Publish Appointments
            </p>
            <button
              onClick={() => {
                setPublishOpen(false);
                setStartDate("");
                setEndDate("");
              }}
              className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#3062D4"} />
            </button>
          </div>
          <div className="w-full border border-primarygrayborder" />
          <p className="px-4 pt-4 m-0 text-base text-left text-primarytextgrey">
            Please specify the date range for this provider's schedule that you
            would like to publish to Athena.
          </p>
          <div className="flex flex-col w-full gap-2 p-4">
            <div className="relative flex flex-col gap-1">
              <div className="flex flex-row items-center justify-between">
                <p className="m-0 text-xl font-semibold">Provider Name</p>
              </div>
              <span className="text-lg">{selectedProvider.name}</span>
              {/* <div
                className={classNames(
                  "absolute left-0 w-full bg-white border rounded-md top-20 border-primarygrayborder z-50",
                  startDateOpen
                    ? "max-h-44 overflow-x-auto opacity-100"
                    : "hidden h-0 overflow-hidden opacity-30"
                )}
              >
                {weeks.map((x, ind) => (
                  <button
                    key={ind}
                    onClick={() => {
                      setStartDate(moment(x.date).format("MMMM DD, YYYY"));
                      setStartDateOpen(false);
                    }}
                    className="flex flex-col items-start w-full transition-all hover:bg-slate-300"
                  >
                    <p className="p-2 m-0 text-base font-semibold cursor-pointer text-primaryblack">
                      {moment(x.date).format("MMMM DD, YYYY")}
                    </p>
                    {ind !== 6 && (
                      <div className="w-full border border-primarygrayborder" />
                    )}
                  </button>
                ))}
              </div> */}
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-center my-2">
                <p className="m-0 text-xl font-semibold">
                  Start Date<span className="text-danger">*</span>
                </p>
              </div>
              <Form.Select
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              >
                <option value={""}>Select Start Date</option>
                {weeks?.map((x, idx) => (
                  <option key={idx} value={x.date}>
                    {moment(x.date).format("MMMM DD, YYYY")}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div className="mb-2">
              <div className="d-flex align-items-center my-2">
                <p className="m-0 text-xl font-semibold">
                  End Date<span className="text-danger">*</span>
                </p>
              </div>
              <Form.Select
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              >
                <option value={""}>Select End Date</option>
                {weeks?.map((x, idx) => (
                  <option key={idx} value={x.date}>
                    {moment(x.date).format("MMMM DD, YYYY")}
                  </option>
                ))}
              </Form.Select>
            </div>
            {startDate && (
              <p>
                You have selected{" "}
                {startDate && moment(startDate).format("MMMM DD, YYYY")} -{" "}
                {endDate && moment(endDate).format("MMMM DD, YYYY")}
              </p>
            )}
            {message.length > 0 && (
              <ul className="text-red-500 m-0 p-0 font-semibold">
                {message?.map((msg) => {
                  return <li>{msg}</li>;
                })}
              </ul>
            )}
          </div>
          <div className="flex flex-row p-4">
            <div className="flex flex-row items-center justify-end w-full gap-2">
              <button
                onClick={() => {
                  setPublishOpen(false);
                  setStartDate("");
                  setEndDate("");
                }}
                className="flex flex-row items-center justify-center px-3 py-2 border-2 rounded-md shadow-sm w-fit border-primaryred"
              >
                <p className="m-0 text-sm font-bold text-primaryred">
                  No, don't publish to EHR
                </p>
              </button>
              <button
                disabled={!startDate || !endDate}
                onClick={onPublish}
                className="flex flex-row items-center justify-center px-3 py-2 border-2 rounded-md shadow-sm w-fit bg-primarygreen border-primarygreen"
              >
                <p className="m-0 text-sm font-bold text-white">
                  Yes, publish to EHR
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReusableModal>
  );
};

export default PublishAppointmentModal;
