import React from "react";
import IconComponent from "../../../components/DataTable/Icon";
import classNames from "classnames";
import { Avatar } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

const roles = {
  1: "Back Office Staff",
  2: "Community Staff",
  3: "Provider",
};

const backOfficeRoles = {
  1: "Admin",
  2: "Scheduler",
  3: "Executive",
  4: "Creator",
};

const UsersTable = ({ users, deleteUser, onEdit, searchFilter }) => {
  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  return (
    <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow max-h-[calc(100vh-270px)]">
      <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
        <thead>
          <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
            <th className="px-4 py-3">
              <div className="flex flex-row items-center gap-2">
                <p className="m-0">Name</p>
              </div>
            </th>
            <th className="px-4 py-3">
              <div className="flex flex-row items-center gap-2">
                <p className="m-0">Role</p>
              </div>
            </th>
            <th className="px-4 py-3">Phone</th>
            <th className="px-4 py-3">Email</th>
            <th className="px-4 py-3">Position</th>
            <th className="px-4 py-3">Status</th>
            <th className="px-4 py-3">
              <p className="m-0">Edit</p>
            </th>
            <th className="px-4 py-3">
              <p className="m-0">Delete</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {users
            .filter((user) =>
              user?.details?.name
                ?.toLowerCase()
                .includes(searchFilter.toLowerCase())
            )
            .map((user, index) => (
              <tr
                className={classNames(
                  "text-left text-gray-900 h-24 whitespace-nowrap",
                  index !== users.length - 1 ? "border-b" : "border-none"
                )}
                key={index}
              >
                <td className="px-4 py-3 text-left">
                  <div className="flex flex-row items-center gap-4">
                    <button
                      // onClick={() => handleProviderClick(provider)}
                      className="flex flex-row items-center gap-4"
                    >
                      <Avatar name={user.details.name} image={user.img} />
                      <p className="m-0 font-semibold underline">
                        {user.details.name}
                      </p>
                    </button>
                  </div>
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  {roles[user.type]}
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  {formatPhoneNumber(user.phone)}
                </td>

                <td className="px-4 py-3 text-left text-gray-700">
                  {user.email}
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  {user.type === 1
                    ? backOfficeRoles[user.details.role]
                    : `${user.details.speciality} - ${user.details.ehr.name}`}
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  <div
                    className={classNames(
                      user.details?.status?.toUpperCase() === "ACTIVE"
                        ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                        : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                      "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                    )}
                  >
                    <div
                      className={classNames(
                        "w-2 h-2 rounded-full",
                        user.details?.status?.toUpperCase() === "ACTIVE"
                          ? "bg-primarygreen"
                          : "bg-primarytextgrey"
                      )}
                    />
                    {user.details?.status}
                  </div>
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  <IconComponent
                    tooltip="Edit user"
                    icon={"Edit_Icon"}
                    i={user}
                    onClick={(e) => onEdit(e, user)}
                  />
                </td>
                <td className="px-4 py-3 text-left text-gray-700">
                  <IconComponent
                    disabled={user?.details?.status === "INACTIVE"}
                    tooltip="Delete user"
                    icon={"Delete_Icon"}
                    i={user}
                    onClick={() => deleteUser(user)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default UsersTable;
