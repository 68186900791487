import { CalendarIcon } from "@mui/x-date-pickers-pro";
import FileSvg from "../../assets/svgs/FileSvg";
import { Icons } from "../../assets";

const menuOptions = [
  {
    id: 1,
    name: "Command Center",
    path: "/",
    icon: Icons.Communities_Icon1,
    hasSubsections: true,
    access: ["*"],
    subSections: [
      {
        id: 5,
        name: "Home",
        path: "/home",
        access: ["*"],
      },
      {
        id: 6,
        name: "Calendar View",
        path: "/calendar-view",
        access: ["*"],
      },
      {
        id: 6,
        name: "Dispatch",
        path: "/patient-assignment",
        access: ["1"],
      },
    ],
  },
  {
    id: 2,
    name: "Data Management",
    path: "/data-management",
    icon: Icons.Stats_Icon,
    hasSubsections: true,
    access: ["1"],
    subSections: [
      {
        id: 6,
        name: "Providers",
        path: "/data-management/providers",
        access: ["1"],
      },
      {
        id: 7,
        name: "Patients",
        path: "/data-management/patients",
        access: ["1"],
      },
      {
        id: 8,
        name: "Communities",
        path: "/data-management/communities",
        access: ["1"],
      },
      {
        id: 9,
        name: "Zones",
        path: "/data-management/zones",
        access: ["1"],
      },
      {
        id: 10,
        name: "Caretags",
        path: "/data-management/caretags",
        access: ["1"],
      },
    ],
  },
  {
    id: 3,
    name: "Messenger",
    path: "/chat",
    icon: Icons.Chat_Icon,
    access: ["*"],
  },
  {
    id: 21,
    name: "Scheduler",
    path: "/schedules",
    icon: CalendarIcon,
    noIcon: true,
    hasSubsections: true,
    access: ["1"],
    subSections: [
      {
        id: 0,
        name: "Data and Settings",
        access: ["1"],
        hasSubsections: true,
        subSections: [
          {
            id: 2,
            name: "Provider Settings",
            path: "/schedules/configure/providers",
            access: ["1"],
          },
          {
            id: 3,
            name: "Visit Rules",
            path: "/schedules/configure/visit-rules",
            access: ["1"],
          },
          // {
          //   id: 4,
          //   name: "Visit Types",
          //   path: "/schedules/configure/visit-types",
          //   access: ["1"],
          // },
          // {
          //   id: 1,
          //   name: "Upload Scheduling Data",
          //   path: "/schedules/careplan-upload",
          //   access: ["1"],
          // },
        ],
      },
      // {
      //   id: 5,
      //   name: "Run Scheduler",
      //   path: "/schedules/run-scheduler",
      //   access: ["1"],
      //   isEnterprise: false,
      // },
    ],
  },
  {
    id: 22,
    name: "Reporting & Analytics",
    path: "/reports",
    icon: FileSvg,
    noIcon: true,
    hasSubsections: true,
    access: ["1"],
    subSections: [
      {
        id: 0,
        name: "Standard Reports",
        access: ["1"],
        hasSubsections: true,
        subSections: [
          {
            id: 1,
            name: "Patient Visit Report",
            path: "/reports/patient-visit-report",
            access: ["*"],
          },
          {
            id: 2,
            name: "Provider Activity Report",
            path: "/reports/provider-activity-report",
            access: ["*"],
          },
          {
            id: 3,
            name: "Revenue Report",
            path: "/reports/revenue-report",
            access: ["*"],
          },
          {
            id: 4,
            name: "Appointment Cancellation Report",
            path: "/reports/appointment-cancellation-report",
            access: ["*"],
          },
          {
            id: 5,
            name: "Overdue Patients Report",
            path: "/reports/overdue-patients-report",
            access: ["*"],
          },
          {
            id: 6,
            name: "New Patients Report",
            path: "/reports/new-patients-report",
            access: ["*"],
          },
        ],
      },
      {
        id: 8,
        name: "Pivot",
        path: "/analytics/pivot",
        access: ["*"],
      },
      {
        id: 7,
        name: "Report Builder",
        path: "/reports/report-builder",
        access: ["*"],
      },
      {
        id: 9,
        name: "Dashboard Builder",
        path: "/analytics/dashboard",
        access: ["*"],
      },
    ],
  },
  // {
  //   id: 23,
  //   name: "Analytics",
  //   path: "/analytics",
  //   icon: Icons.Stats_Icon,
  //   hasSubsections: true,
  //   access: ["1"],
  //   subSections: [
  //     {
  //       id: 1,
  //       name: "Pivot",
  //       path: "/analytics/pivot",
  //       access: ["*"],
  //     },
  //     {
  //       id: 2,
  //       name: "Dashboard",
  //       path: "/analytics/dashboard",
  //       access: ["*"],
  //     },
  //   ],
  // },
];

export default menuOptions;
