import { ArrowDownwardTwoTone, ArrowUpwardTwoTone } from "@mui/icons-material";
import { Collapse, Tooltip } from "@mui/material";
import { selectToken, selectUser } from "../../../features/auth/authSlice";
import {
  useCreateCommunityMutation,
  useDeleteCommunityMutation,
  useUpdateCommunityMutation,
} from "../../../features/community/communityApiSlice";

import CommunityAddModal from "./components/CommunityAddModal";
import CommunityDeleteModal from "./components/CommunityDeleteModal";
import { FileUploader } from "react-drag-drop-files";
import IconComponent from "../../../components/DataTable/Icon";
import { Icons } from "../../../assets";
import Loading from "../../../components/Loading";
import Pagination from "../../../components/Pagination/Pagination";
import React from "react";
import Search from "../../../components/Search/SearchNew";
import { Search_Icon } from "../../../assets/images";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import axios from "axios";
import classNames from "classnames";
import { useFetchCommunitiesMutation } from "../../../features/organisation/organisationApiSlice";
import { useLSStore } from "../../../context/ls-store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import useDebounce from "../../../hooks/useDebounce";

const fileTypes = ["csv", "xls", "xlsx"];

const CommunityList = () => {
  const [communities, setCommunities] = React.useState([]);

  const [searchFilter, setSearchFilter] = React.useState("");

  const [sortNOPOrder, setSortNOPOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");
  const [communityModalOpen, setCommunityModalOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [del, setDel] = React.useState(false);
  const [data, setData] = React.useState({});
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);

  const search = useDebounce(searchFilter, 500);

  const user = useSelector(selectUser);
  const [fetchCommunities, { isLoading: getLoading }] =
    useFetchCommunitiesMutation();
  const [createCommunity, { isLoading: createLoading }] =
    useCreateCommunityMutation();
  const [updateCommunity, { isLoading: updateLoading }] =
    useUpdateCommunityMutation();
  const [deleteCommunity, { isLoading: deleteLoading }] =
    useDeleteCommunityMutation();

  const isLoading =
    getLoading || createLoading || updateLoading || deleteLoading;

  const navigate = useNavigate();

  const handleCommunityClick = (community) => {
    navigate(`/data-management/communities/${community.id}`);
  };

  const getCommunities = async ({ limit, skip }) => {
    try {
      const { data, count } = await fetchCommunities({
        orgId: user.organisation.id,
        limit,
        skip,
        search,
      }).unwrap();

      setCommunities(data);
      setCount(count);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const handleSortNOP = (a, b) => {
    if (sortNOPOrder === "none") {
      return 0;
    } else if (sortNOPOrder === "asc") {
      return a.noOfPatients - b.noOfPatients;
    } else {
      return b.noOfPatients - a.noOfPatients;
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  React.useEffect(() => {
    getCommunities({ limit: 20, skip: 0 });
  }, [search]);

  const Avatar = ({ name, image }) => {
    return (
      <>
        {image !== null ? (
          <div className="flex items-center gap-2">
            <img
              src={image}
              alt={name}
              className="object-cover w-10 h-10 rounded-full"
            />
          </div>
        ) : (
          <p className="flex items-center justify-center w-10 h-10 m-0 text-white rounded-full bg-primaryblue">
            {name
              ?.split(" ")
              ?.map((n) => n[0])
              ?.slice(0, 2)
              ?.map((n) => n?.toUpperCase())
              ?.join("")}
          </p>
        )}
      </>
    );
  };

  const setAlert = useLSStore((state) => state.setAlert);

  const token = useSelector(selectToken);

  async function uploadFile(file) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/community/verify-communities-doc`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_SERVER_URL,
          },
          withCredentials: true,
        }
      );

      await getCommunities({ limit: 20, skip: 0 });

      setOpenDropdown(false);

      setAlert({
        message: "Data Uploaded Successfully",
        severity: "success",
      });
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (file) => {
    try {
      uploadFile(file);
    } catch (error) {
      console.log("err");
    }
  };

  const saveCommunity = async () => {
    try {
      const res = await createCommunity({
        communityName: data.name,
        communityStreet: data.street,
        communityCity: data.city,
        communityState: data.state,
        communityZip: data.zip,
      }).unwrap();

      const communitiesList = [
        {
          name: res.data.communityName,
          address: `${res.data.communityStreet}, ${res.data.communityCity}, ${res.data.communityState}, ${res.data.communityZip}`,
        },
        ...communities,
      ];
      setCommunities(communitiesList);
    } catch (error) {
      throw new Error(error?.data?.message || error.message);
    }
  };

  const onUpdateCommunity = async () => {
    try {
      await updateCommunity({
        id: edit,
        ...data,
      }).unwrap();

      setCommunities((prev) => {
        const update = [...prev];
        const index = update.findIndex((x) => x.id === edit);
        update[index] = data;
        return [...update];
      });
    } catch (error) {
      throw new Error(error?.data?.message || error.message);
    }
  };

  const onDeleteCommunity = async () => {
    try {
      await deleteCommunity({
        id: del,
      }).unwrap();

      setCommunities((prev) => {
        const update = [...prev].filter((x) => x.id !== del);
        return [...update];
      });
    } catch (error) {
      setAlert({
        message: error?.data?.message || error.message,
        severity: "error",
      });
    }
  };

  const onEdit = (location) => {
    const [street, city, state, zip] = location.address.split(", ");
    setData({ ...location, street, city, state, zip });
    setEdit(location.id);
    setCommunityModalOpen(true);
    setDeleteModalOpen(false);
  };

  const onDelete = (location) => {
    setDeleteModalOpen(true);
    setData(location);
    setDel(location.id);
  };

  const clearInput = () => {
    setData({});
  };

  const downloadCSV = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/community/get/template`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "community-template.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden max-h-[calc(100vh-100px)]">
      <Loading loading={isLoading} />
      <div
        className={classNames(
          "w-full px-16 transition-all",
          !openDropdown && "pb-8"
        )}
      >
        <button
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
          className="flex flex-row items-center justify-between w-full px-4 py-2 bg-gray-100 rounded-md"
        >
          <div className="flex flex-row items-center">
            Upload Community Data
            <img
              src={Icons.Upload_Icon}
              alt="upload icon"
              className="mx-2 icon"
            />
          </div>
          {openDropdown ? (
            <Tooltip title="Collapse">
              <ArrowDownwardTwoTone />
            </Tooltip>
          ) : (
            <Tooltip title="Expand">
              <ArrowUpwardTwoTone />
            </Tooltip>
          )}
        </button>
      </div>
      <Collapse in={openDropdown} className="w-full">
        <div className="flex flex-col w-full gap-1 px-16 py-8">
          <div className="flex flex-row items-end justify-end w-full mb-2 md:mb-0">
            <p
              className="m-0 text-sm underline text-primaryblue"
              onClick={downloadCSV}
              role="button"
            >
              Community Data Template
            </p>
          </div>
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            classes="w-100"
          >
            <div className="flex hover:bg-subtleblue transition-all cursor-pointer flex-col w-full justify-center items-center gap-2 border-[1px] border-dashed border-secondarygrayborder rounded-lg p-4">
              <img
                src={Icons.Upload_Icon}
                alt="upload icon"
                className="mx-2 icon"
              />
              <p className="m-0 mt-2 text-sm">
                Drag & Drop or{" "}
                <span className="text-primaryblue">Choose file</span> to upload
              </p>
            </div>
          </FileUploader>
        </div>
      </Collapse>
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Communities{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {count}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
          </div>
        </div>
        <button
          onClick={() => {
            setCommunityModalOpen(true);
            setEdit(false);
          }}
          className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
        >
          Add Community
        </button>
      </div>

      <div className="w-full px-16 mt-2">
        <Pagination count={count} api={getCommunities} />
        <div className="relative w-full  mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow  max-h-[calc(100vh-300px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Address</th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">No of Patients</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNOPOrder === "none") {
                          setSortNOPOrder("asc");
                        }
                        if (sortNOPOrder === "asc") {
                          setSortNOPOrder("desc");
                        }
                        if (sortNOPOrder === "desc") {
                          setSortNOPOrder("none");
                        }
                      }}
                    >
                      {sortNOPOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNOPOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Edit</p>
                </th>
                <th className="px-4 py-3">
                  <p className="m-0">Delete</p>
                </th>
              </tr>
            </thead>
            <tbody>
              {communities
                .filter(Boolean)
                // .filter((community) =>
                //   community.name
                //     .toLowerCase()
                //     .includes(searchFilter.toLowerCase())
                // )
                .sort((a, b) => handleSortNOP(a, b))
                .sort((a, b) => handleSortName(a, b))
                .map((community, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== communities.length - 1
                        ? "border-b"
                        : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => handleCommunityClick(community)}
                        className="flex flex-row items-center gap-4"
                      >
                        <Avatar name={community.name} image={null} />
                        <p className="m-0 font-semibold underline">
                          {community.name}
                        </p>
                      </button>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {community.address}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {community.noOfPatients}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <IconComponent
                        tooltip="Edit Community"
                        icon={"Edit_Icon"}
                        i={community}
                        onClick={() => onEdit(community)}
                      />
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <IconComponent
                        tooltip="Delete Community"
                        icon={"Delete_Icon"}
                        i={community}
                        onClick={() => onDelete(community)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {communityModalOpen && (
        <CommunityAddModal
          isOpen={communityModalOpen}
          edit={edit}
          clearInput={clearInput}
          onUpdate={onUpdateCommunity}
          onSave={saveCommunity}
          setOpen={setCommunityModalOpen}
          data={data}
          setData={setData}
        />
      )}
      {deleteModalOpen && (
        <CommunityDeleteModal
          isOpen={deleteModalOpen}
          clearInput={clearInput}
          onDeleteCommunity={onDeleteCommunity}
          setOpen={setDeleteModalOpen}
          data={data}
        />
      )}
    </div>
  );
};

export default CommunityList;
