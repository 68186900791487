import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { Icons } from "../../../../../assets";
import CheckboxSelector from "../../../components/CheckboxSelector";
import { Tooltip } from "@mui/material";
import Search from "../../../../../components/Search/SearchNew";
import FilterSelect from "../../../../../components/Filter/FilterSelect";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const FREQUENCY_MAP = {
  0: "NA",
  7: "1 week",
  14: "2 weeks",
  21: "3 weeks",
  28: "4 weeks",
  30: "1 month",
  60: "2 month",
  90: "3 month",
};

const frequencies = [
  {
    id: 1,
    name: "NA",
    value: 0,
  },
  {
    id: 2,
    name: "1 week",
    value: 7,
  },
  {
    id: 3,
    name: "2 weeks",
    value: 14,
  },
  {
    id: 4,
    name: "3 weeks",
    value: 21,
  },
  {
    id: 6,
    name: "1 month",
    value: 30,
  },
  {
    id: 7,
    name: "2 month",
    value: 60,
  },
  {
    id: 8,
    name: "3 month",
    value: 90,
  },
];

const CommunityVisitRulesFilter = ({
  handleNameFilter,
  handleFrequencyFilter,
  handleAvailabilityFilter,
  nameFilter,
  frequencyFilter,
  AvailabilityFilter,
  isWelcomePage
}) => {
  const [frequencyListOpen, setFrequencyListOpen] = useState(false);
  const [AvailabilityListOpen, setAvailabilityListOpen] = useState(false);

  const handleFrequencyListOpen = () => {
    setFrequencyListOpen(!frequencyListOpen);
    setAvailabilityListOpen(false);
  };

  const handleAvailabilityListOpen = () => {
    setAvailabilityListOpen(!AvailabilityListOpen);
    setFrequencyListOpen(false);
  };

  return (
    <>
      {isWelcomePage ?
        <div className="flex flex-col w-full gap-8 md:flex-row h-fit">
          <div className="relative flex flex-row items-center w-full gap-2 divide-x-2 rounded-md outline outline-gray-200 md:w-80">
            <div className="pl-2">
              <Tooltip title="Search">
                <img
                  src={Icons.Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon"
                />
              </Tooltip>
            </div>
            <input
              type="text"
              placeholder="Search by Community Name ..."
              className="w-full h-full p-2 text-base outline-none"
              value={nameFilter}
              onChange={(e) => handleNameFilter(e.target.value)}
            />
          </div>
          <div className="relative flex flex-row items-center w-full gap-2 divide-x-2 rounded-md cursor-grab outline outline-gray-200 md:w-80">
            <Tooltip title="Filter">
              <button onClick={handleFrequencyListOpen} className="pl-2">
                <img
                  src={Icons.Sort_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon"
                />
              </button>
            </Tooltip>
            <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll flex-nowrap no-scrollbar">
              {frequencyFilter.length === 0 && (
                <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                  Filter by Frequency ...
                </p>
              )}
              {frequencyFilter.map((frequency, index) => (
                <div
                  key={index}
                  className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder"
                >
                  <p aria-multiline={false} className="m-0 whitespace-nowrap">
                    {FREQUENCY_MAP[frequency]}
                  </p>
                  <Tooltip title="Remove">
                    <button
                      onClick={() => handleFrequencyFilter(frequency)}
                      className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#BDBDBD"} />
                    </button>
                  </Tooltip>
                </div>
              ))}
            </div>
            {frequencyListOpen && (
              <div className="absolute z-10 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14">
                {frequencies.map((frequency, index) => (
                  <div
                    className={classNames(
                      "hover:bg-primarylightgrey",
                      index !== 7 && "border-b"
                    )}
                  >
                    <CheckboxSelector
                      key={index}
                      header={frequency.name}
                      isChecked={
                        frequencyFilter.includes(frequency.value) ? true : false
                      }
                      handleChange={(_e) => handleFrequencyFilter(frequency.value)}
                      isBold={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="relative flex flex-row items-center w-full gap-2 divide-x-2 rounded-md outline outline-gray-200 md:w-80">
            <Tooltip title="Filter">
              <button onClick={handleAvailabilityListOpen} className="pl-2">
                <img
                  src={Icons.Sort_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon"
                />
              </button>
            </Tooltip>
            <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
              {AvailabilityFilter.length === 0 && (
                <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                  Filter by Availability ...
                </p>
              )}
              {AvailabilityFilter.map((Availability, index) => (
                <div
                  key={index}
                  className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder"
                >
                  <p aria-multiline={false} className="m-0 whitespace-nowrap">
                    {Availability[0] + Availability.substring(1).toLowerCase()}
                  </p>
                  <Tooltip title="Remove">
                    <button
                      onClick={() => handleAvailabilityFilter(Availability)}
                      className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#BDBDBD"} />
                    </button>
                  </Tooltip>
                </div>
              ))}
            </div>
            {AvailabilityListOpen && (
              <div className="absolute z-10 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14">
                {days.map((Availability, index) => (
                  <div
                    className={classNames(
                      "hover:bg-primarylightgrey",
                      index !== days.length - 1 && "border-b"
                    )}
                  >
                    <CheckboxSelector
                      key={index}
                      header={
                        Availability[0] + Availability.substring(1).toLowerCase()
                      }
                      isChecked={
                        AvailabilityFilter.includes(Availability) ? true : false
                      }
                      handleChange={(_e) => handleAvailabilityFilter(Availability)}
                      isBold={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        :
        <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
          <Search value={nameFilter} setValue={handleNameFilter} placeholder={"Search by Community Name ..."} />
          <FilterSelect
            value={frequencyFilter}
            handleChange={handleFrequencyFilter}
            data={frequencies}
            placeholder=" Filter by Frequency ..."
            isFrequency={true}
          />
          <FilterSelect
            value={AvailabilityFilter}
            handleChange={handleAvailabilityFilter}
            data={days}
            placeholder=" Filter by Availability ..."
          />

        </div>
      }
    </>
  );
};

export default CommunityVisitRulesFilter;
