import classNames from "classnames";
import React, { useState } from "react";
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import { Icons } from "../../../../../assets";
import CheckboxSelector from "../../../components/CheckboxSelector";
import { Tooltip } from "@mui/material";
import FilterSelect from "../../../../../components/Filter/FilterSelect";
import Search from "../../../../../components/Search/SearchNew";

const DAY_MAP = {
  MONDAY: "M",
  TUESDAY: "T",
  WEDNESDAY: "W",
  THURSDAY: "Th",
  FRIDAY: "F",
  SATURDAY: "Sa",
  SUNDAY: "Su",
};

const ProviderFilter = ({
  providers,
  handleNameFilter,
  handleSpecialityFilter,
  handleAvailabilityFilter,
  nameFilter,
  specialityFilter,
  AvailabilityFilter,
  isGlobalShow = true,
  isRunScheduler,
  isWelComePage
}) => {
  const [specialityListOpen, setSpecialityListOpen] = useState(false);
  const [AvailabilityListOpen, setAvailabilityListOpen] = useState(false);

  const handleSpecialityListOpen = () => {
    setSpecialityListOpen(!specialityListOpen);
    setAvailabilityListOpen(false);
  };

  const handleAvailabilityListOpen = () => {
    setAvailabilityListOpen(!AvailabilityListOpen);
    setSpecialityListOpen(false);
  };

  return (
    <div className="w-full" >
      {isWelComePage ?
        <div className="flex flex-col flex-wrap w-full gap-8 px-4 m-0 lg:flex-row">
          <div
            style={{ flexGrow: "1" }}
            className={`lg:w-1/4  grow relative flex flex-row items-center gap-2 divide-x-2 rounded-md border-[2px] overflow-clip border-gray-200  ${isRunScheduler ? "w-full " : "w-full md:w-80"
              }`}
          >
            <button className="pl-2">
              <Tooltip title="Search">
                <img
                  src={Icons.Search_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon"
                />
              </Tooltip>
            </button>
            <input
              type="text"
              placeholder="Search by Name ..."
              className="w-full h-full p-2 text-base outline-none"
              value={nameFilter}
              onChange={(e) => handleNameFilter(e.target.value)}
            />
          </div>
          <div
            style={{ flexGrow: "1" }}
            className={`lg:w-1/4  grow relative flex flex-row items-center gap-2 divide-x-2 rounded-md border-[2px] overflow-clip border-gray-200 ${isRunScheduler ? "w-full " : "w-full md:w-80"
              }`}
          >
            <Tooltip title="Filter">
              <button onClick={handleSpecialityListOpen} className="pl-2">
                <img
                  src={Icons.Sort_Icon}
                  alt="search"
                  className="w-5 h-5 cursor-pointer search-icon"
                />
              </button>
            </Tooltip>
            <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
              {specialityFilter.length === 0 && (
                <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                  Filter by Speciality ...
                </p>
              )}
              {specialityFilter.map((speciality, index) => (
                <div
                  key={index}
                  className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder"
                >
                  <p aria-multiline={false} className="m-0 whitespace-nowrap">
                    {speciality}
                  </p>
                  <Tooltip title="Remove">
                    <button
                      onClick={() => handleSpecialityFilter(speciality)}
                      className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
                    >
                      <CloseSvg color={"#BDBDBD"} />
                    </button>
                  </Tooltip>
                </div>
              ))}
            </div>
            {specialityListOpen && (
              <div className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14 speciality-dropdown">
                {Array.from(
                  new Set(providers.map((provider) => provider.speciality))
                ).map((speciality, index) => (
                  <div
                    className={classNames(
                      "hover:bg-primarylightgrey",
                      index !==
                      new Set(providers.map((provider) => provider.speciality))
                        .size -
                      1 && "border-b"
                    )}
                  >
                    <CheckboxSelector
                      key={index}
                      header={speciality}
                      isChecked={
                        specialityFilter.includes(speciality) ? true : false
                      }
                      handleChange={(_e) => handleSpecialityFilter(speciality)}
                      isBold={false}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
          {isGlobalShow && (
            <div
              style={{ flexGrow: "1" }}
              className={`lg:w-1/4   grow relative flex flex-row items-center gap-2 divide-x-2 rounded-md border-[2px] overflow-clip border-gray-200 ${isRunScheduler ? "w-full " : "w-full md:w-80"
                }`}
            >
              <Tooltip title="Filter">
                <button onClick={handleAvailabilityListOpen} className="pl-2">
                  <img
                    src={Icons.Sort_Icon}
                    alt="search"
                    className="w-5 h-5 cursor-pointer search-icon"
                  />
                </button>
              </Tooltip>
              <div className="flex flex-row items-center w-full h-full gap-2 p-2 overflow-scroll cursor-grab flex-nowrap no-scrollbar">
                {AvailabilityFilter.length === 0 && (
                  <p className="m-0 text-base text-primarygrayborder whitespace-nowrap">
                    Filter by Availability ...
                  </p>
                )}
                {AvailabilityFilter.map((Availability, index) => (
                  <div
                    key={index}
                    className="w-fit flex flex-row gap-2 items-center px-2 py-[2px] m-0 text-base text-white rounded-full bg-primarygrayborder"
                  >
                    <p aria-multiline={false} className="m-0 whitespace-nowrap">
                      {Availability}
                    </p>
                    <Tooltip title="Remove">
                      <button
                        onClick={() => handleAvailabilityFilter(Availability)}
                        className="flex items-center justify-center bg-white border border-white rounded-full aspect-square"
                      >
                        <CloseSvg color={"#BDBDBD"} />
                      </button>
                    </Tooltip>
                  </div>
                ))}
              </div>
              {AvailabilityListOpen && (
                <div className="absolute z-50 flex flex-col w-full overflow-auto bg-white border rounded-md max-h-52 top-14">
                  {Array.from(
                    Object.keys(DAY_MAP).map(
                      (day) => day[0] + day.substring(1).toLowerCase()
                    )
                  ).map((Availability, index) => (
                    <div
                      className={classNames(
                        "hover:bg-primarylightgrey",
                        index !==
                        Array.from(
                          Object.keys(DAY_MAP).map(
                            (day) => day[0] + day.substring(1).toLowerCase()
                          )
                        ).length -
                        1 && "border-b"
                      )}
                    >
                      <CheckboxSelector
                        key={index}
                        header={Availability}
                        isChecked={
                          AvailabilityFilter.includes(Availability) ? true : false
                        }
                        handleChange={(_e) =>
                          handleAvailabilityFilter(Availability)
                        }
                        isBold={false}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

        </div> : <div className="flex flex-row items-center w-full gap-3  px-16" >
          <Search value={nameFilter} setValue={handleNameFilter} />
          <FilterSelect
            value={specialityFilter}
            handleChange={handleSpecialityFilter}
            data={Array.from(
              new Set(providers.map((provider) => provider.speciality))
            )}
            placeholder="Filter by Speciality..."
          />
          <FilterSelect
            value={AvailabilityFilter}
            handleChange={handleAvailabilityFilter}
            data={Array.from(
              Object.keys(DAY_MAP).map(
                (day) => day[0] + day.substring(1).toLowerCase()
              )
            )}
            placeholder="Filter by Availability ..."
          />

        </div>
      }
    </div>
  );
};

export default ProviderFilter;
