import React, { useEffect, useState } from "react";
import GlobalSchedulingSettings from "./components/GlobalSchedulingSettings";
import SelectedAppointmentTypesTable from "./components/SelectedAppointmentTypesTable";
import AppointmentTypeModal from "../Configure/AppointmentTypes/components/AppointmentTypeModal";
import ProviderSettingsModal from "../Configure/ProvidersConfig/components/ProviderSettingsModal";
import { useFetchAppointmentTypesMutation } from "../../../features/appointment/appointmentApiSlice";
import { selectUser } from "../../../features/auth/authSlice";
import { useSelector } from "react-redux";
import ProvidersScreen from "../Configure/ProvidersConfig/ProvidersScreen";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Bolt from "../../../assets/svgs/Bolt";
import { ArrowUpwardTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const RunScheduler = ({ setOpaque, providers, setProviders }) => {
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentTypeIdToEdit, setAppointmentTypeIdToEdit] = useState(null);
  const [editAppointmentTypeModalOpen, setEditAppointmentTypeModalOpen] =
    useState(false);
  const [providerIdToEdit, setProviderIdToEdit] = useState(null);
  const [editProviderModalOpen, setEditProviderModalOpen] = useState(false);

  const user = useSelector(selectUser);

  const [getAppointmentTypes] = useFetchAppointmentTypesMutation();

  const fetchAppointmentTypes = async () => {
    try {
      const { data } = await getAppointmentTypes(user.organisation.id).unwrap();

      setAppointmentTypes(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const handleAppointmentTypeEditClick = (appointmentTypeId) => {
    if (editAppointmentTypeModalOpen) {
      setAppointmentTypeIdToEdit(null);
      setOpaque(false);
    } else {
      setAppointmentTypeIdToEdit(appointmentTypeId);
      setOpaque(true);
    }

    setEditAppointmentTypeModalOpen(!editAppointmentTypeModalOpen);
  };

  const handleProviderEditClick = (providerId) => {
    if (editProviderModalOpen) {
      setProviderIdToEdit(null);
      setOpaque(false);
    } else {
      setProviderIdToEdit(providerId);
      setOpaque(true);
    }

    setEditProviderModalOpen(!editProviderModalOpen);
  };

  useEffect(() => {
    fetchAppointmentTypes();
  }, []);

  const visitTypesRef = React.useRef(null);
  const globalSettingsRef = React.useRef(null);
  const providersTableRef = React.useRef(null);

  const navigate = useNavigate();

  return (
    <>
      <div className="max-h-screen pb-12 overflow-y-auto no-scrollbar">
        <div className="flex flex-col p-4">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Bolt height="18" />
              <p className="m-0 text-2xl font-bold text-primaryblue">
                Run the CareSMS Intelligent Scheduler
              </p>
            </div>
            <button
              // onClick={() => {
              //   providersTableRef.current?.scrollIntoView({
              //     behavior: "smooth",
              //   });
              //   if (providersTableRef.current) {
              //     providersTableRef.current.style.backgroundColor =
              //       "rgba(0, 0, 0)";
              //     providersTableRef.current.style.backgroundOpacity = "50%";
              //   }
              // }}
              className="flex border-[1px] rounded-lg border-primaryorange w-36 h-14 items-center justify-center"
            >
              <p className="m-0 font-bold text-primaryorange">Get Help</p>
            </button>
          </div>
          <p className="m-0 text-lg text-primarydarkgrey">
            Confirm your visit settings and required scheduling horizon to
            instantly create daily visit schedules for each of your Providers.
          </p>
        </div>
        {providers.filter((provider) => provider.locationId === null).length >
          0 && (
          <div className="p-4">
            <div
              onClick={() => {
                // setInviteModal(true);
                // setSelectedProvider(provider);
              }}
              className="bg-yellow-50 w-full p-1 gap-1 text-yellow-600 border-[1px] flex items-center justify-between border-primaryyellow rounded-md px-2"
            >
              <div className="flex items-center gap-2">
                <WarningRoundedIcon
                  style={{
                    // fontSize: "20px",
                    marginBottom: "2px",
                  }}
                />
                <p className="m-0 text-lg">Missing Data Points</p>
              </div>
              <button
                onClick={() => navigate("/data-management/providers")}
                className="flex items-center gap-2 px-4 py-2 font-bold text-white rounded-lg bg-primaryyellow"
              >
                Provider Data
                <ArrowUpwardTwoTone
                  style={{
                    rotate: "90deg",
                  }}
                />
              </button>
            </div>
          </div>
        )}
        <div className="flex flex-col w-full gap-0 py-8 md:gap-6">
          {/* <div className="w-full border border-primarygrayborder" /> */}
          <div className="relative pb-4">
            {/* <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-50" /> */}
            <SelectedAppointmentTypesTable
              appointments={appointmentTypes}
              handleEditClick={handleAppointmentTypeEditClick}
              ref={visitTypesRef}
            />
          </div>
          <div className="relative py-4">
            {/* <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-50" /> */}
            <GlobalSchedulingSettings
              providers={providers}
              setProviders={setProviders}
              ref={globalSettingsRef}
            />
          </div>
          <div className="relative pt-4">
            {/* <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-50" /> */}
            <ProvidersScreen
              setOpaque={setOpaque}
              providers={providers}
              setProviders={setProviders}
              isGlobalShow={true}
              isRunScheduler={true}
              ref={providersTableRef}
            />
          </div>
        </div>
        <div className="relative">
          {/* <div className="absolute inset-0 z-10 h-full bg-black bg-opacity-50" /> */}
          <div className="flex justify-center w-full mb-10 -mt-8">
            <p className="text-lg font-bold text-black">
              <span className="text-primaryblue">
                {providers.filter((provider) => provider.isSelected).length > 1
                  ? `${
                      providers.filter((provider) => provider.isSelected).length
                    } Providers `
                  : `${
                      providers.filter((provider) => provider.isSelected).length
                    } Provider `}
              </span>
              selected to schedule
            </p>
          </div>
        </div>
      </div>
      {editAppointmentTypeModalOpen && (
        <AppointmentTypeModal
          appointments={appointmentTypes}
          handleEditClick={handleAppointmentTypeEditClick}
          details={appointmentTypes.find(
            (appointmentType) => appointmentType.id === appointmentTypeIdToEdit
          )}
          setAppointmentTypes={setAppointmentTypes}
          screen="runScheduler"
        />
      )}
      {editProviderModalOpen && (
        <ProviderSettingsModal
          handleEditClick={handleProviderEditClick}
          details={providers.find(
            (provider) => provider.id === providerIdToEdit
          )}
          providers={providers}
          setProviders={setProviders}
          screen="runScheduler"
        />
      )}
    </>
  );
};

export default RunScheduler;
