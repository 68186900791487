import React, { useEffect, useState } from "react";
import ChatSvg from "../../../assets/svgs/Chat";
import InfoSvg from "../../../assets/svgs/InfoSvg";
import LocationSvg from "../../../assets/svgs/LocationSvg";
import UserSvg from "../../../assets/svgs/user";
import HealthSvg from "../../../assets/svgs/HealthSvg";
import CalendarCheckSvg from "../../../assets/svgs/CalendarCheckSvg";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import classNames from "classnames";
import { useLSStore } from "../../../context/ls-store";
import { SCHEDULE_BASE_URL } from "../../../constants/endpoints";
import axios from "axios";
import { capitalize, status } from "../data";
import Lock from "../../../assets/svgs/Lock";
import Unlock from "../../../assets/svgs/Unlock";
import CheckSvg from "../../../assets/svgs/CheckSvg";
import moment from "moment";
import {
  useCancelAppointmentMutation,
  useCreateAppointmentNoteMutation,
  useDeleteAppointmentNoteMutation,
  useEditAppointmentNoteMutation,
  useGetAppointmentNotesMutation,
  useLockAppointmentMutation,
  useUpdateAppointmentStatusMutation,
} from "../../../features/appointment/appointmentApiSlice";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { CancelSharp } from "@mui/icons-material";
import AppointmentCancelModal from "./AppointmentCancelModal";
import AppointmentLockModal from "./AppointmentLockModal";
import { Icon } from "@virtuslab/tetrisly-icons";
import IconComponent from "../../../components/DataTable/Icon";
import NoteDeleteModal from "./NoteDeleteModal";
import { useSelector } from "react-redux";
import { selectUser } from "../../../features/auth/authSlice";
import AppointmentStatusModal from "./AppointmentStatusModal";
import { Tooltip } from "@mui/material";

const AppointmentSelectModal = ({ selectedProvider, setRecallSchedule }) => {
  const selectedAppointment = useLSStore((state) => state.selectedAppointment);
  const setSelectedAppointment = useLSStore(
    (state) => state.setSelectedAppointment
  );
  const user = useSelector(selectUser);

  const [cancelAppointment] = useCancelAppointmentMutation();
  const [lockAppointment] = useLockAppointmentMutation();
  const [getAppointmentNotes, { isLoading: noteLoading }] =
    useGetAppointmentNotesMutation();
  const [createAppointmentNote] = useCreateAppointmentNoteMutation();
  const [editAppointmentNote] = useEditAppointmentNoteMutation();
  const [deleteAppointmentNote] = useDeleteAppointmentNoteMutation();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [appointmentNotes, setAppointmentNotes] = useState([]);
  const [addNote, setAddNote] = useState(false);
  const [note, setNote] = useState("");
  const [id, setId] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [appointmentToChangeStatus, setAppointmentToChangeStatus] =
    useState(null);
  const [updateAppointmentStatus, { isLoading: updateStatusLoading }] =
    useUpdateAppointmentStatusMutation();

  const handleCancel = async () => {
    try {
      await cancelAppointment({
        appointmentId: selectedAppointment.appointmentId,
      }).unwrap();

      console.log(`Appointment ${selectedAppointment.appointmentId} cancelled`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }

    setSelectedAppointment(null);
  };

  const handleLock = async () => {
    try {
      await lockAppointment({
        appointmentId: selectedAppointment.appointmentId,
      }).unwrap();

      console.log(`Appointment ${selectedAppointment.appointmentId} locked`);

      setRecallSchedule(true);

      setSelectedAppointment(null);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStatus = async () => {
    try {
      const status =
        appointmentToChangeStatus.visitStatus === "SCHEDULED"
          ? "CONFIRMED"
          : "COMPLETED";
      await updateAppointmentStatus({
        appointmentId: appointmentToChangeStatus.appointmentId,
        status,
      }).unwrap();

      console.log(
        `Appointment ${appointmentToChangeStatus.appointmentId} status changed to ${status}`
      );

      setRecallSchedule(true);

      setSelectedAppointment(null);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAppointmentNotes = async () => {
    try {
      console.log(
        selectedAppointment.appointmentId,
        "selectedAppointment.appointmentId"
      );
      const { data } = await getAppointmentNotes({
        appointmentId: selectedAppointment.appointmentId,
      }).unwrap();

      console.log("Appointment notes", data);

      setAppointmentNotes(data);
    } catch (error) {
      console.log(error, selectedAppointment);
    }
  };

  useEffect(() => {
    fetchAppointmentNotes();
  }, []);

  const handleCancelModal = () => {
    setOpenCancelModal(!openCancelModal);
  };

  const handleLockModal = () => {
    setOpenLockModal(!openLockModal);
  };

  const handleStatusModal = (status) => {
    if (openLockModal) setAppointmentToChangeStatus(null);
    setOpenStatusModal(!openStatusModal);
  };

  const handleAddNote = () => {
    setAddNote(!addNote);
    if (addNote) {
      setNote("");
      setId(null);
    }
  };

  const onEdit = (note) => {
    setAddNote(!addNote);
    setId(note.id);
    setNote(note.description);
  };

  const onDelete = (note) => {
    if (isDelete) {
      setIsDelete(false);
      setId(null);
    } else {
      setIsDelete(true);
      setId(note.id);
    }
  };

  const handleSaveNote = async () => {
    try {
      const { data } = await createAppointmentNote({
        appointmentId: selectedAppointment.appointmentId,
        description: note,
      }).unwrap();

      console.log("Appointment notes", data);
      handleAddNote();

      setAppointmentNotes((prev) => [
        ...prev,
        { ...data, createdBy: `${user.firstname} ${user.lastname}` },
      ]);
    } catch (error) {
      console.log(error, selectedAppointment);
    }
  };

  const handleUpdateNote = async () => {
    try {
      const { data } = await editAppointmentNote({
        noteId: id,
        description: note,
      }).unwrap();

      console.log("Appointment notes", data);
      handleAddNote();

      setAppointmentNotes((prev) =>
        prev.map((note) => {
          if (note.id === id) {
            return data;
          }
          return note;
        })
      );
    } catch (error) {
      console.log(error, selectedAppointment);
    }
  };

  const handleDeleteNote = async () => {
    try {
      const { data } = await deleteAppointmentNote({
        noteId: id,
      }).unwrap();

      console.log("Appointment notes", data);
      handleAddNote();

      setAppointmentNotes((prev) => prev.filter((note) => note.id !== id));
    } catch (error) {
      console.log(error, selectedAppointment);
    }
  };

  return (
    <>
      {/* <ReusableModal open={selectedAppointment} hideHeader> */}
      <div
        className={classNames(
          "absolute top-[0px] overflow-scroll transition-all min-h-svh bg-white w-96 border-l border-t border-b border-secondarygrayborder right-0 opacity-100",
          selectedAppointment ? "right-0" : "right-[-400px]"
        )}
      >
        <div className="flex flex-row items-center justify-between w-full p-4">
          <p className="m-0 text-2xl font-bold text-primaryblack">
            Appointment Details
          </p>
          <Tooltip title="Close">
            <button
              onClick={() => {
                setSelectedAppointment(null);
              }}
              className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#3062D4"} />
            </button>
          </Tooltip>
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-12 p-4">
          <div className="flex flex-row items-center justify-start gap-4">
            <CalendarCheckSvg color={"#3062D4"} />
            {selectedAppointment !== null ? (
              <p className="m-0 text-base text-primaryblack w-fit">
                Scheduled on{" "}
                {moment(selectedAppointment.visitDate).format("ll")}
              </p>
            ) : (
              <div className="relative w-64 h-6 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <LocationSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedAppointment !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    {selectedAppointment.location.name}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    {selectedAppointment.location.address}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-52 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-72 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <UserSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedAppointment !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    {selectedAppointment.patient.name} | ID{" "}
                    {selectedAppointment.patient.idInEhr}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    Last seen:{" "}
                    {selectedAppointment.lastSeenOn === "NA"
                      ? selectedAppointment.lastSeenOn
                      : moment(selectedAppointment.lastSeenOn).format("ll")}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-44 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-52 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <HealthSvg color={"#3062D4"} />
            <div className="flex flex-col w-fit">
              {selectedProvider !== null ? (
                <>
                  <p className="m-0 text-base text-primaryblack w-fit">
                    Dr. {selectedProvider.name}
                  </p>
                  <p className="m-0 text-base text-primarytextgrey w-fit">
                    {selectedProvider.speciality}
                  </p>
                </>
              ) : (
                <>
                  <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-36 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                  <div className="relative h-6 overflow-hidden border rounded-md w-28 border-primarylightgrey bg-primarylightgrey animate-pulse">
                    <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-4">
            <InfoSvg color={"#3062D4"} />
            {selectedAppointment !== null ? (
              <p className="m-0 text-base text-primaryblack w-fit">
                {selectedAppointment.appointmentType.name}
              </p>
            ) : (
              <div className="relative h-6 mb-1 overflow-hidden border rounded-md w-36 border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-2 p-4">
          <div className="flex justify-between">
            <div className="flex flex-row items-center justify-start gap-4">
              <ChatSvg color={"#3062D4"} />
              <p className="m-0 text-base font-bold text-primaryblack w-fit">
                Patient Notes
              </p>
            </div>
          </div>
          <span className="text-base">
            {selectedAppointment?.patient?.notes}
          </span>
        </div>
        <div className="flex flex-col w-full gap-6 p-4">
          <div className="flex justify-between">
            <div className="flex flex-row items-center justify-start gap-4">
              <ChatSvg color={"#3062D4"} />
              <p className="m-0 text-base font-bold text-primaryblack w-fit">
                Appointment Notes
              </p>
            </div>
            <Tooltip title="Add note">
              <button onClick={handleAddNote}>
                <Icon name="20-plus" />
              </button>
            </Tooltip>
          </div>
          {noteLoading ? (
            <div className="relative w-full h-16 mb-1 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
              <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
            </div>
          ) : (
            appointmentNotes?.map((note) => {
              const { firstname, lastname } =
                note?.creator?.back_office_staff || {};
              return (
                <div className="flex flex-col w-full">
                  <div className="flex flex-col m-0">
                    <div className="flex justify-between">
                      <span className="text-base font-bold">
                        {note?.createdBy || `${firstname} ${lastname}`}
                      </span>{" "}
                      <div className="flex items-center gap-3">
                        <Tooltip title="Edit note">
                          <button>
                            <IconComponent
                              icon={"Edit_Icon"}
                              i={note}
                              onClick={() => onEdit(note)}
                            />
                          </button>
                        </Tooltip>
                        <Tooltip title="Delete note">
                          <button>
                            <IconComponent
                              icon={"Delete_Icon"}
                              i={note}
                              onClick={() => onDelete(note)}
                            />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <span className="text-base">{note.description}</span>
                  </div>
                  <p className="m-0 text-xs italic text-primarytextgrey">
                    {moment(note.createdAt).format("hh:mm a MM/DD/YY")}
                  </p>
                </div>
              );
            })
          )}
          {addNote && (
            <div>
              <textarea
                rows={3}
                placeholder="Add a note for this appointment..."
                className="w-full px-4 py-2 rounded-md border-1 placeholder:text-primarytextgrey border-primarygrayborder"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
              <div className="flex justify-end gap-2 py-2">
                <button
                  className="p-1 font-semibold border border-black rounded-md"
                  onClick={handleAddNote}
                >
                  Cancel
                </button>
                <button
                  className="p-1 px-2 font-semibold text-white rounded-md bg-primaryblue"
                  onClick={id ? handleUpdateNote : handleSaveNote}
                >
                  {id ? "Save" : "Add"}
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="w-full border border-primarygrayborder" />
        <div className="flex flex-col w-full gap-4 p-4 mb-4">
          <div className="w-full">
            {selectedAppointment !== null ? (
              <button
                disabled={["COMPLETED", "CANCELLED"].includes(
                  selectedAppointment.visitStatus
                )}
                className={classNames(
                  "flex flex-row items-center justify-center w-full gap-2 px-3 py-2 rounded-md shadow-sm font-semibold",
                  selectedAppointment.visitStatus === "COMPLETED"
                    ? "text-primarygreen border-[1px] border-primarygreen"
                    : selectedAppointment.visitStatus === "SCHEDULED"
                    ? "text-primaryyellow border-[1px] border-primaryyellow"
                    : selectedAppointment.visitStatus === "CANCELLED"
                    ? "text-primaryred border-[1px] border-primaryred"
                    : "text-primaryblue border-[1px] border-primaryblue"
                )}
                onClick={() => {
                  setAppointmentToChangeStatus(selectedAppointment);
                  handleStatusModal(selectedAppointment.visitStatus);
                }}
              >
                {selectedAppointment.visitStatus === "SCHEDULED" ? (
                  // <Unlock color={"#F2C94C"} />
                  <Icon name="20-hourglass" color={"#F2C94C"} />
                ) : selectedAppointment.visitStatus === "CONFIRMED" ? (
                  <Icon name="20-calendar-check" color={"#3062D4"} />
                ) : selectedAppointment.visitStatus === "CANCELLED" ? (
                  <Icon name="20-calendar-close" color={"#eb5757"} />
                ) : (
                  <CheckSvg color={"#27AE60"} />
                )}
                {selectedAppointment.visitStatus === "SCHEDULED"
                  ? "Pending"
                  : selectedAppointment.visitStatus === "CONFIRMED"
                  ? "Confirmed"
                  : selectedAppointment.visitStatus === "CANCELLED"
                  ? "Cancelled"
                  : "Completed"}
              </button>
            ) : (
              <div className="relative w-full h-10 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
          <div className="w-full">
            {selectedAppointment !== null ? (
              <button
                className={classNames(
                  "flex flex-row items-center justify-center w-full gap-2 px-3 py-2 rounded-md shadow-sm",
                  selectedAppointment.status === status.published
                    ? "bg-primarygreen"
                    : selectedAppointment.status === status.unlocked
                    ? "bg-primaryyellow"
                    : "bg-primaryblue"
                )}
                disabled={selectedAppointment.status === status.published}
                onClick={handleLockModal}
              >
                {selectedAppointment.status === status.published ? (
                  <div className="w-[18px] h-[18px] bg-white rounded-full">
                    <CheckSvg color={"#6FCF97"} />
                  </div>
                ) : selectedAppointment.status === status.unlocked ? (
                  <Unlock color={"#FFF"} />
                ) : (
                  <Lock color={"#FFF"} />
                )}
                <p className="m-0 text-base font-semibold text-white">
                  {selectedAppointment.status === status.published
                    ? "Published"
                    : selectedAppointment.status === status.unlocked
                    ? "Unlocked"
                    : "Locked"}
                </p>
              </button>
            ) : (
              <div className="relative w-full h-10 overflow-hidden border rounded-md border-primarylightgrey bg-primarylightgrey animate-pulse">
                <div className="absolute top-0 left-0 w-8 h-full bg-gray-200 pulse" />
              </div>
            )}
          </div>
          {!["COMPLETED", "CANCELLED"].includes(
            selectedAppointment.visitStatus
          ) && (
            <div className="w-full">
              <button
                onClick={handleCancelModal}
                className="flex flex-row items-center justify-center w-full px-3 py-2 border rounded-md shadow-sm border-primarygrayborder"
              >
                <p className="m-0 text-base font-semibold text-primaryred">
                  Cancel Appointment
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
      <NoteDeleteModal
        open={isDelete}
        handleCancelModal={onDelete}
        handleCancel={handleDeleteNote}
      />
      <AppointmentCancelModal
        open={openCancelModal}
        handleCancelModal={handleCancelModal}
        handleCancel={handleCancel}
      />
      <AppointmentLockModal
        open={openLockModal}
        handleLockModal={handleLockModal}
        handleLock={handleLock}
        status={selectedAppointment?.status}
      />
      <AppointmentStatusModal
        open={openStatusModal}
        handleCancel={handleStatusModal}
        handleSubmit={handleChangeStatus}
        status={appointmentToChangeStatus?.visitStatus}
      />
    </>
  );
};

export default AppointmentSelectModal;
