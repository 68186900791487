import React from "react";
import Logo from "../../../assets/images/CareSMS_Logo.png";
import { PlayArrow } from "@mui/icons-material";
import { useForgotMutation } from "../../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../../../components/Loading";
import { isStrongPassword } from "../../../utilities/resuseFunctions";

const ForgotPassword = ({ email }) => {
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [forgot, { isLoading }] = useForgotMutation();

  const isButtonDisabled = () => {
    return !password || !confirmPassword || isLoading;
  };

  const handleAccountCreation = async () => {
    if (password !== confirmPassword) {
      setErrMsg("Passwords don't match!");
      return;
    }
    if (!isStrongPassword(password)) {
      setErrMsg(
        "Password must contain at least 8 characters, one uppercase, one lowercase, one digit and one special character."
      );
      return;
    }
    try {
      await forgot({
        email,
        password,
      }).unwrap();

      alert(
        "Password reset successful. Please sign in with your new password."
      );

      navigate("/signin");
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  return (
    <div className="relative flex flex-col w-full gap-8 mt-8 sm:w-11/12 base:mt-16">
      {isLoading && <Loading loading={isLoading} />}
      <div className="flex flex-col gap-8">
        <img src={Logo} alt="logo" className="-ml-2 logo" />
        <h1 className="m-0 text-2xl font-bold">Forgot password</h1>
      </div>
      <div className="flex flex-col w-80 gap-6">
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Email address
            <span className="text-red-500">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            value={email}
            disabled
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Password
            <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            onBlur={() => {
              if (!isStrongPassword(password)) {
                setErrMsg(
                  "Password must contain at least 8 characters, one uppercase, one lowercase, one digit and one special character."
                );
              }
            }}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label htmlFor="email" className="text-sm font-semibold">
            Confirm Password
            <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            id="text"
            name="text"
            className="p-2 text-sm border rounded-lg border-primarylightgrey"
            placeholder="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={() => {
              if (!isStrongPassword(password)) {
                setErrMsg(
                  "Password must contain at least 8 characters, one uppercase, one lowercase, one digit and one special character."
                );
              } else if (password !== confirmPassword) {
                setErrMsg("Passwords don't match!");
              }
            }}
          />
        </div>
        {errMsg && <p className="m-0 text-xs text-danger">{errMsg}</p>}
        <button
          disabled={isButtonDisabled()}
          onClick={handleAccountCreation}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-white rounded-lg bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
        >
          Change password
          <div className="relative flex flex-row">
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
              }}
            />
            <PlayArrow
              style={{
                color: "#fff",
                width: "18px",
                height: "18px",
                objectFit: "contain",
                position: "absolute",
                left: -5,
              }}
            />
          </div>
        </button>
        <button
          disabled={isLoading}
          onClick={() => navigate("/signin")}
          className="flex items-center justify-center w-full gap-2 p-2 mt-8 font-semibold text-black bg-white rounded-lg shadow-sm"
        >
          Go back to sign in
        </button>
      </div>
      <p className="w-full mt-12 text-xs text-center">
        2024 CareSMS Inc. All rights reserved.
      </p>
    </div>
  );
};

export default ForgotPassword;
