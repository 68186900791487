import classNames from "classnames";
import moment from "moment";
import React from "react";
import CloseSvg from "../../assets/svgs/CloseSvg";
import {
  useClosestPatientAppointmentDistancesMutation,
  useCreateOneAppointmentMutation,
} from "../../features/appointment/appointmentApiSlice";
import MapV3 from "../../components/Maps/MapV3";
import ReusableModal from "../../components/Modals/ReusableModal";
import Select from "react-select";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import Loading from "../../components/Loading";
import { Tooltip } from "@mui/material";
import CheckSvg from "../../assets/svgs/CheckSvg";
import { Edit_Icon } from "../../assets/images";
import { DropArea } from "../LongitudinalSchedules/components/DropArea";
import { flushSync } from "react-dom";

const PatientSchedulingModal = ({
  filterOpen,
  providerAppointment,
  setFilterOpen,
  setProviderAppointment,
  appointments,
  setAppointments,
  dates,
  dateIndex,
  selectedPatient,
  departmentId,
  appointmentTypes,
  setOpen,
  providers,
  resetAllandFetch,
}) => {
  const user = useSelector(selectUser);
  const [fetchClosestDistance] =
    useClosestPatientAppointmentDistancesMutation();

  const [updatedAppointments, setUpdatedAppointments] = React.useState([]);

  const [uniqueLocations, setUniqueLocations] = React.useState([]);

  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);

  const [appointmentType, setAppointmentType] = React.useState(null);

  const [updates, setUpdates] = React.useState([]);

  const [editable, setEditable] = React.useState(false);
  const [draggingAppointment, setDraggingAppointment] = React.useState(null);

  const [distanceFromPreviousAppointment, setDistanceFromPreviousAppointment] =
    React.useState(0);

  const [distanceFromProvider, setDistanceFromProvider] = React.useState(0);

  const getClosestDistance = async (appointments, position) => {
    const { data } = await fetchClosestDistance({
      date: moment(dates[dateIndex]).format("YYYY-MM-DD"),
      patientId: selectedPatient?.value,
      providerId: providerAppointment.provider.id,
      position: position,
    });

    const previousAppointments = appointments
      .slice(0, position - 1)
      .map((appointment) => {
        return {
          ...appointment,
          sequence: appointment.sequenceNo,
        };
      });

    const appointment = {
      patient: {
        name: data.data.name,
      },
      sequence: position,
      location: data.data.location,
      distance: previousAppointments.length === 0 ? 0 : data.data.distance[0],
      distanceFromHome:
        previousAppointments.length === 0 ? data.data.distance[0] : 0,
      proposedPatient: true,
    };

    if (previousAppointments.length === 0) {
      setDistanceFromProvider(data.data.distance[0]);
      setDistanceFromPreviousAppointment(0);
    } else {
      setDistanceFromProvider(0);
      setDistanceFromPreviousAppointment(data.data.distance[0]);
    }

    const nextAppointments = appointments
      .slice(position - 1)
      .map((appointment, index) => {
        return {
          ...appointment,
          sequence: appointment.sequenceNo + 1,
          distance: index === 0 ? data.data.distance[1] : appointment.distance,
        };
      });

    const updatedAppointments = [
      ...previousAppointments,
      appointment,
      ...nextAppointments,
    ];

    const updates = nextAppointments.map((appointment) => {
      return {
        id: appointment.appointmentId,
        sequenceNumber: appointment.sequence,
        distanceFromPreviousAppointment: +appointment.distance,
      };
    });

    setUpdates(updates);

    setUpdatedAppointments(updatedAppointments);

    setProviderAppointment((prev) => ({
      ...prev,
      position,
    }));

    const locationMap = updatedAppointments.reduce(
      (acc, appointment, index) => {
        if (!acc[appointment.location.id]) {
          acc[appointment.location.id] = {
            location: appointment.location,
            appointments: [],
          };
        }
        acc[appointment.location.id].appointments.push({
          sequence: index + 1,
          patient: appointment.patient.name,
          proposedPatient: index + 1 === position,
        });
        return acc;
      },
      {}
    );

    const uniqueLocations = Object.values(locationMap);

    setUniqueLocations(uniqueLocations);
  };

  React.useEffect(() => {
    if (providerAppointment)
      getClosestDistance(appointments, providerAppointment.position);
  }, [appointments, providerAppointment?.position]);

  React.useEffect(() => {}, [updatedAppointments]);

  const onDrop = async (index) => {
    if (draggingAppointment === null) return;
    getClosestDistance(appointments, index + 1);
  };

  const [createOneAppointment, { isLoading }] =
    useCreateOneAppointmentMutation();

  const handleSubmit = async () => {
    try {
      const result = await createOneAppointment({
        visitDate: moment(dates[dateIndex]).format("YYYY-MM-DD"),
        appointmentTypeId: appointmentType.value,
        patientId: selectedPatient?.value,
        providerId: providerAppointment.provider.id,
        lastVisitDate: null,
        visitType: "ADHOC",
      }).unwrap();

      result?.data?.message === "success" ? setOpen(true) : "";
      setOpenConfirmModal(false);
      resetAllandFetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="w-1/2">
        <Loading loading={isLoading} />
        <div className="w-full h-[1px] bg-gray-200" />
        <div className="w-1/2 p-4">
          <MapV3
            locations={uniqueLocations}
            provider={providerAppointment?.provider}
            providers={providers}
            selectedPatient={selectedPatient}
          />
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        {updatedAppointments.length > 0 && (
          <div className="flex justify-end px-4 py-2">
            <button
              onClick={() => {
                setEditable(!editable);
              }}
              className="flex items-center justify-center w-10 h-10 transition-all duration-200 ease-in-out rounded-md bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {!editable ? (
                <Tooltip title="Edit appointment sequence">
                  <img
                    src={Edit_Icon}
                    alt="edit"
                    className="object-contain w-5 h-5"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Save">
                  <CheckSvg color={"#0d6efd"} />
                </Tooltip>
              )}
            </button>
          </div>
        )}
        <div className="flex flex-col w-full gap-2 px-4 mb-24 h-[calc(100vh-590px)] overflow-y-auto">
          <DropArea onDrop={() => onDrop(0)} />
          {updatedAppointments.map((appointment, index) => (
            <>
              <button
                key={index}
                draggable={editable}
                onDragStart={() => setDraggingAppointment(appointment)}
                onDragEnd={() => setDraggingAppointment(null)}
                className={classNames(
                  "flex items-center gap-4 p-4 border rounded-lg shadow-sm",
                  appointment.proposedPatient && "bg-subtleorange"
                )}
              >
                <div
                  className={classNames(
                    "flex items-center justify-center h-8 rounded-full min-w-8 bg-primaryblue",
                    providerAppointment.sequence === index + 1
                      ? "bg-primaryorange"
                      : "bg-primaryblue"
                  )}
                >
                  <p className="m-0 font-bold text-white">
                    {appointment.sequence}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="m-0 text-lg font-bold">
                    {appointment.patient.name} | {appointment.location.name}
                  </p>
                  <p
                    className={classNames(
                      "m-0 text-sm text-start",
                      appointment.proposedPatient
                        ? "text-black"
                        : "text-gray-400"
                    )}
                  >
                    {index === 0
                      ? `${appointment.distanceFromHome} miles from Home`
                      : `${appointment.distance} miles from Visit ${index}`}
                  </p>
                </div>
              </button>
              <DropArea onDrop={() => onDrop(index + 1)} />
            </>
          ))}
        </div>
        <div className="w-full h-[1px] bg-gray-200" />
        {updatedAppointments.length > 0 && (
          <div className="fixed bottom-0 flex items-center justify-center w-1/2 p-2 bg-white border-t">
            <button
              onClick={() => setOpenConfirmModal(true)}
              className="py-2 px-4 font-bold border-[1px] shadow-sm rounded-md text-primarytextgreen border-primarytextgreen"
            >
              Add to Schedule
            </button>
          </div>
        )}
      </div>
      <ReusableModal open={openConfirmModal} hideHeader>
        <div className="flex flex-col items-center min-w-[480px]">
          <div className="flex flex-row items-center justify-between w-full p-6">
            <p className="m-0 text-2xl font-bold text-primaryblack">
              New Appointment
            </p>
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="flex border-[2px] border-primarygrayborder items-center justify-center w-8 rounded-full aspect-square bg-primarylightgrey"
            >
              <CloseSvg color={"#828282"} />
            </button>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-col w-full gap-2 p-4">
            <label htmlFor="patientSelect" className="text-sm font-bold">
              Appointment Type
            </label>
            <div className="w-full">
              <Select
                options={appointmentTypes}
                isSearchable
                isClearable
                onChange={(selectedOption) => {
                  setAppointmentType(selectedOption);
                }}
              />
            </div>
          </div>
          <div className="h-[1px] w-full bg-primarygrayborder" />
          <div className="flex flex-row items-center justify-between w-full gap-6 p-4">
            <button
              onClick={() => setOpenConfirmModal(false)}
              className="w-1/2 p-2 font-semibold rounded-md bg-primarylightgrey border-[2px] text-primarytextgrey border-primarygrayborder"
            >
              Cancel
            </button>
            <button
              disabled={!appointmentType}
              onClick={handleSubmit}
              className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            >
              Create
            </button>
          </div>
        </div>
      </ReusableModal>
    </>
  );
};

export default PatientSchedulingModal;
