import { apiSlice } from "../../app/api/apiSlice";

export const organisationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOrganisation: builder.mutation({
      query: (credentials) => ({
        url: "/organisation",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    saveMetadata: builder.mutation({
      query: (credentials) => ({
        url: "/organisation/save-metadata",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    selectPlan: builder.mutation({
      query: (credentials) => ({
        url: "/organisation/subscribe",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getProviders: builder.mutation({
      query: (credentials) => ({
        url: `/organisation/providers/${credentials}/1`,
        method: "GET",
      }),
    }),
    fetchOrganisationCommunities: builder.mutation({
      query: (credentials) => ({
        url: `/organisation/locations/${credentials}`,
        method: "GET",
      }),
    }),
    fetchVisitRules: builder.mutation({
      query: (credentials) => ({
        url: `/organisation/visit-rules/${credentials}`,
        method: "GET",
      }),
    }),
    getPatients: builder.mutation({
      query: ({ orgId, limit = 20, skip = 0, search, location, status }) => ({
        url: `/organisation/patients/${orgId}/0`,
        method: "POST",
        body: { limit, skip, search, location, status },
      }),
    }),
    setEHR: builder.mutation({
      query: (data) => ({
        url: `/organisation/set-ehr`,
        method: "POST",
        body: data,
      }),
    }),
    practiseId: builder.mutation({
      query: (credentials) => ({
        url: `/organisation/set-practice-id`,
        method: "POST",
        body: {
          practiceId: `${credentials.practiceId}`,
          ehrId: `${credentials.ehrId}`,
        },
      }),
    }),
    checkEHRUploadStatus: builder.mutation({
      query: () => ({
        url: `/organisation/check-ehr-status`,
        method: "GET",
      }),
    }),
    fetchCommunities: builder.mutation({
      query: ({ orgId, limit, skip, search }) => ({
        url: `/organisation/communities/${orgId}?limit=${limit}&skip=${skip}&search=${search}`,
        method: "GET",
      }),
    }),
    fetchUploadHistory: builder.mutation({
      query: (org_id) => ({
        url: `/organisation/upload-history/${org_id}`,
        method: "GET",
      }),
    }),
    fetchContacts: builder.mutation({
      query: () => ({
        url: `/organisation/contacts`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useCreateOrganisationMutation,
  useSaveMetadataMutation,
  useSelectPlanMutation,
  useGetProvidersMutation,
  useFetchOrganisationCommunitiesMutation,
  useFetchVisitRulesMutation,
  useGetPatientsMutation,
  useSetEHRMutation,
  usePractiseIdMutation,
  useCheckEHRUploadStatusMutation,
  useFetchCommunitiesMutation,
  useFetchUploadHistoryMutation,
  useFetchContactsMutation,
} = organisationApiSlice;
