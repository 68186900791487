export const status = {
  published: "PUBLISHED",
  locked: "LOCKED",
  unlocked: "UNLOCKED",
  confirmed: "CONFIRMED",
  ["Pending Confirmation"]: "PENDING",
  ["Published to EHR"]: "PUBLISHED",
};

// export const appointmentStatuses = [
//   "All",
//   status["Published to EHR"],
//   status.locked,
//   status.confirmed,
//   status["Pending Confirmation"]
// ];
export const appointmentStatuses = [
  { name: "All" },
  { name: "Pending Confirmation", value: status["Pending Confirmation"] },
  { name: "Locked", value: status.locked },
  { name: "confirmed", value: status.confirmed },
  { name: "Published to EHR", value: status["Published to EHR"] },
  { name: "unlocked", value: status["unlocked"] },
];

export const locationTypes = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "PRIVATE_HOME",
    name: "Private Home",
  },
  {
    id: "COMMUNITY_HOME",
    name: "Community",
  },
];

export function capitalize(string) {
  const str_arr = string.split(" ");

  for (let i = 0; i < str_arr.length; i++) {
    str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1);
  }
  return str_arr.join(" ");
}
