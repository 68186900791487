import React, { useEffect, useState } from "react";
import {
  useCreateProviderLeaveMutation,
  useFetchProviderLeavesMutation,
  useRemoveProviderLeaveMutation,
  useUpdateProviderLeaveMutation,
  useUpsertProviderConstraintMutation,
} from "../../../../../features/provider/providerApiSlice";

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseSvg from "../../../../../assets/svgs/CloseSvg";
import IconComponent from "../../../../../components/DataTable/Icon";
import ReusableModal from "../../../../../components/Modals/ReusableModal";
import axios from "axios";
import moment from "moment";

const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

const LEAVE_TYPE = {
  GEN: "General",
  PTO: "PTO",
};

const ProviderSettingsModal = ({
  handleEditClick,
  details,
  providers,
  setProviders,
  screen = "Provider",
  setProviderIsAv,
  setIsCalenderOpen,
  setEditModalOpen
}) => {
  const [selectedDays, setSelectedDays] = useState(details.workingDays);
  const [visitsPerDay, setVisitsPerDay] = useState(details.visitsPerDay);
  const [from, setFrom] = useState(details.startDate);
  const [to, setTo] = useState(details.endDate);

  const [leaves, setLeaves] = useState([]);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [ptoToEdit, setPtoToEdit] = useState(null);

  const handleSelectAll = () => {
    setSelectedDays(days);
  };

  const [upsertProviderConstraintAPI] = useUpsertProviderConstraintMutation();

  const upsertProviderConstraint = async () => {
    try {
      await upsertProviderConstraintAPI({
        id: details.id,
        data: {
          working_days: selectedDays,
          visits_per_day: +visitsPerDay,
          current_start_date: from
            ? moment(from).format("YYYY-MM-DD")
            : undefined,
          current_end_date: to ? moment(to).format("YYYY-MM-DD") : undefined,
        },
      }).unwrap();

      const updatedProviders = providers.map((provider) => {
        if (provider.id === details.id) {
          return {
            ...provider,
            workingDays: selectedDays,
            visitsPerDay: +visitsPerDay,
            startDate: from ? moment(from).format("YYYY-MM-DD") : undefined,
            endDate: to ? moment(to).format("YYYY-MM-DD") : undefined,
          };
        }

        return provider;
      });

      setProviders(updatedProviders);

      handleEditClick(null);
    } catch (error) {
      console.log(error);
    }
  };

  const setDeafultValues = () => {
    setName("");
    setType("");
    setStartDate("");
    setEndDate("");
  };

  const [fetchProviderLeavesAPI] = useFetchProviderLeavesMutation();

  const fetchProviderLeaves = async () => {
    try {
      const { data: response } = await fetchProviderLeavesAPI({
        id: details.id,
      }).unwrap();

      setLeaves(response);
    } catch (error) {
      console.log(error);
    }
  };

  const [createProviderLeaveAPI] = useCreateProviderLeaveMutation();

  const createProviderLeave = async () => {
    try {
      if (!name || !type || !startDate || !endDate) return;

      await createProviderLeaveAPI({
        id: details.id,
        data: { name, type, start_date: startDate, end_date: endDate },
      }).unwrap();

      const updatedProviders = providers.map((provider) => {
        if (provider.id === details.id) {
          return {
            ...provider,
            ptos: provider.ptos + 1,
          };
        }

        return provider;
      });

      setProviders(updatedProviders);

      fetchProviderLeaves();
      setDeafultValues();
    } catch (error) {
      console.log(error);
    }
  };

  const [updateProviderLeaveAPI] = useUpdateProviderLeaveMutation();

  const updateProviderLeave = async (id) => {
    try {
      if (!name || !type || !startDate || !endDate) return;

      await updateProviderLeaveAPI({
        id,
        data: { name, type, start_date: startDate, end_date: endDate },
      }).unwrap();

      fetchProviderLeaves();
      setDeafultValues();
      setPtoToEdit(null);
    } catch (error) {
      console.log(error);
    }
  };

  const [deleteProviderLeaveAPI] = useRemoveProviderLeaveMutation();

  const deleteProviderLeave = async (id) => {
    try {
      await deleteProviderLeaveAPI({
        id,
      }).unwrap();

      const updatedProviders = providers.map((provider) => {
        if (provider.id === details.id) {
          return {
            ...provider,
            ptos: provider.ptos - 1,
          };
        }

        return provider;
      });

      setProviders(updatedProviders);

      fetchProviderLeaves();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditClickPTO = (pto) => {
    if (ptoToEdit) {
      setPtoToEdit(null);
      setDeafultValues();
    } else {
      setPtoToEdit(pto);
      setName(pto.name);
      setType(pto.type);
      setStartDate(pto.startDate);
      setEndDate(pto.endDate);
    }
  };

  useEffect(() => {
    fetchProviderLeaves();
  }, []);

  return (
    <ReusableModal
      open
      title={"Settings | " + details.name}
      onClose={() => handleEditClick(null)}
    >
      {screen !== "Provider" && (
        <>
          <div className="flex flex-col items-start justify-between gap-4 p-4">
            <h1 className="m-0 text-lg font-bold">Scheduling Horizon</h1>
            <div className="flex flex-row items-center gap-6">
              <div className="flex flex-col gap-2">
                <label className="font-semibold">Start Date</label>
                <input
                  type="date"
                  className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                  min={moment().format("YYYY-MM-DD")}
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-semibold">End Date</label>
                <input
                  type="date"
                  className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500 disabled:opacity-40"
                  min={from}
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="w-full border border-primarygrayborder" />
        </>
      )}
      {screen === "Provider" && (
        <div className="overflow-auto min-h-[64vh] max-h-[64vh]">
          <div className="w-full border border-primarygrayborder" />
          <div className="flex flex-col items-start justify-between gap-4 p-4">
            {/* <h1 className="m-0 text-lg font-bold">
              Availability
              <button className="ml-2 text-base" onClick={handleSelectAll}>
                (<span className="underline text-primaryblue">select all</span>)
              </button>
            </h1> */}
            <div className="flex justify-between items-center w-full">
              <h1 className="m-0 text-lg font-bold">
                Availability
                <button className="ml-2 text-base" onClick={handleSelectAll}>
                  (<span className="underline text-primaryblue">select all</span>)
                </button>
              </h1>
              {/* <CalendarMonthIcon className="text-primaryblue cursor-pointer" /> */}
              <button
                className="px-2 py-1 ml-1 text-grey-700 hover:text-primaryblue bg-gray-200 rounded-md calendar-icon"
                onClick={() => {
                  setIsCalenderOpen(true);
                  setEditModalOpen(false);
                }}
              >
                <CalendarMonthIcon />
              </button>
            </div>
            <div className="flex flex-row flex-wrap w-full gap-2">
              {days.map((data, index) => (
                <label
                  key={index}
                  className="flex flex-row items-center gap-2 row-gap-4"
                >
                  <input
                    type="checkbox"
                    className="w-4 h-4 checked:lightblue"
                    checked={selectedDays.includes(data)}
                    onChange={() => {
                      if (selectedDays.includes(data)) {
                        setSelectedDays(
                          selectedDays.filter((day) => day !== data)
                        );
                      } else {
                        setSelectedDays([...selectedDays, data]);
                      }
                    }}
                  />
                  <p className="m-0 text-sm">
                    {data[0] + data.substring(1).toLowerCase()}
                  </p>
                </label>
              ))}
            </div>
          </div>
          <div className="w-full border border-primarygrayborder" />
          <div className="flex flex-row items-center justify-between divide-x-2">
            <div className="flex flex-col items-start justify-start w-full gap-4 p-4">
              <p className="m-0 font-bold">Target Visits Per Day</p>
              <select
                className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                value={visitsPerDay}
                onChange={(e) => setVisitsPerDay(e.target.value)}
              >
                <option value={null}>Select</option>
                {Array.from({ length: 26 }, (_, i) => i + 5).map(
                  (data, index) => (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="w-full border border-primarygrayborder" />
          <div className="flex flex-row items-center gap-4">
            <div className="flex flex-col items-start justify-start w-full gap-4 py-6 pl-6">
              <p className="m-0 font-bold">Leaves</p>
              <div className="flex flex-row items-center gap-4">
                <input
                  type="text"
                  className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-4 py-6">
              <p className="m-0 font-bold">Leave Type</p>
              <div className="flex flex-row items-center gap-4">
                <select
                  className="block h-[38px] p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value={""}>Select</option>
                  <option value={"GEN"}>Unpaid Leave</option>
                  <option value={"PTO"}>PTO</option>
                </select>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-4 py-6">
              <p className="m-0 font-bold">Start Date</p>
              <div className="flex flex-row items-center gap-4">
                <input
                  type="date"
                  className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Start date"
                  min={moment().format("YYYY-MM-DD")}
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start w-full gap-4 py-6 pr-6">
              <p className="m-0 font-bold">End Date</p>
              <div className="flex flex-row items-center gap-4">
                <input
                  type="date"
                  className="p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                  placeholder="End date"
                  value={endDate}
                  min={startDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                {!ptoToEdit ? (
                  <button
                    className="px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue"
                    onClick={createProviderLeave}
                  >
                    Add
                  </button>
                ) : (
                  <div className="flex flex-row gap-2">
                    <button
                      className="px-4 py-2 text-sm font-bold text-white bg-gray-400 rounded-lg"
                      onClick={() => {
                        handleEditClickPTO(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue"
                      onClick={() => {
                        updateProviderLeave(ptoToEdit.id);
                      }}
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full border border-primarygrayborder" />
          <div className="p-4 overflow-auto max-h-96 no-scrollbar">
            <p className="text-lg font-bold">
              {/* You have {details.ptos} leave{details.ptos > 1 ? "s" : ""} */}
              Time Off
            </p>
            <div className="overflow-auto max-h-44">
              <table className="w-full">
                <thead>
                  <tr className="text-left bg-primarylightgrey">
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Type</th>
                    <th className="px-4 py-3">Start Date</th>
                    <th className="px-4 py-3">End Date</th>
                    <th className="px-4 py-3"></th>
                  </tr>
                </thead>
                <tbody>
                  {leaves.map((pto, index) => (
                    <tr
                      className="text-left text-gray-900 border-bottom"
                      key={index}
                    >
                      <td className="px-4 py-3">{pto.name}</td>
                      <td className="px-4 py-3">{LEAVE_TYPE[pto.type]}</td>
                      <td className="px-4 py-3">
                        {moment(pto.startDate).format("MM/DD/YYYY")}
                      </td>
                      <td className="px-4 py-3">
                        {moment(pto.endDate).format("MM/DD/YYYY")}
                      </td>
                      <td className="flex flex-row items-center gap-2 px-4 py-3">
                        <button
                          // className="p-2 text-white bg-gray-200 rounded-md"
                          className="p-2 text-white bg-gray-200 rounded-md flex"
                          onClick={() => {
                            handleEditClickPTO(pto);
                          }}
                        >
                          <IconComponent
                            tooltip={"Edit provider PTO"}
                            icon="Edit_Icon"
                            i={pto.id}
                            key={pto.id}
                          />
                        </button>
                        <button
                          // className="p-2 text-white bg-gray-200 rounded-md"
                          className="p-2 text-white bg-gray-200 rounded-md flex"
                          onClick={() => {
                            deleteProviderLeave(pto.id);
                          }}
                        >
                          <IconComponent
                            tooltip={"Delete provider PTO"}
                            icon="Delete_Icon"
                            i={pto.id}
                            key={pto.id}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="w-full border border-primarygrayborder" />
        </div>
      )}
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-3">
          <button
            // className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
            className="w-28  font-semibold p-2 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
            onClick={() => {
              handleEditClick(null);
            }}
          >
            Cancel
          </button>
          <button
            // className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue"
            className="p-2 font-semibold text-white border-[2px] rounded-md w-28  bg-primaryblue whitespace-nowrap border-primaryblue"
            onClick={upsertProviderConstraint}
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ProviderSettingsModal;
