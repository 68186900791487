import { Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import ReferencedPatientCard from "../../components/Cards/ReferencedPatientCard";
import MessageCard from "../../components/Cards/MessageCard";
import { useFetchTaggedCaretagsMessagesMutation } from "../../features/chat/chatApiSlice";
import Loading from "../../components/Loading";

const FilterByCaretags = ({ onTagClick, tagged, chat }) => {
  const [specific, setSpecific] = useState(null);
  const [detailed, setDetailed] = useState(null);

  const onSpecificBack = () => {
    setSpecific(null);
    setDetailed(null);
  };

  const [fetchTaggedCaretags, { isLoading }] =
    useFetchTaggedCaretagsMessagesMutation();

  useEffect(() => {
    if (detailed !== null) {
      fetchTaggedCaretags({
        id: chat.id,
        careTagId: detailed.id,
      }).then(({ data }) => {
        setSpecific(data.data);
      });
    }
  }, [detailed]);

  return (
    <Grid
      sx={{
        width: {
          xs: "300px",
          sm: "300px",
          md: "350px",
        },
        height: "100vh",
        backgroundColor: "#fff",
      }}
      className="py-3 overflow-hidden"
    >
      {isLoading && <Loading loading={isLoading} />}
      {specific ? (
        <>
          <div className={`d-flex align-items-center px-2`}>
            <div role="button" className="close-box " onClick={onSpecificBack}>
              <Tooltip title="Back">
                <LeftArrowSvg className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header d-flex items-center">
              {detailed?.name}{" "}
              <Grid className="unread mx-2">{specific?.length || 0}</Grid>
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {specific?.map((dataX, ind) => (
              <MessageCard
                key={ind}
                message={{ ...dataX, conversationId: chat.id }}
                data={chat}
                starred={true}
                tagPatients={[]}
              />
            ))}
          </Grid>
        </>
      ) : (
        <>
          <div className={`d-flex align-items-center px-3`}>
            <div role="button" className="close-box " onClick={onTagClick}>
              {/* <LeftIcon className="close-icon-1" /> */}
              <Tooltip title="Back">
                <LeftArrowSvg className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Filter By CareTags
            </Typography>
          </div>
          <Grid className="my-2 px-2 group-content">
            {(tagged || [])?.map((item, idx) => {
              const caretag = item.name;
              // const messages = item.items.map((data) => data.Message);
              const messages = [];
              return (
                <ReferencedPatientCard
                  key={idx}
                  patientName={caretag}
                  patientID={""}
                  messages={messages}
                  count={item.count}
                  onClick={() => setDetailed(item)}
                />
              );
            })}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FilterByCaretags;
