import React, { useEffect, useState } from "react";
import ProviderUserCreateModal from "./components/ProviderUserCreateModal";
import BackOfficeUserCreateModal from "./components/BackOfficeUserCreateModal";
import UsersTable from "./components/UsersTable";
import CloseSvg from "../../assets/svgs/CloseSvg";
import {
  useCreateBackOfficeUserMutation,
  useCreateProviderUserMutation,
  useDeleteUserMutation,
  useGetAllUserMutation,
  useUpdateOrganisationUserMutation,
} from "../../features/user/userApiSlice";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import classNames from "classnames";
import DeleteModal from "./components/DeleteModal";
import Search from "../../components/Search/SearchNew";
import Loading from "../../components/Loading";

const UserManagement = () => {
  const [opaque, setOpaque] = useState(false);
  const [users, setUsers] = useState([]);
  const [addBackOfficeUserModalOpen, setAddBackOfficeUserModalOpen] =
    useState(false);
  const [addProviderUserModalOpen, setAddProviderUserModalOpen] =
    useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [getAllUser, { isLoading: isGetLoading }] = useGetAllUserMutation();
  const [createProviderUser, { isLoading: isProviderLoading }] =
    useCreateProviderUserMutation();
  const [createBackOfficeUser, { isLoading: isCreateLoading }] =
    useCreateBackOfficeUserMutation();
  const [deleteUser, { isLoading: isDeleteLoading }] = useDeleteUserMutation();
  const [updateUser, { isLoading: isUpdateLoading }] =
    useUpdateOrganisationUserMutation();

  const loading =
    isDeleteLoading ||
    isUpdateLoading ||
    isCreateLoading ||
    isGetLoading ||
    isProviderLoading;

  const user = useSelector(selectUser);

  const handleDeleteClose = () => {
    setDeleteUserOpen(false);
    setSelectedUser(null);
  };

  const onDeleteOpen = (data) => {
    setSelectedUser(data);
    console.log(data, "data");
    setDeleteUserOpen(true);
  };

  const fetchUsers = async () => {
    try {
      const { data } = await getAllUser().unwrap();

      setUsers(data);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  const saveBackOfficeUser = async (
    email,
    phone,
    firstName,
    lastName,
    role
  ) => {
    if (selectedUser) {
      const userData = {
        id: selectedUser.id,
        details: {
          name: `${firstName} ${lastName}`,
          role,
          back_office_staff_id: selectedUser.details?.back_office_staff_id,
        },
        phone,
        email,
      };
      await updateUser(userData).unwrap();
      const usersList = users.map((user) => {
        return user.id === selectedUser.id
          ? {
              ...user,
              phone,
              email,
              details: { ...user.details, ...userData.details },
            }
          : user;
      });
      setUsers(usersList);
    } else {
      const userData = {
        userData: {
          type: 1,
          email,
          phone,
        },
        staffData: {
          organisation_id: user.organisation.id,
          firstname: firstName,
          lastname: lastName,
          role,
        },
      };
      const { data } = await createBackOfficeUser(userData).unwrap();

      const usersList = [data, ...users];
      setUsers(usersList);
    }
  };

  const softDeleteUser = async () => {
    handleDeleteClose();
    await deleteUser({
      userId: selectedUser.id,
    }).unwrap();

    const usersList = users.map((user) => {
      return user.id === selectedUser.id
        ? {
            ...user,
            details: {
              ...user.details,
              status: "INACTIVE",
            },
          }
        : user;
    });
    setUsers(usersList);
  };

  const saveProviderUser = async (email, phone, providerId) => {
    const { data } = await createProviderUser({
      userData: {
        type: 3,
        email,
        phone,
      },
      providerId: +providerId,
    }).unwrap();

    const usersList = [data, ...users];
    setUsers(usersList);
  };

  const handleAddBackOfficeUserModal = (e, data) => {
    setAddBackOfficeUserModalOpen(!addBackOfficeUserModalOpen);
    setOpaque(!opaque);
    if (data) {
      setSelectedUser(data);
    } else {
      setSelectedUser(null);
    }
  };

  const handleProviderUserModal = () => {
    setAddProviderUserModalOpen(!addProviderUserModalOpen);
    setOpaque(!opaque);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="relative flex flex-col w-full h-[calc(100vh-110px)]">
      <div className="max-h-screen py-4 px-16 overflow-hidden">
        <div className="flex flex-col w-full gap-2 mb-4">
          <div className="flex flex-col items-center justify-between md:flex-row">
            <h1 className="m-0 text-2xl items-center font-bold w-fit whitespace-nowrap">
              Users{" "}
              <span className="ml-2 text-base font-bold text-primaryblue">
                {users.length}
              </span>
            </h1>
            <div className="flex flex-row items-center gap-8">
              <Search
                value={searchFilter}
                setValue={setSearchFilter}
                customWidth
              />
              <div
                className={classNames(
                  user?.type === 1 &&
                    (user?.details?.role === 4 || user?.details?.role === 1)
                    ? "flex flex-row gap-6"
                    : "hidden"
                )}
              >
                {/* <button
                onClick={handleProviderUserModal}
                className="flex flex-row items-center gap-2 p-2 text-white rounded-md bg-primaryblue"
              >
                <p className="m-0 text-base">Register Provider</p>
              </button> */}
                <button
                  onClick={handleAddBackOfficeUserModal}
                  className="flex flex-row items-center gap-2 p-2 text-white rounded-md bg-primaryblue"
                >
                  <p className="m-0 text-base">Add User</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <UsersTable
          users={users}
          deleteUser={onDeleteOpen}
          onEdit={handleAddBackOfficeUserModal}
          searchFilter={searchFilter}
        />
      </div>
      {addProviderUserModalOpen && (
        <ProviderUserCreateModal
          handleProviderUserModal={handleProviderUserModal}
          saveProviderUser={saveProviderUser}
        />
      )}
      {addBackOfficeUserModalOpen && (
        <BackOfficeUserCreateModal
          handleAddBackOfficeUserModal={handleAddBackOfficeUserModal}
          saveBackOfficeUser={saveBackOfficeUser}
          selectedUser={selectedUser}
        />
      )}
      {deleteUserOpen && (
        <DeleteModal
          open={deleteUserOpen}
          onClose={handleDeleteClose}
          onDelete={softDeleteUser}
          name={selectedUser?.details?.name}
        />
      )}
      {loading && <Loading loading={loading} />}
    </div>
  );
};

export default UserManagement;
