import moment from "moment";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import {
  useCancelAppointmentMutation,
  useFetchAppointmentsMutation,
  useFetchConsolidatedAppointmentsMutation,
  useFetchMapProvidersMutation,
  useFilterAppointmentOptionsMutation,
  useGetAppointmentCountMutation,
  useLockAppointmentMutation,
  useRescheduleAppointmentMutation,
  useUpdateAppointmentStatusMutation,
} from "../../features/appointment/appointmentApiSlice";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { selectToken, selectUser } from "../../features/auth/authSlice";
import Unlock from "../../assets/svgs/Unlock";
import Lock from "../../assets/svgs/Lock";
import CheckSvg from "../../assets/svgs/CheckSvg";
import LeftArrowSvg from "../../assets/svgs/LeftArrowSvg";
import Tabs from "../../components/Tabs";
import Map from "../../components/Maps/Map";
import { useMapStore } from "../../context/map.store";
import { avatarName } from "../../utilities/resuseFunctions";
import axios from "axios";
import { useLSStore } from "../../context/ls-store";
import AppointmentSelectModal from "../LongitudinalSchedules/components/AppointmentSelectModal";
import AppointmentCancelModal from "../LongitudinalSchedules/components/AppointmentCancelModal";
import Dropdown from "../../assets/svgs/Dropdown";
import Download from "../../assets/svgs/Download";
import UserSvg from "../../assets/svgs/user";
import {
  Edit_Icon,
  Search_Icon,
  Sort_Icon,
  User_Icon,
  Users_Icon,
} from "../../assets/images";
import UsersSvg from "../../assets/svgs/UsersSvg";
import CloseSvg from "../../assets/svgs/CloseSvg";
import AppointmentLockModal from "../LongitudinalSchedules/components/AppointmentLockModal";
import { DropArea } from "../LongitudinalSchedules/components/DropArea";
import { flushSync } from "react-dom";
import { Delete } from "@mui/icons-material";
import AppointmentAddModal from "../LongitudinalSchedules/components/AppointmentAddModal";
import Chat from "../Chat/Chat";
import SelectableButton from "../../components/Buttons/SelectableButton";
import CustomButton from "../../components/Buttons/CustomButton";
import PublishAppointmentModal from "../LongitudinalSchedules/components/PublishAppointmentModal";
import Loading from "../../components/Loading";
import { Menu, MenuItem, Tooltip } from "@mui/material";
import { useSaveMetadataMutation } from "../../features/organisation/organisationApiSlice";
import { useFetchZonesMutation } from "../../features/zones/zonesApiSlice";
import AppointmentStatusModal from "../LongitudinalSchedules/components/AppointmentStatusModal";
import { Icon } from "@virtuslab/tetrisly-icons";
import VisitCancelModal from "../../components/Modals/VisitCancelModal";

const CommandCenterV2 = () => {
  const [startDate, setStartDate] = React.useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [selectedDate, setSelectedDate] = React.useState(moment().toDate());
  const [count, setCount] = React.useState([]);
  const [selectedProvider, setSelectedProvider] = React.useState(null);
  const [selectedTab, setSelectedTab] = React.useState("Scheduler");
  const [isPublish, setIsPublish] = useState(false);
  const [weeks, setWeeks] = useState(null);
  const [successCard, setSuccessCard] = useState(false);

  const [appointments, setAppointments] = React.useState([]);
  const [consolidatedAppointments, setConsolidatedAppointments] =
    React.useState([]);
  const [recallSchedule, setRecallSchedule] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openLockModal, setOpenLockModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [appointmentToLock, setAppointmentToLock] = useState(null);
  const [draggingAppointment, setDraggingAppointment] = useState(null);
  const [editable, setEditable] = useState(false);

  const [addOpen, setAddOpen] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [appointmentToChangeStatus, setAppointmentToChangeStatus] =
    useState(null);

  const [filterOptions, setFilterOptions] = useState(null);

  const [showProviderList, setShowProviderList] = useState(false);
  const [searchText, setSearchText] = useState("");

  const {
    providers,
    setProviders,
    providerData,
    setProviderData,
    zone,
    setZone,
  } = useMapStore((state) => state);

  const [zones, setZones] = useState([]);

  const [selectedZone, setSelectedZone] = useState(zone);
  const [zoneAnchor, setZoneAnchor] = useState(null);
  const open = Boolean(zoneAnchor);
  const zoneOpen = Boolean(zoneAnchor);

  const handleZoneClick = (event) => {
    setZoneAnchor(event.currentTarget);
  };

  const handleZoneClose = () => {
    setZoneAnchor(null);
  };

  const handleSelect = (i) => {
    handleZoneClose();
    setZone(i);
    //  !isCommunity &&
    setSelectedZone(i);
  };

  const selectedAppointment = useLSStore((state) => state.selectedAppointment);
  const setSelectedAppointment = useLSStore(
    (state) => state.setSelectedAppointment
  );

  const user = useSelector(selectUser);
  const token = useSelector(selectToken);

  const [getAppointmentCount, { isLoading: getLoading }] =
    useGetAppointmentCountMutation();
  const [fetchMapProviders, { isLoading: mapLoading }] =
    useFetchMapProvidersMutation();
  const [fetchAppointments, { isLoading: appLoading }] =
    useFetchAppointmentsMutation();
  const [fetchConsolidatedAppointmentsAPI, { isLoading: consLoading }] =
    useFetchConsolidatedAppointmentsMutation();
  const [cancelAppointment, { isLoading: cancelLoading }] =
    useCancelAppointmentMutation();
  const [lockAppointment, { isLoading: lockLoading }] =
    useLockAppointmentMutation();
  const [rescheduleAppointments, { isLoading: reschLoading }] =
    useRescheduleAppointmentMutation();
  const [filterAppointmentOptions, { isLoading: filterLoading }] =
    useFilterAppointmentOptionsMutation();
  const [updateAppointmentStatus, { isLoading: updateStatusLoading }] =
    useUpdateAppointmentStatusMutation();
  const [getZones] = useFetchZonesMutation();

  const isLoading =
    getLoading ||
    mapLoading ||
    appLoading ||
    consLoading ||
    cancelLoading ||
    lockLoading ||
    reschLoading ||
    filterLoading ||
    updateStatusLoading;

  const fetchAppointmentCount = async () => {
    try {
      const { data } = await getAppointmentCount({
        providerId:
          user.type === 3
            ? user.details.provider_id
            : selectedProvider?.id || 0,
        organisationId: user.organisation.id,
        startDate,
        endDate,
        fromApp: user.type === 3 ? true : false,
      }).unwrap();

      setCount(data);
    } catch (error) {
      console.error(error?.data?.message || error?.message);
    }
  };

  const fetchAppointmentsFunction = async () => {
    try {
      const { data } = await fetchAppointments({
        providerId:
          user.type === 3 ? user.details.provider_id : selectedProvider.id,
        appointmentType: "all",
        locationType: "all",
        communityId: null,
        patientId: null,
        startDate: moment(selectedDate).format("YYYY-MM-DD"),
        endDate: moment(selectedDate).format("YYYY-MM-DD"),
        fromApp: user.type === 3 ? true : false,
      });

      setAppointments(data.data);

      const { data: mapData } = await fetchMapProviders({
        mapFor: "PATIENT",
        date: moment(selectedDate).format("YYYY-MM-DD"),
        providerId:
          user.type === 3 ? user.details.provider_id : selectedProvider.id,
      });
      setProviderData(mapData?.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchAppointmentsConsolidated = async () => {
    try {
      const { data } = await fetchConsolidatedAppointmentsAPI({
        organisationId: user.organisation.id,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        zoneId: selectedZone.id,
      });

      setConsolidatedAppointments(data.data);
      const { data: mapData } = await fetchMapProviders({
        mapFor: "PROVIDER",
        date: moment(selectedDate).format("YYYY-MM-DD"),
        providerId: "0",
        zoneId: selectedZone.id,
      });
      setProviders(mapData?.data || []);
    } catch (err) {
      console.error(err);
    }
  };

  const downloadCSV = async () => {
    const providerId =
      user.type === 3 ? user.details.provider_id : selectedProvider.id;
    const name = user.type === 3 ? user.details.name : selectedProvider.name;
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/appointment/csv?providerId=${providerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${name} (${providerId}).csv`);
    document.body.appendChild(link);
    link.click();
  };

  const onMonthChange = (val) => {
    setStartDate(moment(val.activeStartDate).format("YYYY-MM-DD"));
    setEndDate(moment(val.activeStartDate).endOf("month").format("YYYY-MM-DD"));
  };

  const onDateChange = (val) => {
    setSelectedDate(val);
  };

  const getDatesInMonth = () => {
    const startDate = moment().startOf("week").add(1, "day");
    const endDate = moment(startDate).add(60, "day");

    let currentWeek = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      currentWeek.push({
        date: currentDate.format("YYYY-MM-DD"),
        day: currentDate.format("ddd").toUpperCase(),
      });

      currentDate = currentDate.clone().add(1, "day");
    }
    return currentWeek;
  };

  useEffect(() => {
    const newWeeks = getDatesInMonth(1, 2024);
    newWeeks.pop();
    setWeeks(newWeeks);
  }, []);

  useEffect(() => {
    if (
      user.organisation?.coOrdinates?.latitude !== undefined &&
      user.organisation?.coOrdinates?.latitude !== null &&
      !selectedProvider
    ) {
      setZone({
        ...zone,
        coords: {
          lat: user.organisation?.coOrdinates?.latitude,
          lng: user.organisation?.coOrdinates?.longitude,
          zoom: 9,
        },
      });
    }
  }, [selectedProvider]);

  const onPublishClick = () => setIsPublish(true);

  useEffect(() => {
    setSelectedProvider(null);
    if (user.type === 1) fetchAppointmentsConsolidated();
    if (user.type === 3) fetchAppointmentsFunction();
  }, [selectedZone]);

  useEffect(() => {
    if (user.type === 1 || !selectedProvider) fetchAppointmentsConsolidated();
    if (user.type === 3 || selectedProvider) fetchAppointmentsFunction();
  }, [selectedDate]);

  useEffect(() => {
    if (selectedProvider) fetchAppointmentsFunction();
  }, [selectedProvider]);

  useEffect(() => {
    fetchAppointmentCount();
  }, [startDate, selectedProvider]);

  const handleCancel = async ({ reason, description }) => {
    try {
      await cancelAppointment({
        appointmentId: appointmentToCancel.appointmentId,
        reason,
        description,
      }).unwrap();

      console.log(`Appointment ${appointmentToCancel.appointmentId} cancelled`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLock = async () => {
    try {
      await lockAppointment({
        appointmentId: appointmentToLock.appointmentId,
      }).unwrap();

      console.log(`Appointment ${appointmentToLock.appointmentId} locked`);

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeStatus = async () => {
    try {
      const status =
        appointmentToChangeStatus.visitStatus === "SCHEDULED"
          ? "CONFIRMED"
          : "SCHEDULED";
      // : "COMPLETED";
      await updateAppointmentStatus({
        appointmentId: appointmentToChangeStatus.appointmentId,
        status,
      }).unwrap();

      console.log(
        `Appointment ${appointmentToChangeStatus.appointmentId} status changed to ${status}`
      );

      setRecallSchedule(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (recallSchedule) {
      // setAppointments([]);
      fetchAppointmentsFunction();
      setRecallSchedule(false);
    }
  }, [recallSchedule]);

  const handleCancelModal = () => {
    if (openCancelModal) setAppointmentToCancel(null);
    setOpenCancelModal(!openCancelModal);
  };

  const handleLockModal = () => {
    if (openLockModal) setAppointmentToLock(null);
    setOpenLockModal(!openLockModal);
  };

  const handleStatusModal = (status) => {
    if (openLockModal) setAppointmentToChangeStatus(null);
    setOpenStatusModal(!openStatusModal);
  };

  const onDrop = async (index) => {
    if (draggingAppointment === null) return;

    const { data } = await rescheduleAppointments({
      dayToBeMoved: draggingAppointment.visitDate,
      indexToBeMoved: index,
      appointmentId: draggingAppointment.appointmentId,
    }).unwrap();

    const { appointmentsTo } = data;

    const newAppointments = {
      ...appointments,
      [draggingAppointment.visitDate]: appointmentsTo,
    };

    if (document.startViewTransition) {
      document.startViewTransition(() => {
        flushSync(() => {
          setAppointments(newAppointments);
        });
      });
    } else {
      setAppointments(newAppointments);
    }
  };

  const handleProviderList = () => {
    setShowProviderList(!showProviderList);
  };

  const fetchFilterOptions = async () => {
    try {
      if (selectedProvider !== null) {
        const { data } = await filterAppointmentOptions({
          providerId: selectedProvider.id,
        });

        setFilterOptions(data.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchZones = async () => {
    try {
      const { data } = await getZones().unwrap();
      setZones(
        data?.map((zone) => {
          return {
            ...zone,
            zipCodes: zone.zip_codes,
          };
        })
      );
    } catch (error) {
      console.error(error.data.message);
    }
  };

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    if (selectedProvider) fetchFilterOptions();
  }, [selectedProvider]);

  const isProvider = user.type === 3 || !!selectedProvider?.id;

  const Mileage = isProvider
    ? appointments[moment(selectedDate).format("YYYY-MM-DD")]?.reduce(
        (total, current, idx) => {
          if (
            idx === 0 ||
            idx ===
              appointments[moment(selectedDate).format("YYYY-MM-DD")].length - 1
          ) {
            return (
              total +
              Number(current.distanceFromHome) +
              Number(current.distance)
            );
          }
          return total + Number(current.distance);
        },
        0
      )
    : 0;

  const providerFilter = (appointment) => {
    if (searchText === "") return true;
    return appointment.provider.name
      .toLowerCase()
      .includes(searchText.toLowerCase());
  };

  const appointmentFilter = (appointment) => {
    if (searchText === "") return true;
    return (
      appointment.patient.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      (appointment.location?.name || "")
        ?.toLowerCase()
        ?.includes(searchText.toLowerCase())
    );
  };

  const [dragPosition, setDragPosition] = React.useState({ x: 0, y: 0 });

  const containerRef = React.useRef(null);

  const handleDrag = (event) => {
    const { clientX, clientY } = event;
    const container = containerRef.current;

    if (container) {
      const { top, left, bottom, right } = container.getBoundingClientRect();
      const scrollSpeed = 10;

      // Check if the drag is near the container's edges and scroll accordingly
      if (clientX > right - 50)
        container.scrollBy({ left: scrollSpeed, behavior: "smooth" });
      if (clientX < left + 50)
        container.scrollBy({ left: -scrollSpeed, behavior: "smooth" });
      if (clientY > bottom - 50)
        container.scrollBy({ top: scrollSpeed, behavior: "smooth" });
      if (clientY < top + 50)
        container.scrollBy({ top: -scrollSpeed, behavior: "smooth" });

      // Update drag position for custom logic if needed
      setDragPosition({ x: clientX, y: clientY });
    }
  };
  const filteredAppointments =
    selectedProvider || user.type === 3
      ? appointments[moment(selectedDate).format("YYYY-MM-DD")]?.filter(
          appointmentFilter
        )
      : [];

  return (
    <div className="relative flex flex-col md:flex-row h-[calc(100vh-70px)] overflow-auto divide-x-[1px]">
      <Loading loading={isLoading} />
      <div
        className={classNames(
          "flex flex-col w-full gap-3 p-6 pb-3 overflow-auto md:w-1/2",
          selectedAppointment || addOpen ? "opacity-40" : "opacity-100"
        )}
      >
        <div>
          <h2 className="m-0 text-4xl font-semibold">
            Command Center |{" "}
            {user.type === 1 && (
              <>
                <button
                  id="zone-button"
                  aria-controls={open ? "zone-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleZoneClick}
                  className="btn text-capitalize"
                >
                  <span className="text-4xl font-semibold zone text-primaryblue">
                    {selectedZone?.name}
                  </span>
                </button>
                <Menu
                  id="basic-menu"
                  anchorEl={zoneAnchor}
                  open={zoneOpen}
                  onClose={handleZoneClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      handleSelect({
                        id: 0,
                        name: "All Zones",
                        coords: {
                          lat: user.organisation?.coOrdinates?.latitude,
                          lng: user.organisation?.coOrdinates?.longitude,
                          zoom: 9,
                        },
                      })
                    }
                  >
                    All Zones
                  </MenuItem>
                  {zones?.map((zone, i) => {
                    return (
                      <MenuItem key={i} onClick={() => handleSelect(zone)}>
                        {zone?.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            )}
          </h2>
          <span className="px-1 command-time">
            {moment().format("MMMM D, YYYY, hh:mm A z")}
          </span>
        </div>

        <Tabs
          tabs={["Map", "Calendar"]}
          components={[
            <Map
              isProvider={user.type === 3 || !!selectedProvider?.id}
              data={
                user.type === 3 || !!selectedProvider?.id
                  ? providerData
                  : providers
              }
              selectedProvider={
                user.type === 3 || !!selectedProvider?.id
                  ? selectedProvider
                  : undefined
              }
            />,
            <Calendar
              className={"h-[500px]"}
              onClickMonth={onMonthChange}
              onActiveStartDateChange={onMonthChange}
              value={selectedDate}
              onChange={onDateChange}
              tileContent={({ date, view }) => {
                return (
                  <>
                    {count.find(
                      (x) => x.date === moment(date).format("YYYY-MM-DD")
                    ) === undefined ? (
                      <></>
                    ) : (
                      <div
                        className={classNames(
                          "flex items-center justify-center w-5 h-5 mx-auto mt-2 text-[10px] rounded-full",
                          moment(selectedDate).format("YYYY-MM-DD") !==
                            moment(date).format("YYYY-MM-DD")
                            ? "bg-primaryblue text-white"
                            : "bg-white text-primaryblue"
                        )}
                      >
                        {
                          count.find(
                            (x) => x.date === moment(date).format("YYYY-MM-DD")
                          )?.noOfAppointments
                        }
                      </div>
                    )}
                  </>
                );
              }}
            />,
          ]}
        />
      </div>
      <div
        className={classNames(
          "flex flex-col w-full gap-3 p-6 pb-3 overflow-auto md:w-1/2",
          selectedAppointment || addOpen ? "opacity-40" : "opacity-100"
        )}
      >
        <div className="flex flex-col gap-3 md:flex-row">
          <SelectableButton
            onClick={() => setSelectedTab("Scheduler")}
            title={"Scheduler"}
            isSelected={selectedTab === "Scheduler"}
            className={"py-2 "}
          />
          <SelectableButton
            onClick={() => setSelectedTab("Messenger")}
            title={"Messenger"}
            isSelected={selectedTab === "Messenger"}
            className={"py-2 "}
          />
        </div>
        {selectedTab === "Scheduler" && (
          <>
            <div className="flex flex-col gap-3 md:flex-row">
              <button
                disabled={user.type === 3}
                onClick={handleProviderList}
                className="flex relative items-center justify-center w-full h-10 gap-2 py-2 text-white rounded-md border-[1px] border-primaryblue bg-primaryblue"
              >
                {user.type === 3 || selectedProvider ? (
                  <UserSvg color={"#FFF"} />
                ) : (
                  <UsersSvg color={"#FFF"} />
                )}
                {user.type === 3 ? (
                  <p className="m-0 text-xl">{user.details.name}</p>
                ) : (
                  <p className="m-0 text-xl">
                    {selectedProvider ? selectedProvider.name : "All Providers"}
                  </p>
                )}
                {user.type !== 3 && (
                  <div className={classNames("")}>
                    <Dropdown color={"#FFF"} />
                  </div>
                )}
                <div
                  className={classNames(
                    "absolute w-full max-h-40 bg-white border-[1px] rounded-md overflow-y-auto flex-col text-black z-[999] top-[120%]",
                    !showProviderList ? "hidden" : "flex"
                  )}
                >
                  <button
                    onClick={() => {
                      setSelectedProvider(null);
                      handleProviderList();
                    }}
                    className={classNames(
                      "flex flex-row items-center hover:bg-primarylightgrey gap-2 p-2",
                      "border-b-[1px]"
                    )}
                  >
                    All Providers
                  </button>
                  {consolidatedAppointments.map((appointments, index) => (
                    <button
                      onClick={() => {
                        setSelectedProvider(appointments.provider);
                        handleProviderList();
                      }}
                      className={classNames(
                        "flex flex-row items-center hover:bg-primarylightgrey gap-2 p-2",
                        index === consolidatedAppointments.length - 1
                          ? "border-none"
                          : "border-b-[1px]"
                      )}
                    >
                      <div className="flex items-center justify-center w-8 h-8 rounded-full bg-primarygrayborder">
                        <p className="m-0 font-semibold text-white">
                          {avatarName(appointments.provider.name)}
                        </p>
                      </div>
                      <p className="m-0">{appointments.provider.name}</p>
                    </button>
                  ))}
                </div>
              </button>
              <Tooltip title="Download schedules">
                <button
                  className="flex items-center justify-center w-10 h-10 bg-white border-[1px] rounded-md border-primaryblue disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                  onClick={downloadCSV}
                  disabled={
                    (user.type !== 3 && !selectedProvider) ||
                    selectedAppointment
                  }
                >
                  <Download color={"#3062D4"} />
                </button>
              </Tooltip>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row gap-4">
                    {selectedProvider !== null && (
                      <Tooltip title="Back to providers">
                        <button
                          disabled={
                            selectedProvider === null || user.type !== 1
                          }
                          onClick={() => {
                            setSelectedProvider(null);
                          }}
                          className={classNames(
                            "w-10 h-10 rounded-md flex items-center justify-center bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                          )}
                        >
                          <div className="rotate-90">
                            <Dropdown color={"#000"} />
                          </div>
                        </button>
                      </Tooltip>
                    )}
                    <p className="m-0 text-3xl">
                      {moment(selectedDate).format("YYYY-MM-DD") ===
                      moment().format("YYYY-MM-DD")
                        ? "Today"
                        : moment(selectedDate).format("MMMM DD, YYYY")}
                    </p>
                  </div>
                  <div className="flex flex-row gap-4">
                    {selectedProvider !== null && (
                      <Tooltip title="Add appointment">
                        <button
                          disabled={selectedProvider === null}
                          onClick={() => {
                            setAddOpen(true);
                          }}
                          className={classNames(
                            "w-10 h-10 rounded-md flex items-center justify-center bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-200 ease-in-out"
                          )}
                        >
                          <div className="rotate-45">
                            <CloseSvg color={"#000"} />
                          </div>
                        </button>
                      </Tooltip>
                    )}
                    {selectedProvider !== null && (
                      <button
                        disabled={selectedProvider === null}
                        onClick={() => {
                          setEditable(!editable);
                        }}
                        className="flex items-center justify-center w-10 h-10 transition-all duration-200 ease-in-out rounded-md bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {!editable ? (
                          <Tooltip title="Edit appointment sequence">
                            <img
                              src={Edit_Icon}
                              alt="edit"
                              className="object-contain w-5 h-5"
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Save">
                            <CheckSvg color={"#0d6efd"} />
                          </Tooltip>
                        )}
                      </button>
                    )}
                  </div>
                </div>

                <div className="flex flex-row gap-4 justify-stretch">
                  <div className="relative flex flex-row w-full items-center gap-2 divide-x-2 rounded-md border-[2px] border-primarylightgrey overflow-clip">
                    <div className="pl-2">
                      <Tooltip title="Search">
                        <img
                          src={Search_Icon}
                          alt="search"
                          className="w-5 h-5 cursor-pointer search-icon"
                        />
                      </Tooltip>
                    </div>
                    <input
                      type="text"
                      placeholder={"Search..."}
                      className="w-full h-full p-2 text-base outline-none"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <Tooltip title="Sort">
                    <button
                      disabled={selectedProvider !== null}
                      className="flex items-center self-end justify-center h-10 transition-all duration-200 ease-in-out rounded-md min-w-10 bg-primarylightgrey disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <img
                        src={Sort_Icon}
                        alt="search"
                        className="object-contain w-5 h-5"
                      />
                    </button>
                  </Tooltip>
                </div>
              </div>
              {isProvider && (
                <div className="flex justify-end">
                  <span className="text-xl bold-700 color-o">
                    {" "}
                    Total mileage: {Mileage?.toFixed(2)} Miles
                  </span>
                </div>
              )}
              {!selectedProvider && user.type === 1 ? (
                <div className="flex flex-col items-center gap-3 p-2 h-[calc(100vh-310px)] overflow-auto">
                  {consolidatedAppointments
                    ?.filter(providerFilter)
                    .map((appointments) => (
                      <div className="flex flex-col w-full gap-4 p-3 rounded-md shadow-custom border-[1px] border-gray-100">
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-3">
                            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-primarygrayborder">
                              <p className="m-0 text-lg font-semibold text-white">
                                {avatarName(appointments.provider.name)}
                              </p>
                            </div>
                            <div>
                              <p className="m-0 text-lg font-semibold">
                                {appointments.provider.name}
                              </p>
                              <p className="m-0 text-sm">
                                {appointments.provider.speciality}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col items-start">
                            <p className="m-0 text-sm font-semibold">
                              Distance Travelled
                            </p>
                            <p className="m-0 text-sm">
                              {appointments.totalDistance} miles
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between">
                          <div className="flex flex-row items-center gap-2">
                            <p className="m-0 text-xs">
                              {appointments.noOfAppointments} visits
                            </p>
                            <div className="h-6 bg-primarygrayborder w-[1px]" />
                            <p className="m-0 text-sm text-primaryblue">
                              {appointments.noOfCompletedAppointments} visits
                              completed
                            </p>
                          </div>
                          <button
                            onClick={() => {
                              const provider = providers.find((provider) =>
                                [
                                  ...provider.providers.map((p) => p.id),
                                ].includes(appointments.provider.id)
                              );
                              setSelectedProvider({
                                ...appointments.provider,
                                ...provider,
                              });
                              setZone({
                                ...zone,
                                coords: {
                                  ...zone.coords,
                                  ...(provider?.coords || {}),
                                  zoom: 10,
                                },
                              });
                            }}
                            className="text-xs m-0 py-1 px-2 border-[1px] bg-primaryblue text-white font-semibold rounded-lg"
                          >
                            View schedules
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              ) : (
                <div
                  ref={containerRef}
                  className={classNames(
                    "flex flex-col gap-2 overflow-auto overflow-x-hidden px-2",
                    user?.organisation?.data?.isEnterprise
                      ? "h-[calc(100vh-410px)]"
                      : "h-[calc(100vh-350px)]"
                  )}
                >
                  <DropArea onDrop={() => onDrop(0)} />
                  {filteredAppointments?.map((appointment, index) => (
                    <>
                      <button
                        draggable={editable}
                        onDrag={handleDrag}
                        onDragStart={() => setDraggingAppointment(appointment)}
                        onDragEnd={() => setDraggingAppointment(null)}
                        className={classNames(
                          "flex flex-col  gap-4 p-3 bg-white rounded-md shadow-custom border border-primarygrayborder",
                          editable && "cursor-pointer"
                        )}
                        onClick={() => {
                          if (selectedAppointment || addOpen) return;
                          setSelectedAppointment(appointment);
                        }}
                      >
                        <div className="flex flex-row justify-between w-100">
                          <div className="flex flex-row items-center gap-4">
                            <div
                              className={classNames(
                                "flex items-center justify-center rounded-full text-white text-sm font-semibold w-10 h-10",
                                appointment.status === "UNLOCKED"
                                  ? "bg-[#F2C94C]"
                                  : appointment.status === "LOCKED"
                                  ? "bg-[#3062D4]"
                                  : "bg-[#6FCF97]"
                              )}
                            >
                              {index + 1}
                            </div>
                            <div className="flex flex-col items-start gap-0">
                              <span className="flex flex-row items-center gap-2 text-2xl font-bold whitespace-nowrap text-primaryblack">
                                <span className="m-0">
                                  {appointment.patient.name}
                                </span>
                                <span>|</span>
                                <span className="m-0">
                                  {appointment.location?.name !== null
                                    ? appointment.location?.name
                                    : "Private Residence"}
                                </span>
                              </span>
                              <span className="m-0 text-base font-normal text-primarytextgrey">
                                {index === 0
                                  ? `${appointment.distanceFromHome} miles from home`
                                  : `${appointment.distance} miles from previous appointment`}
                                {index === filteredAppointments.length - 1
                                  ? ` and ${appointment.distanceFromHome} miles from home`
                                  : ``}
                                {appointment?.appointmentType?.duration &&
                                  ` | ${appointment?.appointmentType?.duration}-min visit`}
                              </span>
                            </div>
                          </div>
                          {appointment.status === "PUBLISHED" && (
                            <Tooltip title="Published to Athena">
                              <div className="flex items-center justify-center w-10 h-10 rounded-lg bg-gray-50">
                                <CheckSvg color={"#6FCF97"} />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                        <div className="flex w-full justify-evenly">
                          <button
                            disabled={selectedAppointment || addOpen}
                            className="text-sm m-0 py-1 px-2 border-[1px] border-primarylightgrey bg-primarylightgrey font-semibold rounded-lg"
                          >
                            {appointment.appointmentType.name}
                          </button>
                          {/* <button
                              disabled={selectedAppointment || addOpen}
                              onClick={() => {
                                setSelectedAppointment(appointment);
                              }}
                              className="text-sm m-0 py-1 px-2 border-[1px] font-medium border-primarygrayborder rounded-lg"
                            >
                              Appointment details
                            </button> */}
                          <button
                            disabled={
                              selectedAppointment ||
                              ["COMPLETED", "CANCELLED"].includes(
                                appointment.visitStatus
                              ) ||
                              addOpen
                            }
                            className={classNames(
                              "text-sm m-0 py-1 px-2 bg-white font-semibold rounded-lg flex items-center gap-1",
                              appointment.visitStatus === "COMPLETED"
                                ? "text-primarygreen border-[1px] border-primarygreen"
                                : appointment.visitStatus === "SCHEDULED"
                                ? "text-primaryyellow border-[1px] border-primaryyellow"
                                : appointment.visitStatus === "CANCELLED"
                                ? "text-primaryred border-[1px] border-primaryred"
                                : "text-primaryblue border-[1px] border-primaryblue"
                            )}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents event bubbling
                              setAppointmentToChangeStatus(appointment);
                              handleStatusModal(appointment.visitStatus);
                            }}
                          >
                            {appointment.visitStatus === "SCHEDULED" ? (
                              // <Unlock color={"#F2C94C"} />
                              <Icon name="20-hourglass" color={"#F2C94C"} />
                            ) : appointment.visitStatus === "CONFIRMED" ? (
                              <Icon
                                name="20-calendar-check"
                                color={"#3062D4"}
                              />
                            ) : appointment.visitStatus === "CANCELLED" ? (
                              <Icon
                                name="20-calendar-close"
                                color={"#eb5757"}
                              />
                            ) : (
                              <CheckSvg color={"#27AE60"} />
                            )}
                            {appointment.visitStatus === "SCHEDULED"
                              ? "Pending"
                              : appointment.visitStatus === "CONFIRMED"
                              ? "Confirmed"
                              : appointment.visitStatus === "CANCELLED"
                              ? "Cancelled"
                              : "Completed"}
                          </button>
                          <button
                            disabled={selectedAppointment || addOpen}
                            className={classNames(
                              "text-sm m-0 py-1 px-2 bg-white font-semibold rounded-lg flex items-center gap-1",
                              appointment.status === "PUBLISHED"
                                ? "text-primarygreen border-[1px] border-primarygreen"
                                : appointment.status === "UNLOCKED"
                                ? "text-primaryyellow border-[1px] border-primaryyellow"
                                : "text-primaryblue border-[1px] border-primaryblue"
                            )}
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents event bubbling
                              setAppointmentToLock(appointment);
                              handleLockModal();
                            }}
                          >
                            {appointment.status === "UNLOCKED" ? (
                              <Unlock color={"#F2C94C"} />
                            ) : appointment.status === "LOCKED" ? (
                              <Lock color={"#3062D4"} />
                            ) : (
                              <CheckSvg color={"#27AE60"} />
                            )}
                            {appointment.status === "PUBLISHED"
                              ? "Published"
                              : appointment.status === "UNLOCKED"
                              ? "Unlocked"
                              : "Locked"}
                          </button>
                          {!["COMPLETED", "CANCELLED"].includes(
                            appointment.visitStatus
                          ) && (
                            <button
                              disabled={selectedAppointment || addOpen}
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents event bubbling
                                setAppointmentToCancel(appointment);
                                handleCancelModal();
                              }}
                              className="text-sm flex font-semibold flex-row items-center gap-1 m-0 py-1 px-2 border-[1px] border-primaryred text-primaryred rounded-lg bg-subtlered"
                            >
                              <Delete
                                style={{
                                  width: "20px",
                                  height: "20px",
                                }}
                              />
                              Cancel
                            </button>
                          )}
                        </div>
                        <div className="w-full">
                          <div className="w-full h-[1px] bg-gray-200" />
                          <p className="m-0 mt-2 text-start">
                            <b>Appointment note:</b>{" "}
                            {appointment?.notes?.length > 0
                              ? appointment.notes
                                  ?.map(({ description }) => description)
                                  ?.join(", ")
                              : "NA"}
                          </p>
                          <p className="m-0 text-start">
                            <b>Patient note:</b>{" "}
                            {appointment?.patient?.notes ?? "NA"}
                          </p>
                        </div>
                      </button>
                      <DropArea onDrop={() => onDrop(index + 1)} />
                    </>
                  ))}
                </div>
              )}
              {selectedProvider &&
                appointments[moment(selectedDate).format("YYYY-MM-DD")]
                  ?.length > 0 &&
                user?.organisation?.data?.isEnterprise && (
                  <div className="p-2 d-flex justify-content-center align-items-center">
                    <CustomButton
                      className="font-semibold publish-btn"
                      disabled={false}
                      // disabled={!isPublishEnabled}
                      onClick={onPublishClick}
                    >
                      Publish to Athena
                    </CustomButton>
                  </div>
                )}
            </div>
          </>
        )}
        {selectedTab === "Messenger" && (
          <div>
            <Chat isFullScreen={false} />
          </div>
        )}
      </div>
      {selectedAppointment && (
        <div className="z-50 overflow-auto opacity-100 min-h-svh">
          <AppointmentSelectModal
            selectedProvider={selectedProvider}
            setRecallSchedule={setRecallSchedule}
          />
        </div>
      )}
      {filterOptions && selectedProvider && addOpen && (
        <div className="z-50 opacity-100">
          <AppointmentAddModal
            selectedProvider={selectedProvider}
            addOpen={addOpen}
            setAddOpen={setAddOpen}
            weeks={null}
            onAdd={() => {}}
            filterOptions={filterOptions}
            setRecallSchedule={setRecallSchedule}
            selectedDate={selectedDate}
          />
        </div>
      )}
      {/* <AppointmentCancelModal
        open={openCancelModal}
        handleCancelModal={handleCancelModal}
        handleCancel={handleCancel}
      /> */}
      {openCancelModal && (
        <VisitCancelModal
          open={openCancelModal}
          handleClose={handleCancelModal}
          handleCancel={handleCancel}
          selectedVisit={appointmentToCancel}
        />
      )}
      <AppointmentLockModal
        open={openLockModal}
        handleLockModal={handleLockModal}
        handleLock={handleLock}
        status={appointmentToLock?.status}
      />
      <AppointmentStatusModal
        open={openStatusModal}
        handleCancel={handleStatusModal}
        handleSubmit={handleChangeStatus}
        status={appointmentToChangeStatus?.visitStatus}
      />
      {isPublish && weeks !== null && (
        <PublishAppointmentModal
          publishOpen={isPublish}
          setPublishOpen={setIsPublish}
          weeks={weeks}
          setSuccessCard={setSuccessCard}
          selectedProvider={selectedProvider}
          user={user}
          setRecallSchedule={setRecallSchedule}
        />
      )}
    </div>
  );
};

export default CommandCenterV2;
