import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import Signup from "./screens/Authentication/Signup";
import Signin from "./screens/Authentication/Signin";
import CareplanUpload from "./screens/Schedules/CareplanUpload";
import ProvidersConfig from "./screens/Schedules/Configure/ProvidersConfig";
import VisitRules from "./screens/Schedules/Configure/VisitRules";
import AppointmentTypes from "./screens/Schedules/Configure/AppointmentTypes";
import CommandCenter from "./screens/CommandCenter";
import CommandCenterV2 from "./screens/CommandCenterV2";
import LongitudinalSchedules from "./screens/LongitudinalSchedules";
import RequireAuth from "./RequireAuth";
import Account from "./screens/Account";
import UserManagement from "./screens/UserManagement";
import RunSchedulerMain from "./screens/Schedules/RunScheduler/RunScheduler";
import Welcome from "./screens/Welcome";
import PatientVisitReport from "./screens/Reports/PatientVisitReport";
import ProviderActivityReport from "./screens/Reports/ProviderActivityReport";
import RevenueReport from "./screens/Reports/RevenueReport";
import ApptCancellationReport from "./screens/Reports/AppointCancellationReport";
import GoogleCallback from "./screens/Authentication/GoogleCallback";
import Billing from "./screens/Billing";
import PricingPlans from "./screens/Authentication/common/PricingPlans";
import Pivot from "./screens/Analytics/Pivot";
import ReportBuilder from "./screens/Analytics/ReportBuilder";
import ProviderList from "./screens/CareManagement/Provider/ProviderList";
import PatientList from "./screens/CareManagement/Patient/PatientList";
import CommunityList from "./screens/CareManagement/Community/CommunityList";
import ProviderInfo from "./screens/CareManagement/Provider/ProviderInfo";
import PatientInfo from "./screens/CareManagement/Patient/PatientInfo";
import CommunityInfo from "./screens/CareManagement/Community/CommunityInfo";
import OverduePatientsReport from "./screens/Reports/OverduePatientReport";
import NewPatientsReport from "./screens/Reports/NewPatientReport";
import CaretagList from "./screens/CareManagement/Caretags/CaretagList";
import Chat from "./screens/Chat/Chat";
import PatientSchedulingWizard from "./screens/PatientSchedulingWizard";
import ZoneList from "./screens/CareManagement/Zones/ZonesList";
import Dashboard from "./screens/Analytics/Dashboard";

import Forgot from "./screens/Authentication/Forgot";
function App() {
  useEffect(() => {
    if ("Notification" in window && Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="signup" element={<Signup />} />
        <Route path="signin" element={<Signin />} />
        <Route path="forgot" element={<Forgot />} />
        <Route path="/google">
          <Route path="callback" element={<GoogleCallback />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="/schedules">
            <Route path="careplan-upload" element={<CareplanUpload />} />
            <Route path="run-scheduler" element={<RunSchedulerMain />} />
            <Route path="configure">
              <Route path="providers" element={<ProvidersConfig />} />
              <Route path="visit-rules" element={<VisitRules />} />
              <Route path="visit-types" element={<AppointmentTypes />} />
            </Route>
          </Route>
          <Route path="calendar-view" element={<LongitudinalSchedules />} />
          <Route path="user-management" element={<UserManagement />} />
          <Route path="account" element={<Account />} />
          <Route path="billing" element={<Billing />} />
          <Route path="plans" element={<PricingPlans />} />
          <Route path="/home" element={<CommandCenterV2 />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/data-management">
            <Route path="providers">
              <Route path="" element={<ProviderList />} />
              <Route path=":id" element={<ProviderInfo />} />
            </Route>
            <Route path="patients">
              <Route path="" element={<PatientList />} />
              <Route path=":id" element={<PatientInfo />} />
            </Route>
            <Route path="communities">
              <Route path="" element={<CommunityList />} />
              <Route path=":id" element={<CommunityInfo />} />
            </Route>
            <Route path="caretags" element={<CaretagList />} />
            <Route path="zones" element={<ZoneList />} />
          </Route>
          <Route path="/chat" element={<Chat isFullScreen={true} />} />
          <Route
            path="patient-assignment"
            element={<PatientSchedulingWizard />}
          />
          <Route path="/reports">
            <Route
              path="patient-visit-report"
              element={<PatientVisitReport />}
            />
            <Route
              path="provider-activity-report"
              element={<ProviderActivityReport />}
            />
            <Route path="revenue-report" element={<RevenueReport />} />
            <Route
              path="appointment-cancellation-report"
              element={<ApptCancellationReport />}
            />
            <Route
              path="overdue-patients-report"
              element={<OverduePatientsReport />}
            />
            <Route path="new-patients-report" element={<NewPatientsReport />} />
            <Route path="report-builder" element={<ReportBuilder />}></Route>
          </Route>
          <Route path="/analytics">
            <Route path="pivot" element={<Pivot />}></Route>
            <Route path="dashboard" element={<Dashboard />}></Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
