import React, { useEffect } from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import { useLSStore } from "../../../../context/ls-store";
import { Tooltip } from "@mui/material";

const CaretagModal = ({
  type = "update",
  open,
  setOpen,
  data = null,
  setData,
  caretags,
  setCaretags,
  edit,
  create,
}) => {
  const handleClose = () => {
    setName("");
    setDescription("");
    setStatus("ACTIVE");
    setOpen(false);
    setData(null);
  };

  const [name, setName] = React.useState(data !== null ? data.name : "");
  const [description, setDescription] = React.useState(
    data !== null ? data.description : ""
  );
  const [status, setStatus] = React.useState(
    data !== null ? data.status : "ACTIVE"
  );

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (open && data !== null) {
      setName(data.name);
      setDescription(data.description);
      setStatus(data.status);
    }
  }, [open, data]);

  const updateCaretag = async () => {
    try {
      await edit({
        id: data.id,
        name,
        description,
        status,
      }).unwrap();

      const updatedCaretags = caretags.map((caretag) => {
        if (caretag.id === data.id) {
          return {
            ...caretag,
            name,
            description,
            status,
          };
        }
        return caretag;
      });

      setCaretags(updatedCaretags);

      setAlert({
        message: "Updated Caretag",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to update Caretag",
        severity: "error",
      });
    }
  };

  const createCaretag = async () => {
    try {
      const { data } = await create({
        name,
        description,
      }).unwrap();

      const updatedCaretags = [
        ...caretags,
        {
          id: data.id,
          name,
          description,
          status,
        },
      ];

      setCaretags(updatedCaretags);

      setAlert({
        message: "Created Caretag",
        severity: "success",
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: "Failed to create Caretag",
        severity: "error",
      });
    }
  };

  return (
    <ReusableModal
      open={open}
      title={`${type === "update" ? "Update" : "Create"} Caretag`}
      onClose={handleClose}
    >
      <div className="flex flex-col w-full gap-4 p-6 max-w-[600px] min-w-[500px]">
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Name" className="m-0 text-base font-semibold">
            Name
          </label>
          <input
            type="text"
            id="Name"
            name="Name"
            placeholder="Enter Name"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col w-full gap-2">
          <label htmlFor="Description" className="m-0 text-base font-semibold">
            Description
          </label>
          <input
            type="text"
            id="tel"
            name="tel"
            placeholder="Enter Description"
            className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        {type === "update" && (
          <div className="flex flex-col w-full gap-2">
            <label htmlFor="Status" className="m-0 text-base font-semibold">
              Status
            </label>
            <select
              id="Status"
              name="Status"
              className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option value="ACTIVE">Active</option>
              <option value="INACTIVE">Inactive</option>
            </select>
          </div>
        )}
        <div className="flex flex-row items-center justify-between w-full gap-6">
          <button
            onClick={handleClose}
            className="w-1/2 p-2 font-semibold rounded-md  border-[2px] text-primaryblue border-primaryblue"
          >
            Cancel
          </button>
          <button
            disabled={name === "" || description === ""}
            onClick={async () => {
              handleClose();
              if (type === "update") {
                await updateCaretag();
              } else {
                await createCaretag();
              }
            }}
            className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-primaryblue border-primaryblue text-white whitespace-nowrap"
          >
            {type === "update" ? "Yes, Update" : "Create"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default CaretagModal;
