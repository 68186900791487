import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Input,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import useMessenger from "../../hooks/useMessenger";
import "./ViewGroupModal.css";
import {
  PermMediaOutlined as MediaIcon,
  StarBorderOutlined as StarIcon,
  EnhancedEncryptionOutlined as EncryptionIcon,
  ChevronRightOutlined as RightIcon,
} from "@mui/icons-material";
import { avatarName } from "../../utilities/resuseFunctions";
import UsersSvg from "../../assets/svgs/UsersSvg";
import Chat from "../../assets/svgs/Chat";
import moment, { max } from "moment";
import Loading from "../Loading";

import Delete from "../../assets/images/Delete.png";
import Add from "../../assets/images/Add_Icon.png";
import Close from "../../assets/images/Close.png";
import { Button } from "react-bootstrap";
import AddMedia from "../Cards/AddMedia";
import axios from "axios";
import MessagingApi from "../../services/messagingApi";
import { MESSAGING_BASE_URL } from "../../constants/endpoints";
import useAuth from "../../hooks/useAuth";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import StarredMessages from "../../screens/Chat/StarredMessages";
import DeleteModal from "./DeleteModal";
import ReferencedPatients from "../../screens/Chat/ReferencedPatients";
import SectionCard from "../Cards/SectionCard";
import MediaFiles from "../../screens/Chat/MediaFiles";
import { Icon } from "@virtuslab/tetrisly-icons";
import FilterByCaretags from "../../screens/Chat/FilterByCaretags";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { SocketContext } from "../../context/SocketProvider";
import { useFetchContactsMutation } from "../../features/organisation/organisationApiSlice";
import {
  BACK_OFFICE_ROLE,
  COMMUNITY_ROLE,
  USER_ROLE,
} from "../../constants/config.constants";
import {
  useAddMultipleParticipantsMutation,
  useAddParticipantMutation,
  useDeleteConversationMutation,
  useRemoveParticipantMutation,
  useUpdateConversationMutation,
  useUploadDocumentMutation,
} from "../../features/chat/chatApiSlice";
import SentToEhrMessages from "../../screens/Chat/SentToEhrMessages";

const KickWarning = ({
  deleteUser,
  handleWarning,
  warning,
  handleKickMember,
  handleLeaveGroup,
  handleRefresh,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog open={warning} onClose={handleWarning}>
        <p
          style={{
            paddingInline: "12px",
          }}
        >{`Removing ${deleteUser.deleteName}?`}</p>
        <p
          style={{
            paddingInline: "12px",
          }}
        >{`If you remove ${deleteUser.deleteName}, they will need to be added again to view this conversation.`}</p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              flex: 1,
              textAlign: "center",
              borderRight: "solid",
              borderColor: "red",
              borderWidth: "0.5px",
              cursor: "pointer",
            }}
            className="leave-text"
            onClick={() => {
              handleWarning();
            }}
          >
            Cancel
          </p>
          <p
            style={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              // handleKickMember(deleteUser.deleteID);
              // console.log(deleteUser);
              handleLeaveGroup(deleteUser.groupID, deleteUser.deleteID);
              handleRefresh();
              handleWarning();
            }}
          >
            Remove
          </p>
        </div>
      </Dialog>
    </div>
  );
};

const GroupWarning = ({
  groupWarning,
  handleGroupWarning,
  leaveUser,
  isAdmin,
  handleLeaveGroup,
  handleDeleteGroup,
  onCloseConv,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog open={groupWarning} onClose={handleGroupWarning}>
        <h5
          style={{
            paddingInline: "12px",
            paddingTop: "10px",
          }}
        >
          {isAdmin ? `Deleting Group?` : "Leaving Group?"}
        </h5>
        <p
          style={{
            paddingInline: "12px",
          }}
        >
          {isAdmin
            ? "Once you delete all the chats, images, docs will be deleted!"
            : "Once you leave all the chats, images, docs will be deleted!"}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <p
            style={{
              flex: 1,
              textAlign: "center",
              borderRight: "solid",
              borderColor: "red",
              borderWidth: "0.5px",
              cursor: "pointer",
            }}
            className="leave-text"
            onClick={() => {
              handleGroupWarning();
            }}
          >
            Cancel
          </p>
          <p
            style={{
              flex: 1,
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (isAdmin) {
                // console.log("Delete", leaveUser.groupID);
                handleDeleteGroup(leaveUser.groupID);
                onCloseConv();
              } else {
                // console.log("Leave", leaveUser.groupID, leaveUser.userID);
                handleLeaveGroup(leaveUser.groupID, leaveUser.userID);
                onCloseConv();
              }
              handleGroupWarning();
            }}
          >
            {isAdmin ? "Delete" : "Leave"}
          </p>
        </div>
      </Dialog>
    </div>
  );
};

const AddMemberDialog = ({
  memberOpen,
  onClose,
  leftMembers,
  conversationId,
  handleRefresh,
  conversationName,
}) => {
  const [fetchContacts] = useFetchContactsMutation();
  const [addParticipants] = useAddMultipleParticipantsMutation();
  const [members, setMembers] = useState([]);
  const user = useSelector(selectUser);
  const { addConversationUser } = useContext(SocketContext);

  // const { user, newGroupCreationEmit, groupUpdationEmit } = useMessenger();

  async function getMembers(id, role, communityId) {
    fetchContacts().then(({ data }) => {
      const formatedData = data?.data?.map((item) => {
        return {
          ...item,
          details:
            item.provider || item.back_office_staff || item.community_staff,
        };
      });
      const respData = formatedData
        // ?.filter((data) =>
        //   role === 3
        //     ? data.type === 3 || data.type === 1
        //     : (data.type === 2 &&
        //         data?.details?.community !== null &&
        //         data?.details?.community?.id === communityId) ||
        //       data.type === 1
        // )
        ?.map((member) => {
          return {
            id: member?.id,
            image: member.details?.img,
            firstName: member.details?.firstname,
            lastName: member.details?.lastname,
            post:
              member.type === 1
                ? BACK_OFFICE_ROLE[member.details?.role]
                : member.type === 2
                ? COMMUNITY_ROLE[member.details?.role]
                : member?.details?.speciality,
            community: member.details?.community
              ? member.details?.community.name
              : null,
            isSelected: false,
          };
        });
      setMembers(respData);
    });
  }

  useEffect(() => {
    getMembers(user.id, user.type, user.communityId, leftMembers);
  }, []);

  useEffect(() => {}, [members]);

  const selectMember = (id) => {
    setMembers(
      members?.map((x) => ({
        ...x,
        isSelected: x.id === id ? true : x.isSelected,
      })) || []
    );
  };

  const deselectMember = (id) => {
    setMembers(
      members?.map((x) => ({
        ...x,
        isSelected: x.id === id ? false : x.isSelected,
      })) || []
    );
  };

  const onAdd = async () => {
    try {
      let userIds = members.filter((data) => data.isSelected);

      const meta = {
        creatorName: user?.name,
        groupName: conversationName,
        member: {
          id: userIds?.[0]?.id,
          name: `${userIds?.[0]?.firstName} ${userIds?.[0]?.lastName}`,
        },
      };

      // userIds.forEach(async (userId) => {
      //   await addParticipant({
      //     userId,
      //     id: conversationId,
      //   });
      // });
      await addParticipants({
        id: conversationId,
        data: userIds?.map((data) => data.id),
      });

      userIds = userIds?.map((data) => `USER:${data.id}`);

      addConversationUser(conversationId, [...leftMembers, ...userIds], meta);
      handleRefresh();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Dialog open={memberOpen} onClose={onClose}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            backgroundColor: "#2F80ED",
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              textAlign: "center",
              alignSelf: "center",
            }}
          >
            Add new members
          </Typography>
          <Tooltip title="Close">
            <button
              onClick={onClose}
              className="button-unset align-self-end"
              style={{
                marginLeft: "auto",
              }}
            >
              <img
                src={Close}
                alt="Close Button"
                width={16}
                height={16}
                className="delete-button"
              />
            </button>
          </Tooltip>
        </div>
        <div
          style={{
            minWidth: "300px",
            maxWidth: "800px",
            display: "flex",
            flexDirection: "column",
            maxHeight: "600px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {members?.map((data, indx) => (
            <div
              key={indx}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "12px",
                alignItems: "center",
                cursor: "pointer",
                paddingBlock: "8px",
                paddingInline: "16px",
                backgroundColor: indx % 2 === 0 ? "rgba(0, 0, 0, 0.1)" : "#fff",
              }}
              onClick={() => {
                selectMember(data.id);
              }}
            >
              <Avatar className="avatar-user mx-3" src={data.image}>
                {avatarName(`${data.firstName} ${data.lastName}`)}
              </Avatar>
              <div>
                {data.firstName} {data.lastName}
              </div>
              {data.isSelected && (
                <div
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "5px",
                    backgroundColor: "#2F80ED",
                    marginLeft: "auto",
                  }}
                />
              )}
            </div>
          ))}
        </div>
        {members?.filter((x) => x.isSelected).length > 0 && (
          <div
            style={{
              minWidth: "300px",
              maxWidth: "800px",
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              padding: "16px",
              overflowX: "scroll",
              overflowY: "hidden",
              flexWrap: "wrap",
              marginTop: "6px",
            }}
          >
            {members
              ?.filter((x) => x.isSelected)
              ?.map((data, indx) => (
                <div
                  key={indx}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    deselectMember(data.id);
                  }}
                >
                  <Avatar className="avatar-user mx-3" src={data.image}>
                    {avatarName(`${data.firstName} ${data.lastName}`)}
                  </Avatar>
                </div>
              ))}
          </div>
        )}
        <Button
          style={{
            margin: "16px",
          }}
          disabled={members?.filter((x) => x.isSelected).length === 0}
          onClick={onAdd}
        >
          Add
        </Button>
      </Dialog>
    </div>
  );
};

const ViewGroupModal = ({
  isOpen,
  onClose,
  chat,
  data: chatData,
  handleKickMember,
  handleLeaveGroup,
  handleDeleteGroup,
  onCloseConv,
  handleRefresh,
  isCommunity,
  setData,
}) => {
  const [removeParticipant, { isLoading: removeLoading }] =
    useRemoveParticipantMutation();
  const [updateConversationApi, { isLoading: updateLoading }] =
    useUpdateConversationMutation();
  const [deleteConversationApi, { isLoading: deleteLoading }] =
    useDeleteConversationMutation();

  const [uploadDocument, { isLoading: uploadLoading }] =
    useUploadDocumentMutation();

  const user = useSelector(selectUser);
  const {
    setCurrentChat,
    getStarredMessages,
    getReferencedPatients,
    getTaggedCaretags,
    isLoading,
    removeConversationUser,
    setChats,
    updateConversation,
    deleteConversation,
    getMediaFiles,
    getAthenaMessages,
  } = useContext(SocketContext);
  const [open, setOpen] = useState(isOpen);

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(false);
  const [starred, setStarred] = useState([]);
  const [referenced, setReferenced] = useState([]);
  const [tagged, setTagged] = useState([]);
  const [media, setMedia] = useState([]);
  const [athenaMessages, setAthenaMessages] = useState([]);
  const [isStarred, setIsStarred] = useState(false);
  const [isReferenced, setIsReferenced] = useState(false);
  const [isCaretags, setIsCaretags] = useState(false);
  const [isSentEhr, setIsSentEhr] = useState(false);
  const [isMedia, setIsMedia] = useState(false);

  const handleEdit = () => {
    if (!isEditing) {
      setName(chatData?.name || "");
      setSelectedImage(chatData?.image || "");
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    setName(chatData?.name);
    setSelectedImage(chatData?.image);
    getStarredMessages({ conversationId: chatData?.id }).then((data) => {
      setStarred(data);
    });
    getReferencedPatients({ conversationId: chatData?.id }).then((data) =>
      setReferenced(data)
    );
    getTaggedCaretags({ conversationId: chatData?.id }).then((data) =>
      setTagged(data)
    );
    getMediaFiles({ conversationId: chatData?.id }).then((data) =>
      setMedia(data)
    );
    getAthenaMessages({ conversationId: chatData?.id }).then((data) =>
      setAthenaMessages(data)
    );
  }, []);

  const [warning, setWarning] = useState(false);
  const [groupWarning, setGroupWarning] = useState(false);
  const [memberWarning, setMemberWarning] = useState(false);

  const handleMemberDialog = () => {
    setMemberWarning(!memberWarning);
  };

  const [leaveUser, setLeaveUser] = useState({});
  const [deleteUser, setDeleteUser] = useState({});

  const handleWarning = (deleteID, deleteName, groupID) => {
    if (
      deleteID !== undefined &&
      deleteName !== undefined &&
      groupID !== undefined
    ) {
      setDeleteUser({
        deleteID,
        deleteName,
        groupID,
      });
      setWarning(true);
    } else {
      setDeleteUser({});
      setWarning(false);
    }
  };

  const handleGroupWarning = (groupID) => {
    if (groupID !== undefined) {
      setLeaveUser({
        groupID,
        userID: user.id,
      });
    } else {
      setLeaveUser({});
    }
    setGroupWarning(!groupWarning);
  };

  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    if (chatData) {
      chatData?.participants?.forEach((x) => {
        if (x.user_id === user.id && x.is_admin === true) {
          setAdmin(x);
        }
      });
    }
  }, [chatData]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  // useEffect(() => {
  //   if (Object.keys(leftMember).length !== 0) {
  //     console.log(leftMember);
  //     handleKickMember(leftMember.userID);
  //     if (
  //       leftMember.userID === user.id &&
  //       leftMember.groupID === chatData.conversationId
  //     ) {
  //       handleClose();
  //       onCloseConv();
  //     }
  //   }
  // }, [leftMember]);

  // const [file, setFile] = useState(null);
  // const [fileType, setFileType] = useState("");
  // const [documentOpen, setDocumentOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // const { uploadDocument } = useAuth();

  const isLoadingData =
    removeLoading ||
    updateLoading ||
    deleteLoading ||
    uploadLoading ||
    isLoading;

  const onAddMedia = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = ".jpg, .jpeg, .png";
    input.onchange = (_) => {
      let files = Array.from(input.files);
      const formData = new FormData();
      formData.append("file", files[0]);
      uploadDocument(formData).then((res) => {
        setSelectedImage(res?.data?.data?.url);
      });
    };
    input.click();
  };

  const onStarredClick = () => setIsStarred(!isStarred);

  const onEhrClick = () => setIsSentEhr(!isSentEhr);

  const onReferenceClick = () => setIsReferenced(!isReferenced);

  const onCaretagClick = () => setIsCaretags(!isCaretags);

  const onMediaClick = () => setIsMedia(!isMedia);

  const handleGroupDelete = async () => {
    try {
      if (admin?.is_admin) {
        await deleteConversationApi({
          id: leaveUser.groupID,
        });
        deleteConversation(
          leaveUser.groupID,
          chatData?.participants.map((x) => x.user_id)
        );
        setChats((chats) => {
          const updatedChat = chats.filter((x) => x.id !== leaveUser.groupID);
          return updatedChat;
        });
        onClose();
        onCloseConv();
        setCurrentChat(null);
      } else {
        await removeParticipant({
          id: leaveUser.groupID,
          userId: leaveUser.userID,
        });
        const left = chatData?.participants?.filter(
          (x) => x.user_id !== leaveUser.userID
        );
        removeConversationUser(leaveUser.groupID, left);
        onCloseConv();
      }
      handleGroupWarning();
    } catch (error) {
      console.log(error);
    }
  };

  const handleKick = async () => {
    await removeParticipant({
      id: deleteUser.groupID,
      userId: deleteUser.deleteID,
    });
    const left = chatData?.participants?.filter(
      (x) => x.user_id !== deleteUser.deleteID
    );
    removeConversationUser(deleteUser.groupID, left);
    handleRefresh();
    handleWarning();
  };

  const onEdit = async () => {
    if (isEditing) {
      const { data } = await updateConversationApi({
        id: chatData.id,
        name: name,
        image: selectedImage,
      });
      if (data?.data) {
        setCurrentChat((prev) => ({
          ...prev,
          ...data.data,
        }));
        setData((prev) => ({
          ...prev,
          ...data.data,
        }));
        setChats((chats) => {
          const index = chats.findIndex((x) => x.id === chatData.id);
          const updatedChat = [...chats];
          updatedChat[index] = {
            ...updatedChat[index],
            ...data.data,
          };
          return updatedChat;
        });
        updateConversation(
          data?.data,
          chatData.participants.map((p) => p.user_id)
        );
      }
    }
    handleEdit();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          position: "absolute",
          right: 0,
          margin: 0,
          top: 20,
          bottom: 0,
          backgroundColor: "#F2F2F2",
        },
      }}
    >
      {warning && (
        <DeleteModal
          open={warning}
          handleClose={handleWarning}
          handleDelete={handleKick}
          title={`Removing ${deleteUser.deleteName}?`}
          subtext={`If you remove ${deleteUser.deleteName}, they will need to be added again to view this conversation.`}
          primaryText={"Remove"}
        />
      )}
      {groupWarning && (
        <DeleteModal
          open={groupWarning}
          handleClose={handleGroupWarning}
          handleDelete={handleGroupDelete}
          title={admin?.is_admin ? `Deleting Group?` : "Leaving Group?"}
          subtext={
            admin?.is_admin
              ? "Once you delete all the chats, images, docs will be deleted!"
              : "Once you leave all the chats, images, docs will be deleted!"
          }
          primaryText={admin?.is_admin ? "Delete" : "Leave"}
        />
      )}
      {memberWarning && (
        <AddMemberDialog
          memberOpen={memberWarning}
          onClose={handleMemberDialog}
          leftMembers={chatData?.participants?.map((x) => x.user_id) || []}
          conversationId={chatData.id}
          conversationName={chatData.name}
          handleRefresh={handleRefresh}
        />
      )}
      {isLoadingData && <Loading loading={isLoadingData} />}
      {isMedia ? (
        <MediaFiles handleClick={onMediaClick} chat={chatData} media={media} />
      ) : isStarred ? (
        <StarredMessages
          onStarredClick={onStarredClick}
          starred={starred}
          chat={chatData}
        />
      ) : isReferenced ? (
        <ReferencedPatients
          onReferenceClick={onReferenceClick}
          referenced={referenced}
          chat={chatData}
        />
      ) : isCaretags ? (
        <FilterByCaretags
          onTagClick={onCaretagClick}
          tagged={tagged}
          chat={chatData}
        />
      ) : isSentEhr ? (
        <SentToEhrMessages
          handleClose={onEhrClick}
          messages={athenaMessages}
          chat={chatData}
        />
      ) : (
        <Grid
          sx={{
            width: {
              xs: "300px",
              sm: "300px",
              md: "350px",
            },
            //   height: "100vh",
            backgroundColor: "#F2F2F2",
          }}
          className="py-3 overflow-hidden"
        >
          <div
            className={`d-flex align-items-center px-3 ${
              admin?.is_admin ? " justify-content-sm-between" : ""
            } `}
          >
            <div role="button" className="close-box" onClick={handleClose}>
              <Tooltip title="Close">
                <CloseOutlinedIcon className="close-icon-1" />
              </Tooltip>
            </div>
            <Typography className="px-3 add-patients-modal-title group-header">
              Group Information
            </Typography>
            {admin?.is_admin && (
              <Button
                onClick={onEdit}
                className="px-3 add-patients-modal-title"
              >
                {!isEditing ? "Edit" : "Done"}
              </Button>
            )}
          </div>
          <Grid className="px-3 group-content">
            <Grid className="text-center p-3">
              <div className="d-flex justify-content-center align-items-center">
                <Avatar className="m-2 avatar-group" src={selectedImage} />
                {/* onAddMedia */}
                {admin?.is_admin && isEditing && (
                  <Tooltip title="Add Attachment">
                    <IconButton className="ml-2" onClick={onAddMedia}>
                      <AddOutlinedIcon
                        style={{
                          color: "#fff",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              {!isEditing ? (
                <Typography className="group-name">{chatData?.name}</Typography>
              ) : (
                <Input
                  value={name}
                  className="text-center"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              )}
              {/* <Typography className="group-subtext">Group</Typography> */}
            </Grid>
            <Grid>
              <SectionCard
                text={"Media, Links, Docs"}
                // count={24}
                icon={MediaIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onMediaClick}
              />
              <SectionCard
                text={"Starred Messages"}
                // count={24}
                icon={StarIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onStarredClick}
              />
              <SectionCard
                text={"Filter by Patient"}
                // count={3}
                icon={UsersSvg}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onReferenceClick}
              />
              <SectionCard
                text={"Filter by CareTag"}
                // count={3}
                icon={(props) => <Icon name="20-hashtag" {...props} />}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={onCaretagClick}
              />
              {!isCommunity && (
                <SectionCard
                  text={"Messages sent to EHR"}
                  // count={3}
                  icon={Chat}
                  className={"my-2 rounded"}
                  showDeleteButton={false}
                  handleClick={onEhrClick}
                />
              )}
              <SectionCard
                text={"Privacy Policy"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open("https://www.caresms.io/privacy-policy");
                }}
              />
              <SectionCard
                text={"HIPAA Compliance"}
                icon={EncryptionIcon}
                className={"my-2 rounded"}
                showDeleteButton={false}
                handleClick={() => {
                  window.open(
                    "https://www.caresms.io/hipaa-compliance-security"
                  );
                }}
              />
            </Grid>
            <Grid className="d-flex flex-row align-items-center justify-content-center gap-4">
              <Typography className="text-center participants-title my-3">
                {chatData?.participants?.length} Participants
              </Typography>
              {admin?.is_admin && (
                <Tooltip title="Add member">
                  <button
                    onClick={handleMemberDialog}
                    className="button-unset group-edit"
                  >
                    <img
                      src={Add}
                      alt="Add Button"
                      width={16}
                      height={16}
                      className="add-button"
                    />
                  </button>
                </Tooltip>
              )}
            </Grid>
            <Grid className="rounded">
              {!isLoadingData &&
                Array.isArray(chatData?.participants) &&
                chatData?.participants?.length > 0 &&
                chatData?.participants?.map((data, index) => (
                  <div key={index}>
                    <SectionCard
                      text={
                        data.user_id === user.id
                          ? "You"
                          : `${data.firstname} ${data.lastname}`
                      }
                      count={data.is_admin === true ? "Admin" : ""}
                      profileImg={data.img}
                      handleClick={async () => {
                        // const conv = await createPrivateConversation([data.id]);
                        // newGroupCreationEmit([`U${conv.id}`]);
                        // setCurrentChat({
                        //   conversationId: conv?.conversationId,
                        //   createdAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                        //   isAdmin: false,
                        //   isCreator: false,
                        //   updatedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
                        //   userId: user.id,
                        //   conversationName:
                        //     data.firstName + " " + data.lastName,
                        //   contactFN: data?.firstName,
                        //   contactLN: data?.lastName,
                        //   contactImg: data.profileImg,
                        //   contactId: data.id,
                        // });
                      }}
                      handleDelete={(e) => {
                        e.stopPropagation();
                        handleWarning(
                          data.user_id,
                          data.firstname,
                          chatData.id
                        );
                      }}
                      showDeleteButton={
                        admin?.is_admin && user.id !== data.user_id
                      }
                    />
                    {index !== data?.participants?.length && <Divider />}
                  </div>
                ))}
            </Grid>
            <Grid>
              <div
                className="section-card my-4 rounded"
                onClick={() => {
                  handleGroupWarning(chatData.id);
                }}
              >
                <Typography className="text-center leave-text">
                  {admin?.is_admin ? "Delete this group" : "Leave this group"}
                </Typography>
              </div>
              <div className="text-center">
                <Typography className="chat-creation">
                  Chat created by{" "}
                  {admin?.user_id === user.id
                    ? "You"
                    : `${admin?.firstname || ""} ${admin?.lastname || ""}`}
                </Typography>
                <Typography className="chat-creation">
                  Chat created on{" "}
                  {chatData?.createdAt
                    ? moment(chatData.createdAt).format("MMMM DD, YYYY")
                    : ""}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
};

export default ViewGroupModal;
