import React, { useState } from "react";

const Tabs = ({ components, tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tabs-component">
      <div className="flex w-full border-b tabs-header justify-evenly">
        {tabs?.map((tab, idx) => {
          return (
            <button
              key={idx}
              className={`tab-button w-full  py-2 px-4 focus:outline-none ${
                activeTab === idx
                  ? "border-blue-500 border-b-2 text-blue-500"
                  : ""
              }`}
              onClick={() => handleTabClick(idx)}
            >
              {tab}
            </button>
          );
        })}
      </div>
      <div className="tabs-content">
        {components?.map((Component, idx) => {
          return (
            <div
              key={idx}
              className={`tab-content ${
                activeTab === idx ? "" : "hidden"
              } pt-3`}
              id={idx}
            >
              {activeTab === idx ? Component : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Tabs;
