import React, { useEffect } from "react";
import ReusableModal from "../../../../components/Modals/ReusableModal";
import CloseSvg from "../../../../assets/svgs/CloseSvg";
import { useLSStore } from "../../../../context/ls-store";

const ProviderUpdateModal = ({
  type = "update",
  open,
  setOpen,
  provider,
  setProvider,
  handleSubmit,
  isEdit,
}) => {
  const formatPhoneNumber = (input) => {
    const cleaned = ("" + input).replace(/\D/g, "");

    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    return match
      ? [match[1], match[2], match[3]].filter(Boolean).join("-")
      : "";
  };

  const [providerEmail, setProviderEmail] = React.useState(
    provider.email === "NA" ? "" : provider.email
  );
  const [providerPhone, setProviderPhone] = React.useState(
    provider.phone === "NA" ? "" : formatPhoneNumber(provider.phone)
  );

  const [providerNpi, setProviderNpi] = React.useState("");
  const [providerSpeciality, setProviderSpeciality] =
    React.useState("Family Medicine");
  const [providerStreet, setProviderStreet] = React.useState("");
  const [providerCity, setProviderCity] = React.useState("");
  const [providerState, setProviderState] = React.useState("");
  const [providerZip, setProviderZip] = React.useState("");

  const [errorMsg, setErrorMsg] = React.useState(null);

  const setAlert = useLSStore((state) => state.setAlert);

  useEffect(() => {
    if (isEdit) {
      const [street, city, state, zip] = provider.address;
      setProviderNpi(provider.npi_id);
      setProviderSpeciality(provider.speciality);
      setProviderStreet(street);
      setProviderCity(city);
      setProviderState(state);
      setProviderZip(zip);
    }
  }, [isEdit]);

  return (
    <ReusableModal
      open={open}
      title={type === "update"
        ? "Update"
        : type === "update details"
          ? "Update Contact Details"
          : "Send Invite"
      }
      onClose={() => {
        setOpen(false);
        setProvider(null);
      }}
    >
      <div className="flex flex-col w-full gap-4 p-6 min-w-[500px]">
        {type !== "update" ? (
          <>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="email" className="m-0 text-base font-semibold">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                onChange={(e) => setProviderEmail(e.target.value)}
                value={providerEmail}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label htmlFor="phone" className="m-0 text-base font-semibold">
                Phone
              </label>
              <input
                type="tel"
                id="tel"
                name="tel"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                placeholder="xxx-xxx-xxxx"
                value={providerPhone}
                onChange={(e) => {
                  if (e.target.value.length >= 13) {
                    return;
                  }
                  setProviderPhone(formatPhoneNumber(e.target.value));
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col w-full gap-2">
              <label
                htmlFor="npi_id"
                className="relative m-0 text-base font-semibold"
              >
                NPI ID
              </label>
              <input
                type="text"
                id="npi_id"
                name="npi_id"
                placeholder="Enter NPI ID"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                onChange={(e) => setProviderNpi(e.target.value)}
                value={providerNpi}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <label
                htmlFor="speciality"
                className="m-0 text-base font-semibold"
              >
                Speciality
              </label>
              <select
                name="speciality"
                id="speciality"
                className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                onChange={(e) => setProviderSpeciality(e.target.value)}
                value={providerSpeciality}
              >
                <option value="NA">NA</option>
                <option value="Family Medicine">Family Medicine</option>
                <option value="Psychiatrists">Psychiatrists</option>
                <option value="Podiatrists">Podiatrists</option>
                <option value="Cardiologists">Cardiologists</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <label
              htmlFor="street"
              className="text-base font-semibold border-b-[1px]"
            >
              Address
            </label>
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col w-full gap-2">
                <label
                  htmlFor="street"
                  className="m-0 text-base font-semibold"
                >
                  Street
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter Street"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  onChange={(e) => setProviderStreet(e.target.value)}
                  value={providerStreet}
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                <label
                  htmlFor="street"
                  className="m-0 text-base font-semibold"
                >
                  City
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter Street"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  onChange={(e) => setProviderCity(e.target.value)}
                  value={providerCity}
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                <label
                  htmlFor="street"
                  className="m-0 text-base font-semibold"
                >
                  State
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter Street"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  onChange={(e) => setProviderState(e.target.value)}
                  value={providerState}
                />
              </div>
              <div className="flex flex-col w-full gap-2">
                <label
                  htmlFor="street"
                  className="m-0 text-base font-semibold"
                >
                  Zip
                </label>
                <input
                  type="text"
                  id="street"
                  name="street"
                  placeholder="Enter Street"
                  className="w-full p-2 border-[1px] border-primarygray rounded-md outline-none"
                  onChange={(e) => setProviderZip(e.target.value)}
                  value={providerZip}
                />
              </div>
            </div>
          </>
        )}
        {errorMsg && <div className="text-red-500">{errorMsg}</div>}
        <div className="flex flex-row items-center justify-between w-full gap-6">
          <button
            className="w-1/2 p-2 font-semibold rounded-md  border-[2px] text-primaryblue border-primaryblue"
            onClick={() => {
              setOpen(false);
              setProvider(null);
            }}
          >
            Cancel
          </button>
          <button
            disabled={
              type === "invite"
                ? providerEmail === "" || providerPhone === ""
                : type === "update details"
                  ? providerEmail === "" || providerPhone === ""
                  : providerNpi === "" ||
                  providerSpeciality === "" ||
                  providerStreet === "" ||
                  providerCity === "" ||
                  providerState === "" ||
                  providerZip === ""
            }
            className="w-1/2 disabled:opacity-40 disabled:cursor-not-allowed font-semibold p-2 border-[2px] rounded-md bg-primaryblue border-primaryblue text-white whitespace-nowrap"
            onClick={async () => {
              try {
                setErrorMsg(null);
                if (type === "invite") {
                  await handleSubmit({
                    providerEmail,
                    providerPhone,
                  });
                } else if (type === "update details") {
                  await handleSubmit({
                    providerEmail,
                    providerPhone,
                  });
                } else {
                  if (providerNpi === "NA") {
                    setErrorMsg("Please enter correct npi id");
                    return;
                  }

                  await handleSubmit({
                    providerNpi,
                    providerSpeciality,
                    providerStreet,
                    providerCity,
                    providerState,
                    providerZip,
                  });
                }
                setOpen(false);
              } catch (error) {
                setErrorMsg(error.message);
              }
            }}
          >
            {type === "invite"
              ? `Invite${provider?.name?.split(" ")[0]}`
              : "Yes, Update"}
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default ProviderUpdateModal;
