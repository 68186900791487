import "react-big-calendar/lib/css/react-big-calendar.css";

import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import { DatePicker, DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { IconButton, TextField, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  useCreateProviderAvailabilityMutation,
  useGetProviderAvailabilityMutation,
  useUpdateProviderAvailabilityMutation,
} from "../../../../../features/provider/providerApiSlice";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import dayjs from "dayjs";
import moment from "moment";

const localizer = momentLocalizer(moment);

function CalendarComponent({
  isCalendarOpen,
  setIsCalendarOpen,
  provideIdAv,
  showAlert,
}) {
  const [events, setEvents] = useState([
    {
      id: 1,
      title: "Booked",
      start: new Date(2024, 8, 3),
      end: new Date(2024, 8, 7),
    },
  ]);

  const [selectedRange, setSelectedRange] = useState(null);
  const [eventTitle, setEventTitle] = useState("");
  const [value, setValue] = useState(dayjs());
  const [isAllDay, setisAllday] = useState(true);
  const [calendarOpenStart, setCalendarOpenStart] = useState(false);
  const [timePickerOpenStart, setTimePickerOpenStart] = useState(false);
  const calendarRefStart = React.useRef(null);
  const timePickerRefStart = React.useRef(null);
  const [calendarOpenEnd, setCalendarOpenEnd] = useState(false);
  const [timePickerOpenEnd, setTimePickerOpenEnd] = useState(false);
  const calendarRefEnd = React.useRef(null);
  const timePickerRefEnd = React.useRef(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const [newRange, setNewRange] = useState(null);
  const [providerAv, setProviderAv] = useState([]);
  const today = new Date();
  // const today = new Date(2024, 8, 26);
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  const endOfYear = new Date(today.getFullYear(), 11, 31);
  const [isTemprory, setIsTemprory] = useState(false);
  const [createAvailability] = useCreateProviderAvailabilityMutation();
  const [getProviderAvailability] = useGetProviderAvailabilityMutation();
  const [updateAvailability] = useUpdateProviderAvailabilityMutation();
  const [isEdited, setIsEdited] = useState({ status: false, oldName: "" });
  const eightWeeksFromToday = new Date(today);
  eightWeeksFromToday.setDate(today.getDate() + 56); // 8 weeks later
  const isWithinAllowedRange = (date) => {
    const isToday = moment(date).isSame(moment(today), "day");
    return (
      isToday ||
      (moment(date).isAfter(today) &&
        moment(date).isSameOrBefore(eightWeeksFromToday))
    );
  };
  const isEditableToShow =
    selectedEvent && isWithinAllowedRange(selectedEvent.start);

  // const handleSelectSlot = ({ start, end }) => {
  //     // react-big-calendar uses the end date as exclusive, so we adjust it
  //     const adjustedEnd = moment(end).subtract(1, 'seconds').toDate(); // This includes the full end day

  //     setSelectedRange({ start, end: adjustedEnd });
  //     setIsCalendarOpen(true);
  // };
  const handleSelectSlot = ({ start, end }) => {
    const adjustedEnd = moment(end).subtract(1, "seconds").toDate();
    if (!isWithinAllowedRange(start)) {
      showAlert("You can't select dates because it's out of range.", "info");
      return;
    }
    const existingEvents = providerAv.filter((event) => {
      return (
        moment(event.start).isBefore(adjustedEnd) &&
        moment(event.end).isAfter(start)
      );
    });
    if (existingEvents.length > 0) {
      setEventDetails(existingEvents);
      setNewRange({ start, end: adjustedEnd });
      setIsConfirmationModalOpen(true);
    } else {
      setSelectedRange({ start, end: adjustedEnd });
      setIsCalendarOpen(true);
    }
  };
  const closeModal = (obj) => {
    if (obj.isClearAll) {
      setIsCalendarOpen(false);
    }
    setSelectedRange(null);
    setEventTitle("");
  };
  const getDateAfterTwoMonths = (currentDate) => {
    const date = new Date(currentDate); // Initialize with current date
    date.setMonth(date.getMonth() + 2); // Add 2 months
    return date;
  };
  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  function getDatesFromRange(start, end) {
    const startDate = new Date(start); // Convert start string to Date object
    const endDate = new Date(end); // Convert end string to Date object

    const dates = [];
    let currentDate = new Date(startDate); // Clone the start date

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate)); // Push the current date to the array
      currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return dates;
  }
  function getWeekNumberFromDate(date, startDate) {
    const oneDay = 1000 * 60 * 60 * 24; // Milliseconds in one day
    const diffInDays = Math.floor((date - startDate) / oneDay);

    // Calculate the week number based on the current date's difference from the start date
    const weekNumber = Math.floor(diffInDays / 7) + 1; // Week numbers start from 1

    // Calculate the start and end date for the calculated week number
    const weekStartDate = new Date(
      startDate.getTime() + (weekNumber - 1) * 7 * oneDay
    );
    const weekEndDate = new Date(weekStartDate.getTime() + 6 * oneDay); // 6 days after the start date

    // Debugging output
    console.log(
      `Week Number: ${weekNumber}, Week Start: ${weekStartDate.toISOString()}, Week End: ${weekEndDate.toISOString()}`
    );

    // Check if the selected date falls within the calculated week
    if (date >= weekStartDate && date <= weekEndDate) {
      return weekNumber; // Return the week number if the date is in the range
    }

    return weekNumber + 1; // Otherwise, it belongs to the next week
  }

  function addDatesToPattern(selectedDates, pattern, startDate) {
    selectedDates.forEach((selectedDate) => {
      const defaultTimeSlots = ["00:00:00", "23:59:59"];
      const weekNumber = getWeekNumberFromDate(selectedDate, startDate);
      const weekIndex = pattern.findIndex(
        (week) => week.week_number === weekNumber
      );

      const dayNames = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY",
      ];
      let dateTimeSlots;
      const isSingleDate = selectedDates.length === 1;
      if (isSingleDate) {
        const startTime = new Date(selectedRange.start)
          .toISOString()
          .split("T")[1]
          .split("Z")[0]; // Extract time from start
        const endTime = new Date(selectedRange.end)
          .toISOString()
          .split("T")[1]
          .split("Z")[0]; // Extract time from end
        dateTimeSlots = [`${startTime}`, `${endTime}`];
      } else {
        dateTimeSlots = defaultTimeSlots;
      }
      if (weekIndex >= 0) {
        pattern[weekIndex].available_days.push({
          day: dayNames[selectedDate.getDay()],
          date: formatDateToYYYYMMDD(selectedDate),
          time_slots: dateTimeSlots,
          title: `${dateTimeSlots[0]}-${dateTimeSlots[1]}`,
        });
      }
    });

    return pattern;
  }
  const confirmBooking = async () => {
    const result = await getProviderAvailability({
      id: provideIdAv,
      startDate: formatDateToYYYYMMDD(today),
      endDate: formatDateToYYYYMMDD(getDateAfterTwoMonths(today)),
    }).unwrap();
    // setProviderAv(result?.data);

    const getUpdatedBooking = (data) => {
      const overlappingWeeksWithDates = data
        .map((week) => {
          const weekStart = new Date(week.week_start_date);
          const weekEnd = new Date(week.week_end_date);

          weekStart.setHours(0, 0, 0, 0);
          weekEnd.setHours(23, 59, 59, 999);

          if (
            weekStart <= selectedRange.end &&
            weekEnd >= selectedRange.start
          ) {
            const datesInRange = [];
            const effectiveStart =
              weekStart < selectedRange.start ? selectedRange.start : weekStart;
            const effectiveEnd =
              weekEnd > selectedRange.end ? selectedRange.end : weekEnd;
            const isSingleDay =
              selectedRange.start.toDateString() ===
              selectedRange.end.toDateString();

            const singleDayTimeSlot = isSingleDay
              ? [
                selectedRange.start.toTimeString().split(" ")[0], // Extract time from start date
                selectedRange.end.toTimeString().split(" ")[0] // Extract time from end date
              ]
              : [];

            const defaultTimeSlot = ["00:00:00", "23:59:59"];
            for (
              let d = new Date(effectiveStart);
              d <= effectiveEnd;
              d.setDate(d.getDate() + 1)
            ) {
              const dayOfWeek = d.getDay();
              const dayNames = [
                "SUNDAY",
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
              ];

              if (d >= weekStart && d <= weekEnd) {
                datesInRange.push({
                  date:
                    d.getFullYear() +
                    "-" +
                    String(d.getMonth() + 1).padStart(2, "0") +
                    "-" +
                    String(d.getDate()).padStart(2, "0"),
                  day: dayNames[dayOfWeek],
                  time_slots:
                    isSingleDay &&
                      d.toDateString() === selectedRange.start.toDateString()
                      ? singleDayTimeSlot
                      : defaultTimeSlot,
                });
              }
            }
            return {
              id: week.id,
              week_number: week.week_number,
              dates: datesInRange,
            };
          }
          return null;
        })
        .filter(Boolean);
      return overlappingWeeksWithDates;
    };

    const getExistingAvailability = (weekId) => {
      // Filter to get the existing available dates for the specific week
      const weekData = result.data.find((item) => item.id === weekId);
      return weekData ? weekData.availableDates : [];
    };

    if (result?.data.length <= 0) {
      const Pattern = Array.from({ length: 8 }, (_, index) => ({
        week_number: index + 1,
        available_days: [],
      }));

      const selectedDates = getDatesFromRange(
        selectedRange?.start,
        selectedRange?.end
      );

      const updatedPattern = addDatesToPattern(selectedDates, Pattern, today);
      console.log(updatedPattern, ">>> updated parttern");

      await createAvailability({
        data: { startDate: formatDateToYYYYMMDD(today), pattern: Pattern },
        id: provideIdAv,
      });

      // const result2 = await getProviderAvailability({ id: provideIdAv, startDate: formatDateToYYYYMMDD(today), endDate: formatDateToYYYYMMDD(getDateAfterTwoMonths(today)) }).unwrap();

      // const overlappingWeeksWithDates = getUpdatedBooking(result2?.data);

      // await Promise.all(overlappingWeeksWithDates?.map(async (item) => {
      //     const existingDates = await getExistingAvailability(item.id); // Fetch current available dates
      //     const newDates = item.dates.map(dateItem => ({
      //         day: dateItem.day,
      //         date: dateItem.date,
      //         title: eventTitle,
      //         time_slots: dateItem?.time_slots[0]
      //     }));

      //     const mergedDates = [
      //         ...existingDates,
      //         // ...newDates.filter(newDate => !existingDates.some(existing => existing.date === newDate.date))
      //         ...newDates
      //     ];

      //      await updateAvailability({ id: item.id, data: { isTemprory: isTemprory, availableDates: mergedDates } }).unwrap();
      // }));
    } else {
      const overlappingWeeksWithDates = getUpdatedBooking(result?.data);

      await Promise.all(
        overlappingWeeksWithDates?.map(async (item) => {
          const existingDates = await getExistingAvailability(item.id); // Fetch current available dates
          const newDates = item.dates.map((dateItem) => ({
            day: dateItem.day,
            date: dateItem.date,
            title: `${dateItem?.time_slots[0]}-${dateItem?.time_slots[1]}`,
            time_slots: [dateItem?.time_slots[0], dateItem?.time_slots[1]],
          }));
          // Merge existing and new dates without duplicates
          const mergedDates = [
            ...(isEdited?.status == true
              ? existingDates.filter(
                (existing) =>
                  !newDates.some(
                    (newDate) =>
                      newDate.date === existing.date &&
                      isEdited?.oldName ===
                      `${existing?.time_slots[0]}-${existing?.time_slots[1]}`
                  )
              )
              : existingDates),
            ...newDates,
          ];
          console.log(mergedDates);
          await updateAvailability({
            id: item.id,
            data: { isTemprory: isTemprory, availableDates: mergedDates },
          }).unwrap();
        })
      );
    }

    // Fetch updated availability after all updates are done
    await getProviderAvailabilityFun({
      startDate: formatDateToYYYYMMDD(today),
      endDate: formatDateToYYYYMMDD(getDateAfterTwoMonths(today)),
    });
    setIsEdited(false);
    closeModal({ isClearAll: false });
  };

  const dayPropGetter = (date) => {
    if (selectedRange) {
      const { start, end } = selectedRange;
      const isInRange = date >= start && date <= end;
      const isSingleDay = moment(start).isSame(end, "day");
      if (isInRange) {
        if (!isSingleDay) {
          return {
            style: {
              borderLeft:
                date.getTime() === start.getTime() ? "2px solid #c0e1f9" : "",
              borderRight:
                date.getTime() === end.getTime() ? "2px solid #c0e1f9" : "",
              borderTop: "2px solid #c0e1f9",
              borderBottom: "2px solid #c0e1f9",
            },
          };
        } else {
          return {
            style: {
              border: "2px solid #c0e1f9",
            },
          };
        }
      }
    }
    return {};
  };
  const handleIconClick = (obj) => {
    if (obj?.start == true) {
      setCalendarOpenStart(!calendarOpenStart);
      setTimePickerOpenStart(false);
    } else {
      setCalendarOpenEnd(!calendarOpenEnd);
      setTimePickerOpenEnd(false);
    }
  };
  const handleTimeIconClick = (obj) => {
    if (obj?.start == true) {
      setTimePickerOpenStart(!timePickerOpenStart);
      setCalendarOpenStart(false);
    } else {
      setTimePickerOpenEnd(!timePickerOpenEnd);
      setCalendarOpenEnd(false);
    }
  };
  const groupEventsByTitle = (events) => {
    return events.reduce((acc, event) => {
      if (!acc[event.title]) {
        acc[event.title] = [];
      }
      acc[event.title].push(event);
      return acc;
    }, {});
  };

  // Grouping the events
  const groupedEvents = groupEventsByTitle(eventDetails);
  const handleClickOutside = (event) => {
    if (
      calendarRefStart.current &&
      !calendarRefStart.current.contains(event.target)
    ) {
      setCalendarOpenStart(false);
    }
    if (
      timePickerRefStart.current &&
      !timePickerRefStart.current.contains(event.target)
    ) {
      setTimePickerOpenStart(false);
    }
    if (
      calendarRefEnd.current &&
      !calendarRefEnd.current.contains(event.target)
    ) {
      setCalendarOpenEnd(false);
    }
    if (
      timePickerRefEnd.current &&
      !timePickerRefEnd?.current.contains(event.target)
    ) {
      setTimePickerOpenEnd(false);
    }
  };
  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setIsEventModalOpen(true);
  };
  const closeEventModal = () => {
    setIsEventModalOpen(false);
    setSelectedEvent(null);
  };

  const eventPropGetter = (event) => {
    const isFutureEvent = moment(event.start).isSameOrAfter(today, "day");
    if (isFutureEvent) {
      return {
        style: {
          backgroundColor: "#c5f2c5",
          padding: "4px 5px",
          borderRadius: "2px",
          color: "#006400",
        },
      };
    } else {
      return {
        style: {
          backgroundColor: "#f2c5c5",
          padding: "4px 5px",
          borderRadius: "2px",
          color: "#a10000",
        },
      };
    }
  };
  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const getProviderAvailabilityFun = async (obj) => {
    const result = await getProviderAvailability({
      id: provideIdAv,
      startDate: obj?.startDate,
      endDate: obj?.endDate,
    }).unwrap();
    // const transformedEvents = result?.data?.map(item =>
    //     item.availableDates?.map(availableDate => ({
    //         start: new Date(`${availableDate?.date}T${availableDate?.time_slots?.start}`), // Combine date and start time
    //         end: new Date(`${availableDate?.date}T${availableDate?.time_slots?.end}`),
    //         title: availableDate?.title !== "" || availableDate?.title !== undefined || availableDate?.title !== null ? availableDate?.title : "Booked"
    //     }))
    // ).flat(); // Flatten the array if there are multiple available dates
    const transformedEvents = result?.data
      ?.map((item) =>
        item.availableDates?.map((availableDate) => {
          const startTime = availableDate?.time_slots[0];
          const endTime = availableDate?.time_slots[1];
          const formattedStart = new Date(
            `${availableDate?.date}T${startTime}`
          ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          const formattedEnd = new Date(
            `${availableDate?.date}T${endTime}`
          ).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

          return {
            start: new Date(`${availableDate?.date}T${startTime}`), // Combine date and start time
            end: new Date(`${availableDate?.date}T${endTime}`), // Combine date and end time
            title:
              availableDate?.title && availableDate?.title !== ""
                ? `${formattedStart} to ${formattedEnd}`
                : `${formattedStart} to ${formattedEnd}`,
            time_slots: availableDate?.time_slots,
          };
        })
      )
      .flat();
    setProviderAv(transformedEvents);
    // setProviderAv(result?.data);
  };
  React.useEffect(() => {
    getProviderAvailabilityFun({
      startDate: formatDateToYYYYMMDD(today),
      endDate: formatDateToYYYYMMDD(getDateAfterTwoMonths(today)),
    });
  }, []);
  return (
    <>
      <button
        onClick={() => setIsCalendarOpen(true)}
        style={{ marginBottom: "10px" }}
      >
        Open Calendar Modal
      </button>
      {isCalendarOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle} className="calendar-main">
            <div className="py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
              <span className="font-bold text-gray-800 text-md">
                Provider Availability
              </span>
              <Tooltip title="Close">
                <button onClick={() => closeModal({ isClearAll: true })}>
                  <CloseIcon />
                </button>
              </Tooltip>
            </div>
            <BigCalendar
              localizer={localizer}
              events={providerAv}
              startAccessor="start"
              endAccessor="end"
              selectable
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleEventClick}
              views={["month", "week", "day"]}
              defaultView="month"
              style={{
                height: 550,
                width: 800,
                backgroundColor: "#fff",
                fontSize: "12px",
                textAlign: "center",
              }}
              dayPropGetter={dayPropGetter}
              eventPropGetter={eventPropGetter}
              className="text-gray-600"
            />
          </div>
        </div>
      )}
      {isCalendarOpen && selectedRange && isWithinAllowedRange && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <div className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
              <span className="font-bold text-gray-800 text-md">
                Confirm Availability
              </span>
              <Tooltip title="Close">
                <button onClick={() => closeModal({ isClearAll: false })}>
                  <CloseIcon />
                </button>
              </Tooltip>
            </div>
            <div
              style={dateInputContainerStyle}
              className="relative  px-2 mt-3 gap-2 calendar-availability"
            >
              <span style={inputWrapperStyle}>
                <label style={labelStyle}>Start Date & Time</label>
                <input
                  type="text"
                  value={moment(selectedRange.start).format(
                    "MM/DD/YYYY  h:mm A"
                  )}
                  readOnly
                  className="pl-2 py-2 pr-14 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-[100%] focus:ring-blue-500 focus:border-blue-500"
                />
                <span style={iconStyle} className="w-12 mr-2">
                  <Tooltip title="Select Date">
                    <CalendarMonthIcon
                      onClick={() =>
                        handleIconClick({ start: true, end: false })
                      }
                      className="cursor-pointer mr-1"
                    />
                  </Tooltip>
                  <Tooltip title="Select Time">
                    <AccessTimeIcon
                      onClick={() =>
                        handleTimeIconClick({ start: true, end: false })
                      }
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </span>
              </span>
              {calendarOpenStart && (
                <div
                  ref={calendarRefStart}
                  className="calendar-datepicker"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    backgroundColor: "white",
                    right: 260,
                    top: 68,
                    border: "1px solid grey",
                    borderRadius: "8px",
                    width: "290px",
                    height: "287px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={dayjs(value)}
                      onChange={(newValue) => {
                        setValue(new Date(newValue));
                        setSelectedRange({
                          ...selectedRange,
                          start: new Date(newValue),
                        });
                        setCalendarOpenStart(false);
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {timePickerOpenStart && (
                <div
                  ref={timePickerRefStart}
                  className="calendar-timepicker"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    backgroundColor: "white",
                    top: 68,
                    left: 76,
                    border: "1px solid grey",
                    width: "178px",
                    height: "185px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MultiSectionDigitalClock
                      value={dayjs(selectedRange.start)}
                      sx={{
                        width: "100%",
                        height: "100%",
                        "& ul": {
                          overflowY: "scroll",
                        },
                      }}
                      onChange={(newValue) => {
                        setSelectedRange({
                          ...selectedRange,
                          start: new Date(
                            selectedRange.start.setHours(
                              newValue.$H,
                              newValue.$m // Update hours and minutes
                            )
                          ),
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              )}
              <span style={inputWrapperStyle}>
                <label style={labelStyle}>End Date & Time</label>
                <input
                  type="text"
                  value={moment(selectedRange.end).format(
                    "MM/DD/YYYY  h:mm A"
                  )}
                  readOnly
                  className="pl-2 py-2 pr-14 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-[100%] focus:ring-blue-500 focus:border-blue-500"
                />
                <span style={iconStyle} className="w-12 mr-2">
                  <Tooltip title="Select Date">
                    <CalendarMonthIcon
                      onClick={() =>
                        handleIconClick({ start: false, end: true })
                      }
                      className="cursor-pointer mr-1"
                    />
                  </Tooltip>
                  <Tooltip title="Select Time">
                    <AccessTimeIcon
                      onClick={() =>
                        handleTimeIconClick({ start: false, end: true })
                      }
                      className="cursor-pointer"
                    />
                  </Tooltip>
                </span>
              </span>
              {calendarOpenEnd && (
                <div
                  ref={calendarRefEnd}
                  className="calendar-datepicker"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    backgroundColor: "white",
                    left: 220,
                    top: 68,
                    border: "1px solid grey",
                    borderRadius: "8px",
                    width: "290px",
                    height: "287px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                      value={dayjs(value)}
                      onChange={(newValue) => {
                        setValue(new Date(newValue));
                        setSelectedRange({
                          ...selectedRange,
                          end: new Date(newValue),
                        });
                        setCalendarOpenEnd(false);
                      }}
                      sx={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </LocalizationProvider>
                </div>
              )}
              {timePickerOpenEnd && (
                <div
                  ref={timePickerRefEnd}
                  className="calendar-timepicker"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    backgroundColor: "white",
                    top: 68,
                    right: 10,
                    border: "1px solid grey",
                    width: "178px",
                    height: "185px",
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MultiSectionDigitalClock
                      value={dayjs(selectedRange.end)}
                      sx={{
                        width: "100%",
                        height: "100%",
                        "& ul": {
                          overflowY: "scroll",
                        },
                      }}
                      onChange={(newValue) => {
                        setSelectedRange({
                          ...selectedRange,
                          end: new Date(
                            selectedRange.end.setHours(
                              newValue.$H,
                              newValue.$m // Update hours and minutes
                            )
                          ),
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              )}
            </div>
            <span className="space-x-3 flex justify-end mt-4">
              <button
                onClick={() => closeModal({ isClearAll: false })}
                // className="px-3 py-2 font-semibold text-sm text-white bg-gray-700 rounded-md"
                className="text-sm font-semibold px-3 py-1 border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue whitespace-nowrap"
              >
                Cancel
              </button>
              <button
                onClick={confirmBooking}
                // className="px-3 py-2 text-sm font-semibold text-white rounded-md bg-primaryblue"
                className="px-3 py-1 font-semibold text-sm text-white rounded-md  bg-primaryblue whitespace-nowrap border-[2px] border-primaryblue"
              >
                Confirm
              </button>
            </span>
          </div>
        </div>
      )}
      {/* {isCalendarOpen && selectedRange && isWithinAllowedRange && (
                <div style={modalOverlayStyle}>
                    <div style={modalStyle}>
                        <div className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
                            <span className="font-bold text-gray-800 text-md">Confirm Availability</span>
                            <button onClick={() => closeModal({ isClearAll: false })}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div style={{ padding: '10px 10px 0px 10px' }}>
                            <span style={inputWrapperStyle} className="mb-1">
                                <label style={labelStyle}>Title</label>
                                <input
                                    type="text"
                                    value={eventTitle}
                                    onChange={(e) => setEventTitle(e.target.value)}
                                    className="mb-1 pl-2 py-2 pr-4 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-full focus:ring-blue-500 focus:border-blue-500"
                                />
                            </span>
                            <span className='d-flex gap-3 mt-3 calander'>
                                <span style={inputWrapperStyle}>
                                    <label style={labelStyle}>Start Date & Time</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            renderInput={() => null}
                                            value={dayjs(selectedRange.start)}
                                            onChange={(newValue) => {
                                                setSelectedRange({ ...selectedRange, start: new Date(newValue) });
                                            }}
                                        />
                                    </LocalizationProvider>
                                </span>
                                <span style={inputWrapperStyle} className='relative'>
                                    <label style={labelStyle}>End Date & Time</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DateTimePicker
                                            value={dayjs(selectedRange.end)}
                                            onChange={(newValue) => {
                                                setSelectedRange({ ...selectedRange, end: new Date(newValue) });
                                            }}
                                            renderInput={(params) => (
                                                <input
                                                    {...params.inputProps}
                                                    className="pl-2 py-2 pr-4 text-sm text-gray-900 bg-white border border-gray-300 rounded-lg w-fit focus:ring-blue-500 focus:border-blue-500"
                                                />
                                            )}
                                            PopperProps={{
                                                placement: 'top', // Popper positioned on top
                                                sx: {
                                                    zIndex: 1500, // Ensure it appears above other elements
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                </span>
                            </span>
                            <div className="flex items-center mt-3">
                                <label className="text-sm font-semibold">
                                    <input type="checkbox" checked={isTemprory} onChange={() => setIsTemprory(!isTemprory)} />
                                    &nbsp; <span className="text-center">Temporary</span>
                                </label>
                            </div>
                            <span className='space-x-3 flex justify-end mt-4'>
                                <button
                                    onClick={() => closeModal({ isClearAll: false })}
                                    className="px-3 py-2 font-semibold text-sm text-white bg-gray-700 rounded-md"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={confirmBooking}
                                    className="px-3 py-2 text-sm font-semibold text-white rounded-md bg-primaryblue"
                                >
                                    Confirm
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            )} */}
      {isEventModalOpen && selectedEvent && (
        <span style={modalOverlayStyle}>
          <span style={modalStyle} className="calendar-availability">
            <span className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
              <span className="font-bold text-gray-800 text-md">
                Availability Details
              </span>
              <Tooltip title="Close">
                <button
                  onClick={() => {
                    closeEventModal();
                    setIsEdited(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </Tooltip>
            </span>
            <div style={{ padding: "10px 10px 0px 10px" }}>
              {/* <span className='bg-blue-100 flex items-center h-10 w-full rounded mb-3 '>
                                <p className='m-0 pl-2 text-[14px] font-semibold'>{selectedEvent.title}</p>
                            </span> */}
              <span className="flex items-center mb-2">
                <CalendarMonthIcon className="mr-2 text-gray-700 " />
                <p className="m-0 text-sm font-semibold text-gray-600">
                  {moment(selectedEvent.start).format("Do MMMM YYYY")} -{" "}
                  {moment(selectedEvent.end).format("Do MMMM YYYY")}
                </p>
              </span>
              <span className="flex items-center mb-2">
                <AccessTimeIcon className="mr-2 text-gray-700 " />
                <p className="m-0 text-sm font-semibold text-gray-600">
                  {moment(selectedEvent.start).format("h:mm A")} -{" "}
                  {moment(selectedEvent.end).format("h:mm A")}
                </p>
              </span>
              <span className="space-x-3 flex justify-end mt-3">
                {/* <button
                                    className="px-3 py-2 font-semibold text-sm text-white bg-gray-700 rounded-md"
                                    onClick={() => {
                                        setIsDeleteModalOpen(true);
                                        setIsEventModalOpen(false);
                                    }}
                                >
                                    Delete
                                </button> */}
                {isEditableToShow && (
                  <button
                    onClick={() => {
                      console.log(selectedEvent, ">>> selected eeven");
                      setIsEventModalOpen(false);
                      setSelectedRange({
                        start: selectedEvent?.start,
                        end: selectedEvent?.end,
                      });
                      setEventTitle(
                        `${selectedEvent?.time_slots[0]}-${selectedEvent?.time_slots[1]}`
                      );
                      setIsEdited({
                        status: true,
                        oldName: `${selectedEvent?.time_slots[0]}-${selectedEvent?.time_slots[1]}`,
                      });
                    }}
                    className="px-4 py-1 font-semibold text-sm text-white bg-primaryblue rounded-md border-[2px] border-primaryblue"
                  >
                    Edit
                  </button>
                )}
              </span>
              {/* <button
                                onClick={closeEventModal}
                                className="px-4 py-2 font-bold text-white bg-gray-700 rounded-md"
                            >
                                Close
                            </button> */}
            </div>
          </span>
        </span>
      )}
      {/* {isEventModalOpen && selectedEvent && (
                <div style={modalOverlayStyle}>
                    <div style={modalStyle}>
                        <div className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
                            <span className="font-bold text-gray-800 text-md">Event Details</span>
                            <button onClick={closeEventModal}>
                                <CloseIcon />
                            </button>
                        </div>
                        <div style={{ padding: '10px' }}>
                            <h3>{selectedEvent.title}</h3>
                            <p>
                                Start: {moment(selectedEvent.start).format('MMMM Do YYYY, h:mm A')}
                            </p>
                            <p>
                                End: {moment(selectedEvent.end).format('MMMM Do YYYY, h:mm A')}
                            </p>
                            <button
                                onClick={() => {
                                    setIsEventModalOpen(false);
                                    setSelectedRange({ start: selectedEvent?.start, end: selectedEvent?.end })
                                    setEventTitle(selectedEvent?.title);
                                }}
                                className="px-4 py-2 font-bold text-white bg-gray-700 rounded-md"
                            >
                                edit
                            </button>
                            <button
                                onClick={() => {
                                    setIsDeleteModalOpen(true);
                                    setIsEventModalOpen(false);
                                }}
                                className="px-4 py-2 font-bold text-white bg-gray-700 rounded-md"
                            >
                                delete
                            </button>
                        </div>
                    </div>
                </div>
            )} */}
      {isDeleteModalOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <div className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
              <span className="font-bold text-gray-800 text-md">
                Delete Event
              </span>
              <Tooltip title="Close">
                <button
                  onClick={() => {
                    setIsDeleteModalOpen(false);
                    setSelectedEvent(null);
                  }}
                >
                  <CloseIcon />
                </button>
              </Tooltip>
            </div>
            <span
              className="d-flex flex-col"
              style={{ padding: "10px 10px 0px 10px " }}
            >
              <p>Are you sure you want to delete this event ?</p>
              <div className="d-flex justify-end gap-1">
                <button
                  onClick={() => {
                    setEvents(
                      events?.filter((item) => item?.id !== selectedEvent?.id)
                    );
                    setIsEventModalOpen(false);
                    setSelectedEvent(null);
                    setIsDeleteModalOpen(false);
                  }}
                  className="px-3 py-2 font-semibold text-sm text-white bg-primaryblue rounded-md"
                >
                  Delete
                </button>
              </div>
            </span>
          </div>
        </div>
      )}
      {isConfirmationModalOpen && (
        <div style={modalOverlayStyle}>
          <div style={modalStyle}>
            <div className="px-2 py-2 rounded-t d-flex justify-content-between align-items-center bg-white">
              <span className="font-bold text-gray-800 text-md">
                Confirm Availability Creation
              </span>
              <Tooltip title="Close">
                <button onClick={() => setIsConfirmationModalOpen(false)}>
                  <CloseIcon />
                </button>
              </Tooltip>
            </div>
            <span
              className="d-flex flex-col"
              style={{ padding: "10px 10px 0px 10px " }}
            >
              <p>
                There are existing Availabilities on this date. Are you sure you
                want to proceed?
              </p>
              <p className="text-sm font-semibold mb-2">
                Available Availabilities Details
              </p>
              {/* <div className='max-h-[150px] overflow-auto custom-scrollbar'>
                                {eventDetails.map((event, index) => (
                                    <div key={index}>
                                        <p className='text-xs m-0'>
                                            <strong>{event.title}</strong> :
                                            {moment(event.start).format('Do MMMM, YYYY h:mm A')} -
                                            {moment(event.end).format('h:mm A')}
                                        </p>
                                    </div>
                                ))}
                            </div> */}
              <div className="max-h-[150px] overflow-auto custom-scrollbar">
                {Object.entries(groupedEvents).map(([title, events]) => (
                  <div key={title}>
                    <p className="text-xs m-0">
                      <strong>{title}</strong>:
                    </p>
                    <ul className="list-disc pl-5">
                      {events.map((event, index) => (
                        <li key={index} className="text-xs m-0">
                          {moment(event.start).format("Do MMMM, YYYY h:mm A")} -
                          {moment(event.end).format("h:mm A")}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
              <div className="d-flex justify-end gap-2 mt-3">
                <button
                  onClick={() => {
                    setIsConfirmationModalOpen(false);
                  }}
                  className="px-3 py-1 font-semibold text-sm  border-[2px] rounded-md bg-subtleblue border-primaryblue text-primaryblue"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setIsConfirmationModalOpen(false);
                    setSelectedRange({
                      start: newRange.start,
                      end: moment(newRange.end).subtract(1, "seconds").toDate(),
                    });
                    setEventTitle("");
                    setIsCalendarOpen(true);
                  }}
                  className="px-3 py-1 font-semibold text-sm text-white bg-primaryblue rounded-md"
                >
                  Proceed
                </button>
              </div>
            </span>
          </div>
        </div>
      )}
    </>
  );
}
const modalOverlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyle = {
  backgroundColor: "#fff",
  padding: "10px 20px 10px 20px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
};

const modalButtonContainer = {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
};

const dateInputContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
};

const inputWrapperStyle = {
  position: "relative",
  width: "50%",
};

const iconStyle = {
  position: "absolute",
  // right: "-9px",
  right: "-6px",
  top: "70%",
  transform: "translateY(-50%)",
  // fontSize: "18px",
  color: "#aaa",
};

const labelStyle = {
  display: "block",
  marginBottom: "5px",
  fontWeight: "bold",
  color: "#333",
  fontSize: "13px",
};

export default CalendarComponent;
