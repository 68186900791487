import React, { useEffect, useState } from "react";
import BackOfficeUser from "./components/BackOfficeUser";
import ProviderUser from "./components/ProviderUser";
import { useDispatch, useSelector } from "react-redux";
import { logOut, selectUser, updateUser } from "../../features/auth/authSlice";
import {
  useGetUserMutation,
  useUpdateUserMutation,
} from "../../features/user/userApiSlice";
import { useLogoutMutation } from "../../features/auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { isStrongPassword } from "../../utilities/resuseFunctions";

const Account = () => {
  const user = useSelector(selectUser);

  const [getUser, { isLoading: userLoading }] = useGetUserMutation();
  const [useLogout, { isLoading: logoutLoading }] = useLogoutMutation();
  const [updateUserApi, { isLoading: updateLoading }] = useUpdateUserMutation();
  const [isEdit, setIsEdit] = useState(false);
  const [userData, setUserData] = useState(user);
  const [errMsg, setErrMsg] = React.useState(null);

  const isLoading = userLoading || logoutLoading || updateLoading;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUser = async () => {
    try {
      const { data } = await getUser().unwrap();

      dispatch(
        updateUser({
          user: data,
        })
      );
      setUserData(data);
    } catch (error) {
      console.error("Failed to fetch user: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await useLogout().unwrap();
      dispatch(logOut());
      return navigate("/signin");
    } catch (error) {
      console.error("Failed to logout: ", error);
    }
  };

  const handleSave = () => {
    if (userData.password && !isStrongPassword(userData.password)) {
      setErrMsg(
        "Password must contain at least 8 characters, one uppercase, one lowercase, one digit and one special character."
      );
      return;
    }
    setErrMsg(null);
    updateUserApi(userData).then(() => {
      const { password, ...rest } = userData;
      setUserData(rest);
      setIsEdit(false);
      dispatch(
        updateUser({
          user: rest,
        })
      );
    });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <div className="flex flex-col gap-12 p-12">
      {isLoading && <Loading loading={isLoading} />}
      <div className="flex flex-row items-center justify-between">
        <h1 className="m-0 text-2xl font-bold">Account Details</h1>
        <div className="flex flex-row gap-6">
          <button
            className="py-2 font-semibold bg-white border-2 rounded-md shadow-sm border-primarylightgrey w-60"
            onClick={() => setIsEdit(!isEdit)}
          >
            <span className="text-primarytextgrey">
              {isEdit ? "Cancel" : "Edit Account Details"}
            </span>
          </button>
          <button
            onClick={isEdit ? handleSave : handleLogout}
            className="py-2 font-semibold text-white rounded-md shadow-sm w-60 bg-primaryblue"
          >
            <span>{isEdit ? "Save" : "Sign out"}</span>
          </button>
        </div>
      </div>
      {user.type === 1 ? (
        <BackOfficeUser
          user={user}
          setUserData={setUserData}
          userData={userData}
          isEdit={isEdit}
          errMsg={errMsg}
        />
      ) : (
        <ProviderUser
          user={user}
          setUserData={setUserData}
          userData={userData}
          isEdit={isEdit}
          errMsg={errMsg}
        />
      )}
      <div className="w-full h-[1px] bg-primarygrayborder" />
      <div className="flex flex-row gap-12">
        <a href="#" className="no-underline text-lightblue">
          Privacy
        </a>
        <a href="#" className="no-underline text-lightblue">
          HIPAA Compliance
        </a>
      </div>
    </div>
  );
};

export default Account;
