import classNames from "classnames";
import React, { useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import { appointmentStatuses, capitalize, locationTypes, status } from "../data";
import ReusableModal from "../../../components/Modals/ReusableModal";
import { Tooltip } from "@mui/material";

const AppointmentFilterModal = ({
  filterOpen,
  setFilterOpen,
  appointmentStatus,
  setAppointmentStatus,
  locationType,
  setLocationType,
  appointmentTypes,
  communities,
  patients,
  appointmentType,
  setAppointmentType,
  community,
  setCommunity,
  patient,
  setPatient,
  setRecallSchedule,
}) => {
  const [filterOne, setFilterOne] = useState(appointmentStatus);
  const [filterTwo, setFilterTwo] = useState(appointmentType);
  const [filterThree, setFilterThree] = useState(locationType);
  const [filterFour, setFilterFour] = useState(community);
  const [filterFive, setFilterFive] = useState(patient);

  const onConfirm = () => {
    setAppointmentStatus(filterOne);
    setAppointmentType(filterTwo);
    setLocationType(filterThree);
    setCommunity(filterFour);
    setPatient(filterFive);
    setRecallSchedule(true);
    setFilterOpen(false);
  };

  const onClear = () => {
    setAppointmentStatus("All");
    setLocationType("All");
    setAppointmentType("All");
    setCommunity("All");
    setPatient({
      patientId: "All",
      patientName: "All",
    });
    setRecallSchedule(true);
    setFilterOpen(false);
    setFilterOne("All");
    setFilterTwo("All");
    setFilterThree("All");
    setFilterFour("All");
    setFilterFive({
      patientId: "All",
      patientName: "All",
    });
  };

  return (
    // <ReusableModal open={filterOpen} hideHeader={true}>
    <div
      className={classNames(
        "absolute top-[0px] transition-all min-h-fit bg-white w-96 border-l border-t border-b border-secondarygrayborder right-0 opacity-100",
        filterOpen ? "right-0" : "right-[-400px]"
      )}
    >
      <div className="flex flex-row items-center justify-between w-full p-4">
        <p className="m-0 text-2xl font-bold text-primaryblack">
          Apply Filters
        </p>
        <Tooltip title="Close">
          <button
            onClick={() => {
              setFilterOpen(false);
            }}
            className="flex items-center justify-center w-10 rounded-full aspect-square bg-primarylightgrey"
          >
            <CloseSvg color={"#3062D4"} />
          </button>
        </Tooltip>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col w-full gap-6 p-4">
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <p className="m-0 text-base font-bold">Appointment Status</p>
          <select
            value={filterOne}
            onChange={(e) => {
              setFilterOne(e.target.value);
            }}
            className="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500"
          >
            {appointmentStatuses.map((data, indx) => (
              <option key={indx} value={data?.value}>
                {/* {capitalize(data.toLowerCase())} */}
                {data?.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <p className="m-0 text-base font-bold">Appointment Type</p>
          <select
            value={filterTwo}
            onChange={(e) => {
              setFilterTwo(e.target.value);
            }}
            className="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500"
          >
            {appointmentTypes.map((data, indx) => (
              <option key={indx} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <p className="m-0 text-base font-bold">Location Type</p>
          <select
            value={filterThree}
            onChange={(e) => {
              setFilterThree(e.target.value);
            }}
            className="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500"
          >
            {locationTypes.map((data, indx) => (
              <option key={indx} value={data.id}>
                {data.name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <p className="m-0 text-base font-bold">Community</p>
          <select
            value={filterFour}
            onChange={(e) => {
              setFilterFour(e.target.value);
            }}
            className="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500"
          >
            {/* {communities.map((data, indx) => (
              <option key={indx} value={data.id}>
                {data.name}
              </option>
            ))} */}
            {communities
              .filter((data) => data.name)
              .map((data, indx) => (
                <option key={indx} value={data.id}>
                  {data.name}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-col items-start justify-start w-full gap-2">
          <p className="m-0 text-base font-bold">Patient</p>
          <select
            value={filterFive}
            onChange={(e) => {
              setFilterFive(e.target.value);
            }}
            className="block w-full p-1 text-sm text-gray-900 border border-gray-300 rounded-lg bg-slate-50 focus:ring-blue-500 focus:border-blue-500"
          >
            {patients.map((data, indx) => (
              <option key={indx} value={data.patientId}>
                {data.patientName}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      <div className="flex flex-col w-full gap-8 p-4 mb-4">
        <button
          onClick={onClear}
          className="flex flex-row items-center justify-center w-full px-3 py-2 border rounded-md shadow-sm border-primarygrayborder"
        >
          <p className="m-0 text-base text-primaryred">Clear Filters</p>
        </button>

        <button
          onClick={onConfirm}
          className="flex flex-row items-center justify-center w-full px-3 py-2 border rounded-md shadow-sm border-primarygrayborder"
        >
          <p className="m-0 text-base text-primaryblue">Confirm</p>
        </button>
      </div>
    </div>
    // </ReusableModal>
  );
};

export default AppointmentFilterModal;
