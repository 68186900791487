import React from 'react'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../../../Reports/reportStyles.css'
import classNames from 'classnames';
import { Icons } from '../../../../assets';

function ProviderActivityReportFilter({ getProviderReports, dateRange, setDateRange, dateRangeValue, setDateRangeValue, handleClear, setClear, isSelecetdValue }) {
    const today = dayjs();
    const [selectedVisitCategory, setSelectedVisitCategory] = React.useState(null);
    const [VisitCatListOpen, setVisitCatListOpen] = React.useState(false);
    const visitCatRef = React.useRef(null)
    //const today = dayjs('2024-07-30');
    const handleChange = (event, newValue) => {
        setClear(true)
        event.preventDefault()
        setDateRangeValue(newValue);
        if (newValue === 'THIS_MONTH') {
            setDateRange([today.startOf('month'), today])
        } else if (newValue === 'LAST_MONTH') {
            setDateRange([today.subtract(1, 'month').startOf('month'), today.subtract(1, 'month').endOf('month')])
        } else if (newValue === 'YTD') {
            setDateRange([today.startOf('year'), today])
        }
        getProviderReports({ dateRangeValue: newValue, setHeaders: true })
    };
    const handleSearch = () => {
        setClear(true)
        getProviderReports({ setHeaders: true, dateRange: dateRange })
    }
    const handleClickOutside = (event) => {
        if (visitCatRef.current && !visitCatRef.current.contains(event.target)) {
            setVisitCatListOpen(false);
        }
    };
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className="col-12 flex flex-row w-full align-items-center flex-wrap">
            <div className='col-lg-9 col-12 flex flex-row flex-wrap items-center mb-1'>
                <div className='d-flex flex-row align-items-center mx-2 mb-2 mb-sm-0'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DateRangePicker']}>
                            <DateRangePicker
                                localeText={{ start: 'From', end: 'To' }}
                                calendars={2}
                                value={dateRange}
                                onChange={(newValue) => { setDateRange(newValue); setDateRangeValue('') }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
                <div className={`d-flex flex-col mx-1 pt-2`}>
                    <div className={`d-flex sm:flex-row flex-col h-fit `}>
                        <div className="relative flex flex-row items-center gap-2 divide-x-2 rounded cursor-grab outline sm:w-56 w-48" ref={visitCatRef} style={{ outlineColor: '#e5e7eb' }}>
                            <button className="pl-2">
                                <img
                                    src={Icons.Sort_Icon}
                                    alt="search"
                                    className="w-5 h-5 cursor-pointer search-icon"
                                    onClick={() => {
                                        setVisitCatListOpen(!VisitCatListOpen)
                                    }}
                                />
                            </button>
                            <div className="flex flex-row items-center w-full h-full py-2 gap-2 px-0 overflow-scroll flex-nowrap no-scrollbar border-gray-400 ">
                                {selectedVisitCategory === null ? (
                                    <p className="m-0 text-base text-gray-400 whitespace-nowrap" style={{ padding: "0px 0.5rem" }}>
                                        Select Visit Category
                                    </p>
                                ) : (
                                    <p className="m-0 text-base px-2 whitespace-nowrap">
                                        {selectedVisitCategory?.name}
                                    </p>
                                )}
                            </div>
                            {VisitCatListOpen && (
                                <div
                                    className="absolute flex flex-col w-full overflow-auto bg-white border rounded-md max-h-56 top-12 z-10"
                                    style={{
                                        boxShadow: "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)"
                                    }}
                                >
                                    {[
                                        { value: 1, name: "All" },
                                        { value: 2, name: "Recurring" },
                                        { value: 3, name: "Walk-in" },
                                    ].map((item, index) => (
                                        <div
                                            className={classNames(
                                                "hover:bg-primarylightgrey z-10 bg-white",
                                                index !== 7 && "border-b"
                                            )}
                                        >
                                            <div
                                                className="px-2 py-2"
                                                onClick={() => {
                                                    setSelectedVisitCategory(item);
                                                }}
                                            >
                                                {item?.name}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className=' mr-1 ml-1 pt-2'>
                    <button variant="contained" disabled={dayjs(dateRange[0]).isValid() || dayjs(dateRange[1]).isValid() ? false : true} className='py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm' onClick={handleSearch} style={{ textTransform: 'capitalize' }}>Search</button>
                </div>
                <div className=' mx-1 pt-2'>
                    <button variant="contained"
                        // disabled={!isSelectedValue && !dayjs(dateRange[0]).isValid() && !dayjs(dateRange[1]).isValid()}
                        disabled={!isSelecetdValue && !dayjs(dateRange[0]).isValid() && !dayjs(dateRange[1]).isValid()}
                        className='py-2 px-2 text-white font-bold bg-primaryblue rounded-lg disabled:opacity-60 hover:bg-blue-700 text-sm'
                        onClick={() => {
                            handleClear()
                        }} style={{ textTransform: 'capitalize' }}>Clear</button>
                </div>

            </div>
            <div className='d-flex mb-1 justify-content-end align-items-center mr-0 ml-md-auto col-lg-3 col-12'>
                <Tabs
                    value={dateRangeValue}
                    // value='MTD'
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="white"
                    aria-label="secondary tabs example"
                >
                    <Tab label="This Month" value="THIS_MONTH" />
                    <Tab label="Last Month" value="LAST_MONTH" />
                    <Tab label="Year to Date" value="YTD" />
                </Tabs>
            </div>
        </div >
    )
}

export default ProviderActivityReportFilter