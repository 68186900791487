import StarBorderIcon from "@mui/icons-material/StarBorder";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import WarningSvg from "../assets/svgs/WarningSvg";
import TagSvg from "../assets/svgs/TagSvg";
import ImageSvg from "../assets/svgs/ImageSvg";
import FileSvg from "../assets/svgs/FileSvg";
import CloseIcon from "@mui/icons-material/Close";
import CheckSvg from "../assets/svgs/CheckSvg";
import UserSvg from "../assets/svgs/user";
import { Icon } from "@virtuslab/tetrisly-icons";

const EditIcon = (props) => <Icon name="20-edit" {...props} />;

export const messageOptions = [
  { id: 5, name: "Apply Tag", icon: TagSvg, color: "#272E35" },
  { id: 7, name: "Apply Patient Tag", icon: UserSvg, color: "#272E35" },
  // { id: 6, name: "Send To Athena", icon: TagSvg, color: "#272E35" },
  {
    id: 8,
    name: "Sent To Athena",
    icon: CheckSvg,
    color: "#1d7c4d",
    disabled: true,
  },
  { id: 10, name: "Mark as Urgent", icon: WarningSvg, color: "#272E35" },
  { id: 11, name: "Edit", icon: EditIcon, color: "#272E35" },
  { id: 1, name: "Star", icon: StarBorderIcon, color: "#272E35" },
  { id: 9, name: "Unstar", icon: StarBorderIcon, color: "#272E35" },
  { id: 2, name: "Forward", icon: ShortcutIcon, color: "#272E35" },
  { id: 3, name: "Copy", icon: ContentCopyIcon, color: "#272E35" },
  { id: 4, name: "Delete", icon: DeleteOutlineOutlinedIcon, color: "#EB5757" },
];

export const chatOptions = [
  // { id: 5, name: "Apply Tag", icon: TagSvg, color: "#272E35" },
  ...messageOptions,
];

export const contactCategories = [
  "Main Contact",
  "First Backup Contact",
  "Second Backup Contact",
];

export const contactTypes = [
  "Family Member-Son",
  "Family Member-Daughter",
  "Family Member-Other",
  "Non Family Legal Guardian",
  "Community Staff",
];

export const mediaOptions = [
  { id: 1, name: "Photo", icon: ImageSvg, color: "#272E35" },
  { id: 2, name: "Document", icon: FileSvg, color: "#272E35" },
  { id: 3, name: "Cancel", icon: CloseIcon, color: "#272E35" },
];

export const holidaysOptions = [
  { id: 1, name: "New Year" },
  { id: 2, name: "Memorial Day" },
  { id: 3, name: "Labor Day" },
  { id: 4, name: "Independence Day" },
  { id: 5, name: "Thanksgiving" },
  { id: 6, name: "Christmas" },
];

export const appointmentTypes = [
  {
    id: 46,
    name: "Primary Follow up",
    type: "Normal",
    specialty: ["Family Medicine"],
  },
  {
    id: 59,
    name: "Palliative Follow-up",
    type: "Normal",
    specialty: ["Family Medicine"],
  },
  {
    id: 59,
    name: "Palliative Follow up",
    type: "Normal",
    specialty: ["Family Medicine"],
  },
  { id: 52, name: "Cardio", type: "Normal", specialty: ["Cardiology"] },
  {
    id: 52,
    name: "Follow-up Cardio",
    type: "Normal",
    specialty: ["Cardiology"],
  },
  {
    id: 50,
    name: "Podiatry",
    type: "Normal",
    specialty: ["Podiatry", "Podiatrists"],
  },
  {
    id: 50,
    name: "Follow-up Podiatry",
    type: "Normal",
    specialty: ["Podiatry", "Podiatrists"],
  },
  { id: 54, name: "Psych", type: "Normal", specialty: ["Psychology"] },
  {
    id: 54,
    name: "Follow-up Psych",
    type: "Normal",
    specialty: ["Psychology"],
  },
  {
    id: 11,
    name: "Annual Wellness Medicare",
    type: "Normal",
  },
  {
    id: 81,
    name: "Annual Wellness Non Medicare",
    type: "Normal",
  },
  { id: 13, name: "ANY 15", type: "Normal" },
  {
    id: 1,
    name: "Chronic Disease Management",
    type: "Normal",
  },
  {
    id: 43,
    name: "Hospice F2F Visit",
    type: "Normal",
  },
  {
    id: 2,
    name: "Hospital Follow Up",
    type: "Normal",
  },
  { id: 55, name: "MA Testing", type: "Normal" },
  { id: 56, name: "MA Visit", type: "Normal" },
  {
    id: 34,
    name: "Medication Management",
    type: "Normal",
  },
  { id: 51, name: "New Cardio", type: "Normal", specialty: ["Cardiology"] },
  {
    id: 49,
    name: "New Podiatry",
    type: "Normal",
    specialty: ["Podiatry", "Podiatrists"],
  },
  { id: 53, name: "New Psych", type: "Normal", specialty: ["Psychology"] },
  {
    id: 44,
    name: "NP 1-Time Visit",
    type: "Normal",
  },
  {
    id: 61,
    name: "Palliative Hospital Follow-up",
    type: "Normal",
  },
  {
    id: 61,
    name: "Palliative Hospital Follow up",
    type: "Normal",
  },
  {
    id: 42,
    name: "Palliative New Patient",
    type: "Normal",
    specialty: ["Family Medicine"],
  },
  {
    id: 58,
    name: "Palliative Returning Patient",
    type: "Normal",
  },
  {
    id: 60,
    name: "Palliative Walk-in",
    type: "Ad-Hoc",
    specialty: ["Family Medicine"],
  },
  {
    id: 60,
    name: "Palliative Walk in",
    type: "Ad-Hoc",
    specialty: ["Family Medicine"],
  },
  {
    id: 47,
    name: "Primary Hospital Follow-up",
    type: "Normal",
  },
  {
    id: 47,
    name: "Primary Hospital Follow up",
    type: "Normal",
  },
  {
    id: 41,
    name: "Primary New Patient",
    type: "Normal",
    specialty: ["Family Medicine"],
  },
  {
    id: 45,
    name: "Primary Returning Patient",
    type: "Normal",
  },
  {
    id: 48,
    name: "Primary Walk-in",
    type: "Ad-Hoc",
    specialty: ["Family Medicine"],
  },
  {
    id: 48,
    name: "Primary Walk in",
    type: "Ad-Hoc",
    specialty: ["Family Medicine"],
  },
  {
    id: 57,
    name: "Social Worker Visit",
    type: "Normal",
  },
  { id: 5, name: "Telehealth", type: "Normal" },
];

export const status = {
  isCanceled: {
    status: "Canceled",
    color: "red",
  },
  isConfirmed: {
    status: "Confirmed",
    color: "yellow",
  },
  isCompleted: {
    status: "Completed",
    color: "green",
  },
  isPending: {
    status: "Pending",
    color: "pending",
  },
};

export const tagMapping = {
  "#labs": "OTHER",
  "#imaging": "OTHER",
  "#visitrequest": "ADMINISTRATIVE",
  "#newpacket": "ADMINISTRATIVE",
  "#dme": "ADMINISTRATIVE",
  "#insurance": "BILLING",
  "#newmeds": "REFILL",
  "#contact": "OTHER",
  "#homehealth": "ADMINISTRATIVE",
  "#wound": "CLINICALQUESTION",
  "#movein": "ADMINISTRATIVE",
  "#hospital": "OTHER",
  "#hospice": "OTHER",
  "#concern": "CLINICALQUESTION",
};

export const weeks = [
  {
    id: 1,
    name: "Monday",
  },
  {
    id: 2,
    name: "Tuesday",
  },
  {
    id: 3,
    name: "Wednesday",
  },
  {
    id: 4,
    name: "Thursday",
  },
  {
    id: 5,
    name: "Friday",
  },
  // {
  //   id: 6,
  //   name: "Saturday",
  // },
  // {
  //   id: 7,
  //   name: "Sunday",
  // },
];

export const constraintFrequency = [
  {
    freq: 7,
    name: "Every week",
  },
  {
    freq: 14,
    name: "Every two weeks",
  },
  {
    freq: 31,
    name: "Once a month",
  },
];

export const states = [
  { value: "AL", text: "Alabama (AL)" },
  { value: "AK", text: "Alaska (AK)" },
  { value: "AZ", text: "Arizona (AZ)" },
  { value: "AR", text: "Arkansas (AR)" },
  { value: "CA", text: "California (CA)" },
  { value: "CO", text: "Colorado (CO)" },
  { value: "CT", text: "Connecticut (CT)" },
  { value: "DE", text: "Delaware (DE)" },
  { value: "DC", text: "District Of Columbia (DC)" },
  { value: "FL", text: "Florida (FL)" },
  { value: "GA", text: "Georgia (GA)" },
  { value: "HI", text: "Hawaii (HI)" },
  { value: "ID", text: "Idaho (ID)" },
  { value: "IL", text: "Illinois (IL)" },
  { value: "IN", text: "Indiana (IN)" },
  { value: "IA", text: "Iowa (IA)" },
  { value: "KS", text: "Kansas (KS)" },
  { value: "KY", text: "Kentucky (KY)" },
  { value: "LA", text: "Louisiana (LA)" },
  { value: "ME", text: "Maine (ME)" },
  { value: "MD", text: "Maryland (MD)" },
  { value: "MA", text: "Massachusetts (MA)" },
  { value: "MI", text: "Michigan (MI)" },
  { value: "MN", text: "Minnesota (MN)" },
  { value: "MS", text: "Mississippi (MS)" },
  { value: "MO", text: "Missouri (MO)" },
  { value: "MT", text: "Montana (MT)" },
  { value: "NE", text: "Nebraska (NE)" },
  { value: "NV", text: "Nevada (NV)" },
  { value: "NH", text: "New Hampshire (NH)" },
  { value: "NJ", text: "New Jersey (NJ)" },
  { value: "NM", text: "New Mexico (NM)" },
  { value: "NY", text: "New York (NY)" },
  { value: "NC", text: "North Carolina (NC)" },
  { value: "ND", text: "North Dakota (ND)" },
  { value: "OH", text: "Ohio (OH)" },
  { value: "OK", text: "Oklahoma (OK)" },
  { value: "OR", text: "Oregon (OR)" },
  { value: "PA", text: "Pennsylvania (PA)" },
  { value: "RI", text: "Rhode Island (RI)" },
  { value: "SC", text: "South Carolina (SC)" },
  { value: "SD", text: "South Dakota (SD)" },
  { value: "TN", text: "Tennessee (TN)" },
  { value: "TX", text: "Texas (TX)" },
  { value: "UT", text: "Utah (UT)" },
  { value: "VT", text: "Vermont (VT)" },
  { value: "VA", text: "Virginia (VA)" },
  { value: "WA", text: "Washington (WA)" },
  { value: "WV", text: "West Virginia (WV)" },
  { value: "WI", text: "Wisconsin (WI)" },
  { value: "WY", text: "Wyoming (WY)" },
];
