import {
  ArrowDownwardTwoTone,
  ArrowUpwardTwoTone,
  FilterList,
} from "@mui/icons-material";
import {
  useCreateCaretagMutation,
  useEditCaretagMutation,
  useGetAllCaretagsMutation,
} from "../../../features/caretag/caretagApiSlice";

import CaretagModal from "./components/CaretagModal";
import CheckboxSelector from "../../Schedules/components/CheckboxSelector";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import FilterSelect from "../../../components/Filter/FilterSelect";
import IconComponent from "../../../components/DataTable/Icon";
import Loading from "../../../components/Loading";
import React from "react";
import Search from "../../../components/Search/SearchNew";
import { Search_Icon } from "../../../assets/images";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { Tooltip } from "@mui/material";
import classNames from "classnames";

// const caretags = [
//   {
//     id: 1,
//     name: "labs",
//     description: "Request for labs",
//     status: "Inactive",
//   },
//   {
//     id: 2,
//     name: "imaging",
//     description: "Request for imaging",
//     status: "Inactive",
//   },
//   {
//     id: 3,
//     name: "visitrequest",
//     description: "Request for visits",
//     status: "Active",
//   },
//   {
//     id: 4,
//     name: "movein",
//     description: "Request for move-in paperwork and all paperwork",
//     status: "Active",
//   },
//   {
//     id: 5,
//     name: "hospital",
//     description: "Going to or returning from hospital/SNF",
//     status: "Active",
//   },
//   {
//     id: 6,
//     name: "concern",
//     description: "Any clinical concerns/behaviors",
//     status: "Active",
//   },
// ];

const CaretagList = () => {
  const [selectedCaretag, setSelectedCaretag] = React.useState(null);
  const [caretagModalOpen, setCaretagModalOpen] = React.useState(false);

  const [statusFilter, setStatusFilter] = React.useState([]);
  const [searchFilter, setSearchFilter] = React.useState("");

  const [statuses, setStatuses] = React.useState([]);

  const [statusDropdown, setStatusDropdown] = React.useState(false);

  const [sortStatusOrder, setSortStatusOrder] = React.useState("none");
  const [sortNameOrder, setSortNameOrder] = React.useState("none");

  const [getCaretags, { isLoading }] = useGetAllCaretagsMutation();

  const [edit, { isLoading: editLoading }] = useEditCaretagMutation();

  const [create, { isLoading: createLoading }] = useCreateCaretagMutation();

  const loading = editLoading || createLoading || isLoading;

  const handleSortStatus = (a, b) => {
    if (sortStatusOrder === "none") {
      return 0;
    } else if (sortStatusOrder === "asc") {
      return a.status.localeCompare(b.status);
    } else {
      return b.status.localeCompare(a.status);
    }
  };

  const handleSortName = (a, b) => {
    if (sortNameOrder === "none") {
      return 0;
    } else if (sortNameOrder === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  };

  const handleStatusFilter = (status) => {
    if (statusFilter.indexOf(status) === -1) {
      setStatusFilter([...statusFilter, status]);
    } else {
      setStatusFilter(statusFilter.filter((filter) => filter !== status));
    }
  };

  const [caretags, setCaretags] = React.useState([]);

  const fetchCaretags = async () => {
    try {
      const { data } = await getCaretags().unwrap();

      setCaretags(data);

      const statuses = Array.from(
        new Set(data.map((caretag) => caretag.status))
      );

      setStatuses(statuses);
    } catch (error) {
      console.error(error.data.message);
    }
  };

  React.useEffect(() => {
    const statuses = Array.from(
      new Set(caretags.map((caretag) => caretag.status))
    );

    setStatuses(statuses);
  }, [caretags]);

  React.useEffect(() => {
    fetchCaretags();
  }, []);

  return (
    <div className="flex flex-col items-start py-4 overflow-hidden">
      <Loading loading={loading} />
      <div className="flex flex-row items-center justify-between w-full px-16">
        <div className="flex flex-row items-center w-full gap-12">
          <h1 className="m-0 text-2xl font-bold w-fit whitespace-nowrap">
            Caretags{" "}
            <span className="ml-2 text-base font-bold text-primaryblue">
              {caretags.filter((caretag) =>
                caretag.name
                  .toLowerCase()
                  .includes(searchFilter.toLowerCase())
              )
                .filter(
                  (caretag) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(caretag.status) !== -1
                )?.length}
            </span>
          </h1>
          <div className="flex flex-row items-center w-full gap-8 max-w-7xl">
            <Search value={searchFilter} setValue={setSearchFilter} />
            <FilterSelect
              value={statusFilter}
              handleChange={handleStatusFilter}
              data={statuses}
              placeholder="Filter by Status ..."
            />
          </div>
        </div>
        <button
          onClick={() => {
            setCaretagModalOpen(true);
          }}
          className="flex items-center justify-center px-4 py-2 text-sm font-bold text-white rounded-lg bg-primaryblue whitespace-nowrap"
        >
          Add Caretag
        </button>
      </div>
      <div className="w-full px-16">
        <div className="relative w-full mt-8 mb-2 overflow-x-auto overflow-y-auto bg-white rounded-lg shadow no-scrollbar max-h-[calc(100vh-200px)]">
          <table className="relative w-full whitespace-no-wrap bg-white border-collapse table-auto table-striped">
            <thead>
              <tr className="h-16 text-base font-bold text-left text-white bg-primaryblue whitespace-nowrap">
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Name</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortNameOrder === "none") {
                          setSortNameOrder("asc");
                        }
                        if (sortNameOrder === "asc") {
                          setSortNameOrder("desc");
                        }
                        if (sortNameOrder === "desc") {
                          setSortNameOrder("none");
                        }
                      }}
                    >
                      {sortNameOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortNameOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Description</p>
                  </div>
                </th>
                <th className="px-4 py-3">
                  <div className="flex flex-row items-center gap-2">
                    <p className="m-0">Status</p>
                    <button
                      className="flex flex-col items-center gap-1"
                      onClick={() => {
                        if (sortStatusOrder === "none") {
                          setSortStatusOrder("asc");
                        }
                        if (sortStatusOrder === "asc") {
                          setSortStatusOrder("desc");
                        }
                        if (sortStatusOrder === "desc") {
                          setSortStatusOrder("none");
                        }
                      }}
                    >
                      {sortStatusOrder === "asc" ? (
                        <Tooltip title="Sort Descending">
                          <ArrowDownwardTwoTone />
                        </Tooltip>
                      ) : sortStatusOrder === "desc" ? (
                        <Tooltip title="Sort Ascending">
                          <ArrowUpwardTwoTone />
                        </Tooltip>
                      ) : (
                        // <div className="flex items-center h-4">
                        //   <div className="w-6 h-[2px] bg-white" />
                        // </div>
                        <div className="flex items-center providerlist-icon">
                          <SwapVertIcon />
                        </div>
                      )}
                    </button>
                  </div>
                </th>
                <th className="px-4 py-3">Edit</th>
              </tr>
            </thead>
            <tbody>
              {caretags
                .filter((caretag) =>
                  caretag.name
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase())
                )
                .filter(
                  (caretag) =>
                    statusFilter.length === 0 ||
                    statusFilter.indexOf(caretag.status) !== -1
                )
                .sort((a, b) => handleSortName(a, b))
                .sort((a, b) => handleSortStatus(a, b))
                .map((caretag, index) => (
                  <tr
                    className={classNames(
                      "text-left text-gray-900 h-24 whitespace-nowrap",
                      index !== caretags.length - 1 ? "border-b" : "border-none"
                    )}
                    key={index}
                  >
                    <td className="px-4 py-3 text-left">
                      <div className="flex flex-row items-center gap-4">
                        <p className="m-0 font-semibold underline">
                          {caretag.name}
                        </p>
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      {caretag.description}
                    </td>
                    <td className="px-4 py-3 text-left text-gray-700">
                      <div
                        className={classNames(
                          caretag.status.toUpperCase() === "ACTIVE"
                            ? "bg-subtlegreen border-[1px] border-primarygreen text-primarygreen"
                            : "bg-primarylightgrey border-[1px] border-primarygrayborder text-primarytextgrey",
                          "rounded-md text-center flex flex-row gap-1 items-center px-2 w-fit"
                        )}
                      >
                        <div
                          className={classNames(
                            "w-2 h-2 rounded-full",
                            caretag.status.toUpperCase() === "ACTIVE"
                              ? "bg-primarygreen"
                              : "bg-primarytextgrey"
                          )}
                        />
                        {caretag.status}
                      </div>
                    </td>
                    <td className="px-4 py-3 text-left">
                      <button
                        onClick={() => {
                          setSelectedCaretag(caretag);
                          setCaretagModalOpen(true);
                        }}
                        className="text-white rounded-md"
                      >
                        <IconComponent
                          tooltip="Edit caretag"
                          icon="Edit_Icon"
                          i={caretag.id}
                          key={caretag.id}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <CaretagModal
        type={selectedCaretag ? "update" : "create"}
        open={caretagModalOpen}
        setOpen={setCaretagModalOpen}
        data={selectedCaretag}
        setData={setSelectedCaretag}
        caretags={caretags}
        setCaretags={setCaretags}
        edit={edit}
        create={create}
      />
    </div>
  );
};

export default CaretagList;
