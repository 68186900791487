import React, { useEffect, useState } from "react";
import CloseSvg from "../../../assets/svgs/CloseSvg";
import ReusableModal from "../../../components/Modals/ReusableModal";

const BackOfficeUserCreateModal = ({
  handleAddBackOfficeUserModal,
  saveBackOfficeUser,
  selectedUser,
}) => {
  const [errMsg, setErrMsg] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState(1);

  const isButtonDisabled = () => {
    return !role || !email || !firstName || !lastName;
  };

  const handleSave = async (e) => {
    try {
      await saveBackOfficeUser(email, phone, firstName, lastName, role);
      handleAddBackOfficeUserModal(e);
    } catch (error) {
      if (!error.status) setErrMsg("No response from server");
      else if (error.status === 400 || error.status === 404)
        setErrMsg(error.data.message);
      else setErrMsg("An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    if (selectedUser) {
      const [firstName, lastName] = (selectedUser?.details?.name || "").split(
        " "
      );
      setEmail(selectedUser.email);
      setPhone(selectedUser.phone);
      setFirstName(firstName);
      setLastName(lastName);
      setRole(selectedUser?.details?.role);
    }
  }, []);

  return (
    <ReusableModal
      open
      title={"Add Back Office Staff"}
      onClose={handleAddBackOfficeUserModal}
      boxClasses="xs:w-[300px] md:w-[400px] overflow-y-auto"
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4 pt-4">
          <p className="m-0 font-bold">Role</p>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="block w-full md:w-fit p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
          >
            <option value={""}>Select Role</option>
            <option value={1}>Admin</option>
            <option value={2}>Scheduler</option>
            <option value={3}>Executive</option>
          </select>
        </div>
      </div>
      {/* <div className="w-full border border-primarygrayborder" /> */}
      <div className="flex flex-col">
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">Email</p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">Phone</p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">First Name</p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
            <p className="m-0 font-bold whitespace-nowrap">Last Name</p>
            <input
              type="text"
              className="block p-2 text-sm text-gray-900 bg-white border border-gray-300 rounded w-full md:w-fit focus:ring-blue-500 focus:border-blue-500"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="w-full border border-primarygrayborder" />
      {errMsg && (
        <div className="flex flex-col items-start justify-start w-full gap-2 p-2 px-4">
          <p className="m-0 text-sm text-danger">{errMsg}</p>
        </div>
      )}
      <div className="flex flex-col items-start justify-between gap-2 p-4">
        <div className="flex flex-row items-center justify-end w-full gap-4">
          <button
            onClick={handleAddBackOfficeUserModal}
            className="px-4 py-2 font-bold text-white bg-gray-400 rounded-md"
          >
            Cancel
          </button>
          <button
            disabled={isButtonDisabled()}
            onClick={handleSave}
            className="px-4 py-2 font-bold text-white rounded-md bg-primaryblue disabled:opacity-40 disabled:cursor-not-allowed"
          >
            Save
          </button>
        </div>
      </div>
    </ReusableModal>
  );
};

export default BackOfficeUserCreateModal;
