import React, { useCallback, useContext, useEffect, useState } from "react";
import Tag_Icon from "../../assets/images/Tag.png";
import MessageCard from "../../components/Cards/MessageCard";
import { Avatar, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import "./ConversationScreen.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ViewGroupModal from "../../components/Modals/ViewGroupModal";
import ViewContactModal from "../../components/Modals/ViewContactModal";
import { Icons } from "../../assets";
import AddMedia from "../../components/Cards/AddMedia";
// import useMessenger from "../../hooks/useMessenger";
import Loading from "../../components/Loading";
import useChatScroll from "../../hooks/useChatScroll";
// import usePatients from "../../hooks/usePatients";
// import useCareTags from "../../hooks/useCareTags";
// import useAuth from "../../hooks/useAuth";
import UploadScreen from "../../components/Modals/UploadScreen";
import AddDocumentModal from "../../components/Modals/AddDocumentModal";
import ChatInput from "./ChatInput";
import CareTagsModal from "../../components/Modals/CareTagsModal";
import {
  convertMentionsMessage,
  parseHash,
  parseMessage,
  parseRef,
  regexHash,
  regexRef,
} from "../../utilities/resuseFunctions";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/auth/authSlice";
import { SocketContext } from "../../context/SocketProvider";
import { v4 } from "uuid";
import { useGetAllCaretagsMutation } from "../../features/caretag/caretagApiSlice";
import {
  useFetchConversationPatientsMutation,
  useUploadDocumentMutation,
} from "../../features/chat/chatApiSlice";
import { useUploadDocumentAthenaMutation } from "../../features/athena/athenaApiSlice";

const medicines = [
  { id: "medicine1", display: "Medicine 1" },
  { id: "medicine2", display: "Medicine 2" },
];

const providers = [
  { id: "provider1", display: "Provider 1(Physician)" },
  { id: "provider2", display: "Provider 2(Cardiologist)" },
  { id: "provider3", display: "Provider 3(Podiatrist)" },
  { id: "provider4", display: "Provider 4(Psychiatrist)" },
];

const ConversationScreen = ({ onClose, isFullScreen, setCurrentChat }) => {
  const { height } = useWindowDimensions();
  const user = useSelector(selectUser);
  const {
    currentChat,
    messages,
    isLoading,
    sendMessage,
    getConversationDetails,
    getMessages,
    editMessage,
    setEditMessage,
    updateMessage,
    careTags,
    setCareTags,
    socket,
  } = useContext(SocketContext);
  const [getAllCaretags] = useGetAllCaretagsMutation();
  const [fetchConversationPatients] = useFetchConversationPatientsMutation();
  const [uploadDocument] = useUploadDocumentMutation();
  const [uploadDocumentAthena] = useUploadDocumentAthenaMutation();

  const [message, setMessage] = useState("");
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [documentOpen, setDocumentOpen] = useState(false);
  const [tagOpen, setTagOpen] = useState(false);
  const [tagSelected, setTagSelected] = useState("");

  const [groupInfo, setGroupInfo] = useState(false);
  const [contactInfo, setContactInfo] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [data, setData] = useState({});
  const [documentData, setDocumentData] = useState({});
  const [tagData, setTagData] = useState([]);
  const [tagPatients, setTagPatients] = useState([]);

  const [users, setUsers] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [isEventRefresh, setEventRefresh] = useState(false);

  const isCommunity = user.type === "COMMUNITY";

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    getAllCaretags().then(({ data }) => {
      setCareTags(data?.data ?? []);
    });
  }, []);

  useEffect(() => {
    const conversationUserAdded = (groupId) => {
      if (groupId === currentChat.id) {
        console.log("conversation user added");
        setRefresh(true);
        setEventRefresh(true);
      }
    };

    socket.on("conversation user added", conversationUserAdded);

    const conversationUserRemoved = (groupId) => {
      if (groupId === currentChat.id) {
        console.log("conversation user removed");
        setRefresh(true);
        setEventRefresh(true);
      }
    };

    socket.on("conversation user removed", conversationUserRemoved);

    return () => {
      socket.off("conversation user added", conversationUserAdded);
      socket.off("conversation user removed", conversationUserRemoved);
    };
  }, []);

  useEffect(() => {
    if (refresh === true) {
      if (currentChat?.id) {
        getConversationDetails({
          conversationId: currentChat.id,
        }).then((res) => {
          setData({
            ...res,
            createdBy: res.participants?.find(
              (user) => user.user_id === res.creator_id
            ),
          });

          setUsers(
            res.participants
              ?.map((user) => {
                return {
                  id: user.user_id,
                  display: `${user.firstname} ${user.lastname}`,
                  providerId: user?.provider_id,
                  communityId: res.community_id,
                };
              })
              ?.filter((data) => data.user_id !== user.id) || []
          );
        });
      }
      setRefresh(false);
      setEventRefresh(false);
    }
  }, [refresh]);

  const scrollToMessage = () => {
    const scrollToMessageId = 102;
    if (scrollToMessageId) {
      const messageElement = document.getElementById(
        `message-${scrollToMessageId}`
      );

      if (messageElement) {
        messageElement.scrollTop = messageElement.scrollHeight;
        // messageElement.scrollIntoView({
        //   behavior: "smooth",
        //   block: "nearest",
        //   inline: "start",
        // });
      }
    }
  };

  const ref = useChatScroll();

  const isGroup = currentChat?.type === "GROUP";

  const resultRef = useCallback(
    () => parseRef({ message, regexRef }),
    [message]
  )();

  const resultHash = useCallback(
    () => parseHash({ message, regexHash }),
    [message]
  )();

  const handleSubmit = (data) => {
    setDocumentData(data);
    setDocumentOpen(false);
    setUploadModal(true);
  };

  const handleTagSubmit = (data) => {
    setTagData([...tagData, data]);
    setMessage(`${message} "${data.docData?.name}"`);
    setTagOpen(false);
  };

  const onAddTag = (tag, tagName) => {
    setTagSelected(tag);
    setTagOpen(true);
  };

  const hashTags =
    careTags?.map((i) => {
      return {
        id: i.id,
        display: "#" + i.name,
      };
    }) || [];

  useEffect(() => {
    if (currentChat?.id) {
      // setMessages([]);
      setMessage("");
      getConversationDetails({
        conversationId: currentChat.id,
      })
        .then((res) => {
          fetchConversationPatients({ id: currentChat.id }).then(({ data }) => {
            if (data?.data) {
              setTagPatients(
                data?.data?.map((d) => {
                  return {
                    id: d.id,
                    display: d.name,
                    name: d.name,
                    dob: d.dob,
                    image:
                      "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png",
                  };
                })
              );
            }
          });
          setData({
            ...res,
            createdBy: res.participants?.find(
              (user) => user.user_id === res.creator_id
            ),
          });

          setUsers(
            res.participants
              ?.map((user) => {
                return {
                  id: user.user_id,
                  display: `${user.firstname} ${user.lastname}`,
                  providerId: user?.provider_id,
                  communityId: res.community_id,
                };
              })
              ?.filter((data) => data.user_id !== user.id) || []
          );
        })
        .catch((e) => {
          console.log(e.message);
        });
      getMessages({ conversationId: currentChat?.id }).then((res) => {
        // scrollToMessage();
      });
      if (currentChat.conversationType === "GROUP") {
        if (contactInfo) {
          setContactInfo(false);
          setGroupInfo(true);
        }
      } else {
        if (groupInfo) {
          setContactInfo(true);
          setGroupInfo(false);
        }
      }
    }
  }, [currentChat]);

  useEffect(() => {
    if (editMessage?.id === currentChat?.id) {
      const text = convertMentionsMessage({
        message: editMessage?.content?.message || "",
        patients: tagPatients,
        caretags: hashTags,
        users: users,
      });
      setMessage(text || "");
    } else {
      message && setMessage("");
    }
  }, [editMessage, currentChat, users]);

  const handleKickMember = (deleteID) => {
    setData({
      ...data,
      participants: data.participants.filter((x) => x.user_id !== deleteID),
    });
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior (adding a newline)
      editMessage ? onUpdate() : onSend();
      setEditMessage(null);
      setMessage(""); // Clear the input after sending the message
    }
  };

  const onSend = async () => {
    try {
      if (!message.trim()) {
        setMessage("");
        return;
      }
      setDisableInput(true);

      const { taggedMembers, taggedPatients, currentHashTags, replaceHash } =
        parseMessage({
          message,
        });

      let fileRes = undefined;
      // console.log(taggedPatients, "taggedPatients");

      if (file) {
        const docData = new FormData();
        docData.append("file", file);
        docData.append("practiceId", 1959447);
        docData.append("departmentid", 163);
        // docData.append("patientId", taggedPatients?.[0]?.patientId);
        docData.append("patientId", 4);
        docData.append("documentsubclass", documentData?.documentsubclass);
        docData.append("internalnote", documentData?.internalnote);
        docData.append("providerid", 88);
        isGroup && uploadDocumentAthena(docData);
        const formData = new FormData();
        formData.append("file", file);
        fileRes = await uploadDocument(formData);
      }

      const meta = {};
      taggedMembers.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "USER",
          id: data.userId,
        };
      });
      currentHashTags.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "CARETAG",
          id: data.id,
        };
      });
      taggedPatients.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "PATIENT",
          id: data.id,
        };
      });
      sendMessage({
        message: {
          tempId: v4(),
          conversationId: currentChat.id,
          isForwarded: false,
          isEdited: false,
          isUrgent: false,
          attachmentId: fileRes?.data?.data?.attachment_id,
          attachment: fileRes?.data?.data && {
            id: fileRes?.data?.data?.id,
            type: fileRes?.data?.data?.file_type,
            attachment_id: fileRes?.data?.data?.id,
            file_type: fileRes?.data?.data?.file_type,
            url: fileRes?.data?.data?.url,
          },
          content: {
            type: "MESSAGE",
            message: replaceHash,
            meta,
          },
        },
        userIds: data.participants
          ?.filter((p) => p.user_id !== user.id)
          ?.map((d) => `USER:${d.user_id}`),
        meta: {
          senderName: user.details?.name || "",
          groupName:
            currentChat.type === "GROUP"
              ? currentChat.name
              : user.details?.name || "",
        },
      });
      setMessage("");
      setFile(null);
      setSelectedImage(null);
      setUploadModal(false);
      setFileType("");
      setDocumentData({});
      setDisableInput(false);
    } catch (e) {
      setDisableInput(false);
      console.log(e, "err");
    }
  };

  const onUpdate = async () => {
    try {
      if (!message.trim()) {
        setMessage("");
        return;
      }

      setDisableInput(true);

      const { taggedMembers, taggedPatients, currentHashTags, replaceHash } =
        parseMessage({
          message,
        });

      const meta = {};
      taggedMembers.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "USER",
          id: data.userId,
        };
      });
      currentHashTags.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "CARETAG",
          id: data.id,
        };
      });
      taggedPatients.forEach((data) => {
        meta[`${data.startIndex}-${data.endIndex}`] = {
          type: "PATIENT",
          id: data.id,
        };
      });

      updateMessage({
        message: {
          id: editMessage.id,
          conversationId: editMessage.conversationId,
          isForwarded: editMessage.isForwarded,
          isEdited: true,
          isUrgent: editMessage.isUrgent,
          attachmentId: editMessage?.attachmentId,
          content: {
            type: "MESSAGE",
            message: replaceHash,
            meta,
          },
          sender: {
            id: editMessage.sender?.id,
          },
          createdAt: new Date(editMessage.sentAt),
        },
        userIds: data.participants
          ?.filter((p) => p.user_id !== user.id)
          ?.map((d) => `USER:${d.user_id}`),
        meta: {
          senderName: user.details?.name || "",
          groupName:
            data.type === "GROUP" ? data.name : user.details?.name || "",
        },
        updateMsg: {
          ...editMessage,
          content: {
            type: "MESSAGE",
            message: replaceHash,
            meta,
          },
        },
      });

      setMessage("");
      setFile(null);
      setSelectedImage(null);
      setUploadModal(false);
      setFileType("");
      setDocumentData({});
      setDisableInput(false);
    } catch (e) {
      setDisableInput(false);
      console.log(e, "err");
    }
  };

  const filterSuggestions = () => {
    const parts = message.split(" ");
    if (parts.length > 1) {
      const tag = parts?.[parts.length - 2];
      const regex = /#\[([\w\s]+)\]\((\w+)\)/;
      const match = tag.match(regex);
      // console.log(match, "match", tag);
      if (match) {
        const id = match[2];
        if (id === "fill") return medicines;
        if (id === "visit") return providers;
        // if (id === "ref") return tagPatients;
        return [];
      }
      return [];
    }
    return [];
  };

  const refList = filterSuggestions();

  // console.log(message, "mes");

  const messagesList = messages.length ? [...messages].reverse() : [];

  return (
    <Grid className="w-100 ">
      {isLoading && !isEventRefresh && (
        <Loading loading={!isEventRefresh && isLoading} />
      )}
      <Grid
        className={clsx(
          "d-flex align-items-center p-2 header justify-content-between",
          isFullScreen && "header-full"
        )}
      >
        <Grid
          role="button"
          onClick={() => {
            if (isGroup) {
              setGroupInfo(true);
            } else {
              setContactInfo(true);
            }
          }}
          className="d-flex align-items-center"
        >
          <Avatar size={"lg"} className="mx-2" src={currentChat.image} />
          <Grid className="mx-3">
            <Typography
              className={clsx("chat-title", isFullScreen && "chat-title-full")}
            >
              {currentChat?.name}
            </Typography>
            {isFullScreen && (
              <Grid className="d-flex align-items-center">
                <Typography className="chat-subtext">
                  Tap here for {isGroup ? "group" : "contact"} info
                </Typography>
              </Grid>
            )}
            {isFullScreen && isGroup && (
              <Grid className="d-flex align-items-center">
                {currentChat?.tagName && (
                  <img src={Tag_Icon} alt="tag" className="icon-chat" />
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        {!isFullScreen && (
          <Tooltip title="Close">
            <IconButton onClick={onClose}>
              <img src={Icons.Close_Icon1} alt="close" className="close-icon" />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid
        ref={ref}
        style={{
          height: isFullScreen ? height - 210 : height / 2,
          overflowY: "auto",
        }}
        className="p-3"
      >
        {messagesList?.map((item, idx) => {
          return (
            <MessageCard
              key={idx}
              id={`message-${item.id}`}
              message={item}
              data={{ ...currentChat, ...data }}
              isFullScreen={isFullScreen}
              careTags={careTags}
              tagPatients={tagPatients}
            />
          );
        })}
      </Grid>
      <Grid
        className={clsx(
          "d-flex align-items-center p-2 header",
          isFullScreen && "footer-chat"
        )}
      >
        <AddMedia
          isFullScreen={isFullScreen}
          setFile={(val) => {
            setFile(val);
            const reader = new FileReader();

            reader.onload = () => {
              setSelectedImage(reader.result);
            };
            reader.readAsDataURL(val);
          }}
          setDocumentOpen={setDocumentOpen}
          setFileType={setFileType}
          handleSubmit={handleSubmit}
          isGroup={isGroup}
        />
        <ChatInput
          message={message}
          onKeyDown={onKeyDown}
          setMessage={setMessage}
          users={users}
          hashTags={resultHash?.length ? [] : hashTags}
          tagPatients={resultRef?.length ? [] : tagPatients || []}
          refList={refList}
          onSend={onSend}
          disabled={(!message?.trim() && !file) || disableInput}
          onAddTag={onAddTag}
          editMessage={editMessage}
          setEditMsg={setEditMessage}
          conversationId={currentChat?.id}
          onUpdate={onUpdate}
        />
      </Grid>
      {groupInfo && (
        <ViewGroupModal
          isOpen={groupInfo}
          onClose={() => setGroupInfo(false)}
          chat={currentChat}
          data={data}
          setData={setData}
          handleKickMember={handleKickMember}
          // handleDeleteGroup={groupDeletionEmit}
          // handleLeaveGroup={leaveGroup}
          onCloseConv={isFullScreen ? () => {} : onClose}
          handleRefresh={handleRefresh}
          isCommunity={isCommunity}
        />
      )}
      {contactInfo && (
        <ViewContactModal
          isOpen={contactInfo}
          onClose={() => setContactInfo(false)}
          chat={currentChat}
          isCommunity={isCommunity}
        />
      )}
      {documentOpen && (
        <AddDocumentModal
          open={documentOpen}
          handleClose={() => setDocumentOpen(false)}
          chat={currentChat}
          handleSubmit={handleSubmit}
        />
      )}
      {tagOpen && (
        <CareTagsModal
          open={tagOpen}
          handleClose={() => setTagOpen(false)}
          chat={currentChat}
          handleSubmit={handleTagSubmit}
          tag={tagSelected}
        />
      )}
      {uploadModal && (
        <UploadScreen
          open={uploadModal}
          handleClose={() => setUploadModal(false)}
          chat={currentChat}
          InputBox={ChatInput}
          file={file}
          fileType={fileType}
          selectedImage={selectedImage}
          message={message}
          onKeyDown={onKeyDown}
          setMessage={setMessage}
          users={users}
          hashTags={hashTags}
          tagPatients={tagPatients || []}
          refList={refList}
          onSend={onSend}
          onAddTag={onAddTag}
          disable={disableInput}
        />
      )}
    </Grid>
  );
};

export default ConversationScreen;
